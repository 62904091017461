import React, { useEffect, useState } from "react";
import { FaEye, FaGem } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  COLOR_RED,
  DIV_NAME,
  MUN_NAME,
  PAR_NAME,
  STATUS_ASSESSED,
  STATUS_DEACTIVATED,
  STR_NAME,
  TENDERER,
  TENDERER_STATUS_DEFERRED,
  TENDERER_STATUS_REJECTED,
  TENDERER_VALIDITY,
  TENDER_STATUS_APPROVED,
  TENDER_STATUS_PENDING,
  VILL_NAME,
} from "../../constants/webapp";
import {
  canEdit,
  downloadThroughAnchorLink,
  returnObject,
} from "../../utils/helperUtil";
import useGenericGet from "../common/useGenericGet";
import useLocationData from "../common/useLocationData";
import {
  CONFIGURABLES_API,
  DEACTIVATE_API_TENDERER,
  PAYMENTS_API,
  TENDERER_BILLING_API,
  TENDERER_REVENUE_SOURCE_ITEM_API,
} from "../../constants/api";
import {
  ActivateButton,
  DeactivateButton,
  DownloadLink,
} from "../../components/common/Button";
import { Tooltip } from "antd";
import { MakePaymentRevenueButton } from "../trading-license/useTradingLicense";
import { Visibility } from "../../components/common/Visibility";
import axiosInstance from "../../utils/axios";
import { useAppContext } from "../../context/AppContext";

const useTenderer = () => {
  const [installments, setInstallments] = useState([]);
  const { loadData: billGeneration, success } = useGenericGet();
  const [open, setOpen] = useState(false);
  const [id, setID] = useState();
  const { status } = useParams();
  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const { contextData } = useAppContext();

  const userRole = contextData?.role_name;

  const deactivateApi = DEACTIVATE_API_TENDERER;
  const typeApi = "tenderers";

  const activateTenderer = (id) => {
    setLoading(true);
    axiosInstance
      .get(`/revenues/tenderers/reactivate-tenderer/?id=${id}`)
      .then(() => {
        // handle success
        setSubmitting(false);
        setMessage("Tenderer Revenue Succesfully Deactivated");
        navigate(`../tenderer/${status}`, { replace: true });
      })
      .catch((err) => {
        // handle error
        setLoading(false);
        setError(err);
      });
  };

  const tendererTableData = (status, data) => [
    ...data.map((item) => {
      const row = {
        application_number: item?.application_number,
        tender_ref_number: item?.tender_ref_number,
        type_display:
          item?.tenderer_type === "i" ? "Individual" : "Non-Individual",
        revenue_source_item: item?.revenue_source_item_name,
        tenderer_name: item?.owner_name,
        contract_description: item?.contract_description,
        tender_valid_from_date: item?.tender_valid_from_date,
        tender_valid_to_date: item?.tender_valid_to_date,
        tender_expected_amount: item?.tender_expected_amount?.toLocaleString(),
        expected_annual_amont: item?.expected_annual_amont?.toLocaleString(),
        number_of_expected_installments: item?.number_of_expected_installments,
        installment_amount: item?.installment_amount?.toLocaleString(),
        email: item?.email0,
        phone_number: item?.mobile_number,
        tender_validity: item?.tender_validity_name,
        interval_of_installment: item?.interval_of_installment_name,
        [MUN_NAME]: item?.municipality_name,
        [DIV_NAME]: item?.division_name,
        [PAR_NAME]: item?.parish_name,
        [VILL_NAME]: item?.village_name,
        [STR_NAME]: item?.street_name,
        created_by: item?.created_by_name,
        prn: (
          <MakePaymentRevenueButton
            api={TENDERER}
            id={item?.id}
            revenue_source={"tendered_revenue"}
          />
        ),
        actions: (
          <div style={{ whiteSpace: "nowrap" }}>
            <div style={{ whiteSpace: "nowrap" }}>
              <Link
                class="mr-2"
                style={{ padding: 5, paddingBottom: 0 }}
                to={`${TENDERER}/view/${item.id}`}
              >
                <FaEye />
              </Link>

              {status === "1" && userRole !== "MIS Authority" ? (
                <Tooltip title="Amendment">
                  <Link
                    style={{ padding: 5, paddingBottom: 0 }}
                    class=" mr-2"
                    to={`/amendment/tenderers/${item.id}`}
                  >
                    <FaGem />
                  </Link>
                </Tooltip>
              ) : null}

              <Visibility show={status === "3" && userRole !== "MIS Authority"}>
                {canEdit(item.created_by, item.id, TENDERER)}
              </Visibility>
              <Visibility show={userRole !== "MIS Authority"}>
                {item.active === true ? (
                  <>
                    <DeactivateButton
                      onClick={() => {
                        // deactivateUser(item.id);
                        setOpen(true);
                        setID(item.id);
                      }}
                    />
                  </>
                ) : (
                  <>
                    <ActivateButton onClick={() => activateTenderer(item.id)} />
                  </>
                )}
              </Visibility>
            </div>
          </div>
        ),
      };
      return row;
    }),
  ];

  const viewData = (item, isFormData) => {
    return {
      ...returnObject(!isFormData, {
        "Reference Number": item?.application_number,
        // "Branch Code": item?.branch_code,
      }),
      "Tender Reference Number": item?.tender_ref_number,
      tenderer_type:
        item?.tenderer_type === "i" ? "Individual" : "Non-Individual",
      ...returnObject(item?.tenderer_type === "i", {
        tenderer_nin: item?.tenderer_nin,
        surname: item?.surname,
        first_name: item?.first_name,
        other_name: item?.other_name,
      }),
      ...returnObject(item?.tenderer_type === "n", {
        tenderer_legal_name: item?.tenderer_legal_name,
        tenderer_brn: item?.tenderer_brn,
        company_tel_no: item?.company_tel_no,
        company_email: item?.company_email,
        company_contact_name: item?.company_contact_name,
        company_contact_mobile: item?.company_contact_mobile,
        company_contact_email: item?.company_contact_email,
      }),
      address: item?.address,
      contract_description: item?.contract_description,
      email: item?.email,
      phone_number: item?.phone_number,
      municipality: isFormData
        ? item?.municipality_name
        : item?.municipality_name,
      division: isFormData ? item?.division_name : item?.division_name,
      parish: isFormData ? item?.parish_name : item?.parish_name,
      village: isFormData ? item?.village_name : item?.village_name,
      street: isFormData ? item?.street_name : item?.street_name,
      revenue_source_item: isFormData
        ? item?.revenue_source_item_name
        : item?.revenue_source_item_name,
      tender_validity: isFormData
        ? item?.tender_validity
        : item?.tender_validity_name,
      pre_paid_installments: item?.pre_paid_installments,
      "Tender Expected Amount": item?.tender_expected_amount?.toLocaleString(),
      // "Expected Annual Amont": item?.expected_annual_amont?.toLocaleString(),
      Interval_of_installment: isFormData
        ? item?.interval_of_installment
        : item?.interval_of_installment_name,
      "Number of expected installments": isFormData
        ? item?.number_of_expected_installments -
          Number(item?.pre_paid_installments || 0)
        : item?.number_of_expected_installments,
      "Installment Amount": item?.installment_amount?.toLocaleString(),
      ...returnObject(!isFormData, {
        // Status: item?.status_display,
      }),
      created_by: item?.created_by_name,
    };
  };

  const tendererTableColumns = (status) => [
    { id: "application_number", header: "Reference Number ", filter: {} },
    ...returnObject(
      status === STATUS_ASSESSED || status === STATUS_DEACTIVATED,
      [{ id: "branch_code", header: "Branch Code", filter: {} }]
    ),
    { id: "tender_ref_number", header: "Tender Ref Number", filter: {} },
    { id: "type_display", header: "Tenderer Type ", filter: {} },
    { id: "tenderer_name", header: "Tenderer Name ", filter: {} },
    { id: "email", header: "Email", filter: {} },
    { id: "phone_number", header: "Contact", filter: {} },
    { id: "municipality_name", header: "Municipality ", filter: {} },
    { id: "division_name", header: "Division", filter: {} },
    { id: "parish_name", header: "Parish ", filter: {} },
    { id: "village_name", header: "Village", filter: {} },
    { id: "street_name", header: "Street ", filter: {} },
    { id: "revenue_source_item", header: "Revenue Source Item", filter: {} },
    { id: "contract_description", header: "Contract Description", filter: {} },
    { id: "tender_validity", header: "Tender Validity", filter: {} },
    {
      id: "tender_valid_from_date",
      header: "Valid From",
      filter: { type: "date" },
    },
    {
      id: "tender_valid_to_date",
      header: "Valid Until",
      filter: { type: "date" },
    },
    // { id: "expected_annual_amont", header: "Annual Amount", filter: {} },
    { id: "created_by", header: "Created By", filter: {} },

    { id: "interval_of_installment", header: "Payment Interval", filter: {} },
    {
      id: "number_of_expected_installments",
      header: "Payment Installments",
      filter: {},
    },
    {
      id: "installment_amount",
      header: "Installment Amount (UGX)",
      filter: {},
    },
    ...returnObject(userRole !== "MIS Authority", [{
      id: "prn",
      header: "PRNs",
    }]),
    { id: "actions", header: "Actions" },
  ];

  const generateBill = (installment_id) => {
    billGeneration({
      api: `${TENDERER_BILLING_API}?installment_id=${installment_id}`,
    });

    var arr = [...installments];
    setInstallments([...arr, installment_id]);
  };

  const tendererPaymentInstallmentTableData = (ledgers, setRefresh) => {
    const data = [
      ...ledgers.map((item) => {
        var includes = installments.includes(item?.id);

        const row = {
          id: item?.id,
          name: item.name,
          expected_amount: Number(item.expected_amount || 0).toLocaleString(),
          start_date: item.start_date,
          end_date: item.end_date,
          actions:
            item?.fully_paid === true ? (
              <>
                <DownloadLink
                  onClick={() =>
                    downloadThroughAnchorLink(
                      `${PAYMENTS_API}print-receipt-prn/?prn_id=${item?.prn_id}`,
                      "receipt"
                    )
                  }
                  title="[Receipt]"
                />
              </>
            ) : item?.start_notified === 0 && includes === false ? (
              <>
                {"Not Yet Billed"}
                <Link
                  to={`#`}
                  style={{ marginLeft: 10, color: "blue" }}
                  onClick={() => generateBill(item?.id, setRefresh)}
                >
                  [Generate Bill]
                </Link>
              </>
            ) : (item?.start_notified === 1 && item?.fully_paid === false) ||
              includes === true ? (
              <Link
                to={`/paf/create/invoice/${item?.id}/${1}/${
                  item?.expected_amount
                }`}
                style={{ color: COLOR_RED }}
              >
                [PAFs]
              </Link>
            ) : null,
        };
        return row;
      }),
    ];
    return data;
  };
  const tendererPaymentInstallmentColumns = [
    { id: "name", header: "Name" },
    { id: "expected_amount", header: "Expected Amount" },
    { id: "start_date", header: "Start Date" },
    { id: "end_date", header: "End Date" },
    { id: "actions", header: "Actions" },
  ];

  const tendererLedgerTableData = (ledgers) => {
    const data = [];
    Object.keys(ledgers || {})
      .filter((key) => ledgers[key])
      .map((key) => ledgers[key])
      .forEach((item) => {
        item.forEach((ledger) => {
          data.push({
            id: ledger?.id,
            name: ledger.name,
            amount: ledger.amount,
            created_at: ledger.created_at,
          });
        });
      });

    return data;
  };
  const tendererLedgerColumns = [
    { id: "Code", header: "Code" },
    { id: "amount", header: "Amount" },
    { id: "created_at", header: "Created At" },
  ];

  const getActions = (status) => {
    let options;

    switch (status) {
      case TENDER_STATUS_PENDING:
        options = [
          { value: TENDER_STATUS_APPROVED, label: "APRROVE" },
          { value: TENDERER_STATUS_REJECTED, label: "REJECT" },
          { value: TENDERER_STATUS_DEFERRED, label: "DEFER" },
        ];
        break;

      default:
        options = [];
    }

    return options;
  };

  const getRedirect = (status) => {
    let redirect = "";
    switch (status) {
      default:
        redirect = `${TENDERER}/pending`;
        break;
    }

    return redirect;
  };

  const amendableFields = [
    { id: "tenderer_nin", type: "text", field: "tenderer_nin" },
    { id: "Tender Reference Number", type: "text", field: "tender_ref_number" },
    { id: "surname", type: "text", field: "surname" },
    { id: "first_name", type: "text", field: "first_name" },
    { id: "other_name", type: "text", field: "other_name" },
    { id: "address", type: "text", field: "address" },
    { id: "contract_description", type: "text", field: "contract_description" },
    { id: "email", type: "text", field: "email" },
    { id: "phone_number", type: "text", field: "phone_number" },

    { id: "tenderer_legal_name", type: "text", field: "tenderer_legal_name" },
    { id: "tenderer_brn", type: "text", field: "tenderer_brn" },
    { id: "company_tel_no", type: "text", field: "company_tel_no" },
    { id: "company_email", type: "text", field: "company_email" },
    { id: "company_contact_name", type: "text", field: "company_contact_name" },
    {
      id: "company_contact_mobile",
      type: "text",
      field: "company_contact_mobile",
    },
    {
      id: "company_contact_email",
      type: "text",
      field: "company_contact_email",
    },

    {
      id: "municipality",
      type: "select",
      options: "districts",
      field: "municipality",
    },
    { id: "division", type: "select", options: "divisions", field: "division" },
    { id: "parish", type: "select", options: "parishes", field: "parish" },
    { id: "village", type: "select", options: "villages", field: "village" },
    { id: "street", type: "select", options: "streets", field: "street" },
    {
      id: "revenue_source_item",
      type: "select",
      options: "revenuesourceitems",
      field: "revenue_source_item",
    },
    // { id: "tender_validity", type: "select", options: "tenderervalidity", field: "tender_validity" },
  ];

  return {
    tendererTableColumns,
    tendererTableData,
    tendererPaymentInstallmentTableData,
    tendererPaymentInstallmentColumns,
    tendererLedgerTableData,
    tendererLedgerColumns,
    viewData,
    getRedirect,
    getActions,
    amendableFields,
    open,
    setOpen,
    id,
    loading,
    submitting,
    message,
    error,
    deactivateApi,
    typeApi,
  };
};

export const useTendererFormSelect = (form, updateFormValue, municipality) => {
  const {
    districts,
    getDistricts,
    divisions,
    getDivisions,
    parishes,
    getParishes,
    villages,
    getVillages,
    streets,
    getStreets,
  } = useLocationData();
  const { data: revenuesourceitems, loadData: getRevenuesourceItem } =
    useGenericGet();
  const { data: tenderervalidity, loadData: getValidity } = useGenericGet();

  useEffect(() => {
    getDistricts();
  }, []);

  useEffect(() => {
    if (form?.municipality) {
      getDivisions(form?.municipality);
    } else if (municipality) {
      getDivisions(municipality);
    }
  }, [form?.municipality]);

  useEffect(() => {
    if (form?.division) getParishes(form?.division);
  }, [form?.division]);

  useEffect(() => {
    if (form?.parish) getVillages(form?.parish);
  }, [form?.parish]);

  useEffect(() => {
    if (form?.village) getStreets(form?.village);
  }, [form?.village]);

  useEffect(() => {
    getValidity({
      api: CONFIGURABLES_API,
      params: { field: TENDERER_VALIDITY },
    });
  }, []);

  useEffect(() => {
    if (form?.municipality) {
      getRevenuesourceItem({
        api: TENDERER_REVENUE_SOURCE_ITEM_API,
        params: { municipality: form.municipality },
      });
    }
  }, [form?.municipality]);

  return {
    districts,
    divisions,
    parishes,
    villages,
    streets,
    revenuesourceitems: revenuesourceitems?.results || [],
    tenderervalidity: tenderervalidity?.results || [],
  };
};

export default useTenderer;
