import React from "react";
import PropTypes from "prop-types";
// import { Box } from "@mui/material";
// import Footer from "./Footer";
// import Navigation from "./Navigation";

const PortalLayout = ({ children }) => {
  return <div>{children}</div>;
};

PortalLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PortalLayout;
