import React, { useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { Row, Col } from "antd";
import useForm from "../../hooks/common/useForm";
import FormInput from "../common/FormInput";
import PhoneNumberInput from "../common/PhoneNumberInput";
import { useAuth } from "../../context/authContext";
import axiosInstance from "../../utils/axios";
import { parseJwt } from "../../utils/helperUtil";
import { useAppContext } from "../../context/AppContext";

function EditProfileDialog({ open, setOpen, setError, setSuccess }) {
  // const { profileData } = useUsers();
  const { updateContext } = useAppContext();

  const { form, updateFormValues, updateFormValue, onChange } = useForm();
  const { userData } = useAuth();

  // const { userData } = useAuth();

  function handleClose() {
    setOpen(false);
  }

  function handleCheck() {
    setOpen(false);
  }

  function handleCheck() {
    setOpen(false);
    setSuccess(false);
    setError(false);
    axiosInstance
      .patch(`accounts/users/${userData?.id}/`, {
        // first_name: form?.first_name,
        // last_name: form?.last_name,
        ...form,
      })
      .then((res) => {
        updateContext("userData", res?.data);
        localStorage.setItem("userData", JSON.stringify(res?.data));
        setSuccess(true);
        // navigate("../users", { replace: true });
      })
      .catch((err) => {
        // handle error
        setError(true);
      });
  }

  useEffect(() => {
    updateFormValues({
      first_name: userData?.first_name,
      last_name: userData?.last_name,
      email: userData?.email,
      username: userData?.username,
      mobile_no: userData?.mobile_no,
    });
  }, []);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Edit Profile</DialogTitle>
      <DialogContent>
        <Box width={2200} />
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <FormInput
              label="First Name"
              type="text"
              name="first_name"
              onChange={onChange}
              value={form.first_name}
              required
            />
          </Col>
          <Col span={12}>
            <FormInput
              label="Last Name"
              type="text"
              name="last_name"
              onChange={onChange}
              value={form.last_name}
              required
            />
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <FormInput
              label="Username"
              type="text"
              name="username"
              onChange={onChange}
              value={form.username}
              required
            />
          </Col>
          <Col span={12}>
            <FormInput
              label="Email"
              type="text"
              name="email"
              onChange={onChange}
              value={form.email}
              required
            />
          </Col>
        </Row>
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <PhoneNumberInput
              label="Mobile No."
              type="text"
              country="ug"
              name="mobile_no"
              onChange={(value) => updateFormValue("mobile_no", value)}
              value={form?.mobile_no}
            />
          </Col>
        </Row>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleCheck}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditProfileDialog;
