import { Radio } from "antd";
import React from "react";

const RadioGroup = ({ options, onChange }) => {
  // const [value, setValue] = useState(1);
  const handleChange = (e) => {
    let value = e.target.value;
    onChange(value);
    //   setValue(e.target.value);
  };

  return (
    <Radio.Group
      onChange={handleChange}
      defaultValue={options && options[0]?.value}
      buttonStyle="solid"
    >
      {options?.map((val) => {
        const { value, label } = val;
        return <Radio.Button value={value}>{label}</Radio.Button>;
      })}
    </Radio.Group>
  );
};

export default RadioGroup;
