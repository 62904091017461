import React, { useEffect } from "react";
import PropTypes from "prop-types";
import FormInput from "../../common/FormInput";
import useGenericGet from "../../../hooks/common/useGenericGet";
import useForm from "../../../hooks/common/useForm";
import Loader from "../../common/Loader";
import { GROUND_RENT_API } from "../../../constants/api";

const GroundRentAssessment = ({ tax_id, formId, proceed }) => {
  const { form, updateFormValue, onChange } = useForm();
  const { loadData, data: rent, loading } = useGenericGet();

  useEffect(() => {
    if (tax_id) {
      loadData({ api: GROUND_RENT_API, id: tax_id });
    }
  }, [tax_id]);

  const onSubmit = (event) => {
    event.preventDefault();
    proceed({ amount: rent?.ground_rent_amount });
  };

  // useEffect(() => {
  //   updateFormValue("assessed_value", Number(rent?.current_premium || 0));
  // }, [rent?.current_premium]);

  if (loading) return <Loader />;

  return (
    <form id={formId} onSubmit={onSubmit}>
      <div className="panel panel-primary">
        <div className="panel-heading">
          <div className="panel-title">Generate Bill for Groud Rent </div>
        </div>

        <div className="panel-body">
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <div className="form-group">
                <FormInput
                  label="Annual Charge Amount (UGX) "
                  onChange={(value) =>
                    updateFormValue("ground_rent_amount", value)
                  }
                  value={Number(rent?.ground_rent_amount || 0).toLocaleString()}
                  disabled
                  required
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

GroundRentAssessment.propTypes = {
  rent: PropTypes.shape({
    amount: PropTypes.number,
  }),
};

export default GroundRentAssessment;
