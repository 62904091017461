import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import FormInput from "../../common/FormInput";
import useGenericGet from "../../../hooks/common/useGenericGet";
import Loader from "../../common/Loader";
import {
  LOCAL_SERVICE_TAX_API,
  LST_EMPLOYEES_API,
  LST_GRADES_API,
  LST_REASSESSMENT_API,
  TURNOVER_THRESHOLD_API,
} from "../../../constants/api";
import { Select } from "antd";
import { SubmitButton } from "../../common/Button";
import useGenericSet from "../../../hooks/common/useGenericSet";
import TextArearInput from "../../common/TextArearInput";
import useForm from "../../../hooks/common/useForm";

const LocalServiceTax = ({ tax_id, formId, proceed, invoice_id }) => {
  const { loadData: getLocalServiceTax, data: lst } = useGenericGet();
  const { loadData: getLstEmployees, data: lst_employees } = useGenericGet();
  const { form, onChange } = useForm();

  const { loadData: getSalaryRanges, data: salary_ranges } = useGenericGet();

  const { loadData: getGrades, data: grades, loading } = useGenericGet();
  const { loadData: getRates, data: rates } = useGenericGet();

  const [updatedAmount, setUpdatedAmount] = useState({});
  const [totalAmount, setTotalAmount] = useState(0);
  const [lstEmployees, setLstEmployees] = useState([]);
  const { submitting, error, uploadData, success, data } = useGenericSet();

  useEffect(() => {
    getSalaryRanges({ api: TURNOVER_THRESHOLD_API });
    getGrades({ api: LST_GRADES_API, params: {category: 1} });
    getRates({ api: LST_GRADES_API, params: {category: 2} });
  }, []);


  useEffect(() => {
    if (tax_id) {
      getLocalServiceTax({ api: LOCAL_SERVICE_TAX_API, id: tax_id });
    }

    if (invoice_id) {
      getLstEmployees({ api: LST_EMPLOYEES_API, params: { invoice_id: invoice_id } });
    }
  }, [tax_id]);


  useEffect(() => {
    if (lst_employees) {
      var employees = [];
      const updatedAmountObj = { ...updatedAmount };

      (lst?.category_name === "Persons in gainful employment" ? grades : rates)?.map((grade, key) => (
        lst_employees.map((item, index) => {

          if (grade.id === item?.grade_details?.id) {

            const sum = item?.grade_details?.amount * item?.number;
            updatedAmountObj[key] = sum;

            var lst_employee = {
              old_value: item?.number,
              new_value: item?.number,
              number: item?.number,
              grade: item?.grade_details?.id,
            }
            
            employees.push(lst_employee)
          }
        })
      ))

      const total = Object.values(updatedAmountObj).reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
  
      setUpdatedAmount(updatedAmountObj); // Update the state with the new amount object
      setTotalAmount(total); // Update the state with the new total amount
  
      setLstEmployees([...lstEmployees, ...employees])
    }
  }, [lst_employees]);


  const onSubmit = (event) => {
    event.preventDefault();

    if (lst_employees) {
      uploadData({
        api: LST_REASSESSMENT_API,
        params: {
          new_amount: totalAmount, 
          reassessment_items: lstEmployees, 
          reassessment_reason: form?.reassessment_reason,
          municipality: lst?.municipality_details?.id, 
          division: lst?.division_details?.id, 
          parish: lst?.parish_details?.id, 
          village: lst?.village_details?.id, 
          street: lst?.street_details?.id,
          invoice: invoice_id  
        },
        redirect: `/assessments?tax_id=${tax_id}&tax=lst`,
      });
  
    } else {
      proceed({ amount: totalAmount, lst_employees: lstEmployees });
    }
  };

  if (loading) return <Loader />;

  const handleChange = (value, amount, id, index) => {
    const updatedAmountObj = { ...updatedAmount };
    const sum = amount * value;
    updatedAmountObj[index] = sum;

    const total = Object.values(updatedAmountObj).reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );

    setUpdatedAmount(updatedAmountObj); // Update the state with the new amount object
    setTotalAmount(total); // Update the state with the new total amount

    var exists = false;
    lstEmployees.map((item) => {
       if (item.grade === id) {
         exists = true;
         item.number=parseInt(value);
         item.new_value=parseInt(value);
       } 
    })

    if (exists === false) {
      var lst_employees = {
        number: parseInt(value),
        grade: id,
      }
      setLstEmployees([...lstEmployees, lst_employees]) 
    }
  };
  

  return (
    <form id={formId} onSubmit={onSubmit}>
      <div className="panel panel-primary">
        <div className="panel-heading">
          <div className="panel-title">Generate Bill for Local Service Tax</div>
        </div>

        <div className="panel-body">
          <div className="form-group">
            <div className="row">
              <div className="col-sm-6">
                <h4>Salary Range</h4>
              </div>
              <div className="col-sm-3">
                <h4 className="">Number</h4>
              </div>
              <div className="col-sm-3">
                <h4>Amount</h4>
              </div>
            </div>

            {(lst?.category_name === "Persons in gainful employment" ? grades : rates)?.map((grade, index) => (
              <div className="row lst">
                <div className="col-sm-6" style={{ marginTop: "25px" }}>
                  <Select
                    style={{ width: "100%" }}
                    value={grade?.name || ""}
                    required
                  />
                </div>

                <div className="col-sm-3">
                  <FormInput
                    name="number"
                    type="number"
                    onChange={(event) =>
                      handleChange(
                        event.target.value,
                        grade?.amount,
                        grade?.id,
                        index
                      )
                    }
                    value={lstEmployees ? lstEmployees?.[index]?.number : lst_employees?.map((item) => {
                      if (item?.grade_details?.id === grade?.id)
                          return item?.number;
                    })}
                    onWheelCapture={e => {
                      e.currentTarget.blur()
                    }}                          
                    required
                  />
                </div>
                <div className="col-sm-3">
                  <FormInput
                    style={{ marignBottom: 100 }}
                    type="number"
                    name="amount"
                    value={updatedAmount[index]}
                    disabled
                  />
                </div>
              </div>
            ))}
            <div className="row">
              <div className="col-sm-6" style={{ marginTop: "25px" }}>
                Total Amount: {totalAmount.toLocaleString()}
              </div>
            </div>
          </div>
        </div>
      </div>

      {lst_employees ? 
        <>
          <div className="row">
            <div className="col-sm-12">
                <TextArearInput
                  label="Reassessment Reason"
                  type="text"
                  name="reassessment_reason"
                  row="3"
                  id="reassessment_reason"
                  onChange={onChange}
                  value={form?.reassessment_reason}
                  error={error}
                  required        
                />
            </div>
          </div>
      
          <SubmitButton submitting={submitting} />
        </>
      : null }
    </form>
  );
};

LocalServiceTax.propTypes = {
  lst: PropTypes.shape({
    amount: PropTypes.number,
  }),
};

export default LocalServiceTax;
