import { Link, useLocation } from "react-router-dom";
import { SelectAntTable } from "../../common/tables/SelectAntTable";
import { useState } from "react";
import { Button, Divider, Tag } from "antd";
import { AlertMessage } from "../../common/alert/AlertMessage";

const ReturnPeriodsInvoices = ({ outstanding_invoices }) => {
  const [selected, setSelected] = useState([]);
  const [message, setMessage] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);

  function onSelect(data) {
    var total = 0;
    data.map((val) => {
      total = total + parseInt(val?.balance.replaceAll(",", ""));
    });
    // get invoice ids
    const invoices = data.map((val) => val?.id);
    setTotalAmount(total);
    setSelected(invoices);
  }

  const columns = [
    {
      title: "Bill Number",
      dataIndex: "ref",
    },
    {
      title: "Period",
      dataIndex: "period_name",
    },
    {
      title: "Bill Amount",
      dataIndex: "amount",
    },
    {
      title: "Paid",
      dataIndex: "amount_paid",
    },
    {
      title: "Balance",
      dataIndex: "balance",
    },
  ];

  const data = [
    ...outstanding_invoices?.map((val, i) => {
      return {
        ...val,
        key: i,
        amount: Number(val.amount).toLocaleString(),
        amount_paid: Number(val.amount_paid).toLocaleString(),
        balance: Number(val?.amount - val?.amount_paid).toLocaleString(),
      };
    }),
  ];

  return (
    <div className="flexColumn gap20">
      <AlertMessage message={message}></AlertMessage>
      {/* <Divider /> */}
      <SelectAntTable
        tableTitle="Business Assessed Return Periods"
        tableColumns={columns}
        tableData={data}
        onSelect={onSelect}
        footer={
          <p>
            <b>Total Amount Selected: </b>{" "}
            <Tag
              style={{
                fontSize: 14,
                paddingBottom: 3,
                paddingTop: 7,
                marginLeft: 5,
              }}
            >{`UGX ${Number(totalAmount).toLocaleString()}`}</Tag>
          </p>
        }
      />

      <div>
        <Button
          type="primary"
          onClick={() => {
            if (selected.length === 0) {
              setMessage("Please select atleast one invoice");
            }
          }}
        >
          {selected.length > 0 ? (
            <Link
              style={{ color: "white" }}
              state={{ amount: totalAmount, selected_invoices: selected }}
              to={"/paf/create/multiple"}
            >
              Generate PRN
            </Link>
          ) : (
            "Generate PRN"
          )}
        </Button>
      </div>
    </div>
  );
};

export default ReturnPeriodsInvoices;
