import React from "react";
import PropTypes from "prop-types";

const Error = ({ message }) => {
  return (
    <div
      className="alert alert-danger redColor font13"
      role="alert"
      style={{
        // margin: 10,
        // marginLeft: 15,
        // marginRight: 0,
        padding: 5,
        width: "80%",
      }}
    >
      {message}
    </div>
  );
};

Error.propTypes = {
  message: PropTypes.string.isRequired,
};

export default Error;
