import PropTypes from "prop-types";

export const Visibility = ({ children, show }) => {
  return show ? children : null;
};

Visibility.propTypes = {
  children: PropTypes.node.isRequired,
  show: PropTypes.bool.isRequired,
};
