import React, { useEffect } from "react";
import PropTypes from "prop-types";
import FormInput from "../../common/FormInput";
import useGenericGet from "../../../hooks/common/useGenericGet";
import useForm from "../../../hooks/common/useForm";
import Loader from "../../common/Loader";
import { OPERRATIONAL_PERMIT_API } from "../../../constants/api";

const OperationPermitAssessment = ({ tax_id, formId, proceed }) => {
  const { form, updateFormValue } = useForm();
  const { loadData, data: permit, loading } = useGenericGet();

  useEffect(() => {
    if (tax_id) {
      loadData({ api: OPERRATIONAL_PERMIT_API, id: tax_id });
    }
  }, [tax_id]);

  const onSubmit = (event) => {
    event.preventDefault();
    proceed({ amount: permit?.amount });
  };

  if (loading) return <Loader />;

  return (
    <form id={formId} onSubmit={onSubmit}>
      <div className="panel panel-primary">
        <div className="panel-heading">
          <div className="panel-title">
            Generate Bill for Operational Permit{" "}
          </div>
        </div>

        <div className="panel-body">
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <div className="form-group">
                <FormInput
                  label="Application Fees"
                  value={Number(permit?.r_app_fees || 0).toLocaleString()}
                  disabled
                  required
                />
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="form-group">
                <FormInput
                  label="Waste Fees"
                  value={Number(permit?.r_waste_fees || 0).toLocaleString()}
                  disabled
                  required
                />
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="form-group">
                <FormInput
                  label="Sky Signage"
                  value={Number(permit?.r_sky_signs || 0).toLocaleString()}
                  disabled
                  required
                />
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="form-group">
                <FormInput
                  label="Permit Amount"
                  value={Number(permit?.permit_amount || 0).toLocaleString()}
                  disabled
                  required
                />
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="form-group">
                <FormInput
                  label="LST Payable"
                  value={Number(permit?.lst_payable || 0).toLocaleString()}
                  disabled
                  required
                />
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="form-group">
                <FormInput
                  label="Total Fees"
                  value={Number(permit?.amount || 0).toLocaleString()}
                  disabled
                  required
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

OperationPermitAssessment.propTypes = {
  permit: PropTypes.shape({
    amount: PropTypes.number,
  }),
};

export default OperationPermitAssessment;
