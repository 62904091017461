import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useGenericGet from "../../../hooks/common/useGenericGet";
import Loader from "../../common/Loader";
import Table from "../../common/Table";
import { ARREARS_LEDGER_API, ARREARS_LEDGER_CSV_API, ARREARS_LEDGER_EXCEL_API } from "../../../constants/api";
import useArrearsLedger from "../../../hooks/payments/useArrearsLedger";

const ArrearsLedgerRegister = () => {
  const location = useLocation();
  const [filters, setFilter] = useState({});
  const { loadData: getLedger, data: ledger, loading } = useGenericGet();

  const { ledgerTableData, ledgerTableColumns } = useArrearsLedger();

  useEffect(() => {
    getLedger({ api: ARREARS_LEDGER_API, params: filters });
  }, [location, filters]);

  if (loading) return <Loader />;

  return (
    <div>
      <Table
        tableData={ledgerTableData(ledger?.results || [])}
        tableColumns={ledgerTableColumns}
        tableTitle="Arrears Ledger"
        loading={loading}
        count={ledger?.count}
        onFilterChange={setFilter}
        filters={filters}
        excelDownloadApi={ARREARS_LEDGER_EXCEL_API}
        csvDownloadApi={ARREARS_LEDGER_CSV_API}
      />
    </div>
  );
};

export default ArrearsLedgerRegister;
