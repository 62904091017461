import React, { useEffect } from "react";
import PropTypes from "prop-types";
import FormInput from "../../common/FormInput";
import useGenericGet from "../../../hooks/common/useGenericGet";
import Loader from "../../common/Loader";
import { OUTDOOR_ADVERTISING_API } from "../../../constants/api";

const OutdoorAssessment = ({ tax_id, formId, proceed }) => {
  const { loadData, data: outdoor, loading } = useGenericGet();

  useEffect(() => {
    if (tax_id) {
      loadData({ api: OUTDOOR_ADVERTISING_API, id: tax_id });
    }
  }, [tax_id]);

  const onSubmit = (event) => {
    event.preventDefault();
    proceed({ amount: Number(outdoor?.amount) });
  };

  if (loading) return <Loader />;

  return (
    <form id={formId} onSubmit={onSubmit}>
      <div className="panel panel-primary">
        <div className="panel-heading">
          <div className="panel-title">
            Generate Bill for Outdoor Advertising{" "}
          </div>
        </div>

        <div className="panel-body">
          <div className="row">
            <div className="col-lg-12 col-sm-12">
              <div className="form-group">
                <FormInput
                  label="Amount"
                  value={Number(outdoor?.amount || 0).toLocaleString()}
                  disabled
                  required
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

OutdoorAssessment.propTypes = {
  outdoor: PropTypes.shape({
    amount: PropTypes.number,
  }),
};

export default OutdoorAssessment;
