import { Navigate, Route, Routes } from "react-router-dom";
import { guestRoutes, standardRoutes } from "../../constants/routes";
import { useAuth } from "../../context/authContext";
import PortalLayout from "./PortalLayout";
import DashboardContainer from "../pages/dashboard/DashboardContainer";
import { Suspense } from "react";
import Loader from "../common/Loader";
import { configColor } from "../styled/Theme";

export default function MainContainer() {
  // assign styles to css variable
  document.documentElement.style.setProperty("--selected-color", configColor);
  document.documentElement.style.setProperty("--config-color", configColor);
  // const loggedIn = true;
  const { loggedIn } = useAuth();
  if (loggedIn) {
    return (
      <DashboardContainer>
        <Suspense fallback={<Loader />}>
          <Routes>
            {standardRoutes().map(({ component, exact, path }, i) => (
              <Route path={path} exact={exact} element={component} key={i} />
            ))}
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </Suspense>
      </DashboardContainer>
    );
  }

  return (
    <PortalLayout>
      <Suspense fallback={<Loader />}>
        <Routes>
          {guestRoutes.map(({ component, exact, path }, i) => (
            <Route path={path} exact={exact} element={component} key={i} />
          ))}
          <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
      </Suspense>
    </PortalLayout>
  );
}
