import { Menu } from "antd";
import { Link } from "react-router-dom";

const { SubMenu, Item } = Menu;

export function RecursiveMenu(data) {
  return data.map(
    ({ key, title, Icon, link, click, visible, children = [] }) => {
      if (visible) {
        if (!children?.length) {
          return (
            <Item key={key || Math.random()} icon={Icon}>
              {link ? (
                <Link to={link}>{title}</Link>
              ) : click ? (
                <div onClick={click}>{title}</div>
              ) : (
                <div>{title}</div>
              )}
              {/* onClick */}
            </Item>
          );
        }

        return (
          <SubMenu
            style={{ paddingLeft: 0 }}
            popupOffset={[500, 500]}
            key={Math.random()}
            title={title}
            icon={Icon}
          >
            {RecursiveMenu(children)}
          </SubMenu>
        );
      }

      // if unassigned visibility
      if (visible == null) {
        if (!children?.length) {
          return (
            <Item key={Math.random()} icon={Icon}>
              {link ? (
                <Link to={link}>{title}</Link>
              ) : click ? (
                <div onClick={click}>{title}</div>
              ) : (
                <div>{title}</div>
              )}
              {/* onClick */}
            </Item>
          );
        }

        return (
          <SubMenu
            style={{ paddingLeft: 0 }}
            popupOffset={[500, 500]}
            key={Math.random()}
            title={title}
            icon={Icon}
          >
            {RecursiveMenu(children)}
          </SubMenu>
        );
      }
    }
  );
}
