import React from "react";
import PropTypes from "prop-types";
import useForm from "../../hooks/common/useForm";
import TextArearInput from "./TextArearInput";
import SelectInput from "./SelectInput";
import { SubmitButton } from "./Button";
import Error from "./Error";
import { Visibility } from "./Visibility";
import FileInput from "./FileInput";
import FormInput from "./FormInput";
import {
  CAN_PHYSICAL_PLANNING,
  CAN_PHYSICAL_PLANNING_DIVISION,
  DOC_TYPE_REPORT,
  STATUS_DEFFERED,
  STATUS_REJECTED,
  STATUS_SENT_BACK,
  VALUATION_STATUS_DEFERRED,
} from "../../constants/webapp";
import { GetPermissions, mainPermissions } from "../../utils/permissionsUtils";
import { GetPermissions as GetPermissionRoles } from "../../utils/authUtils";

const GenericActionForm = (props) => {
  const { submitForm, title, actions, submitting, error, hideActions } = props;
  const { form, onChange, updateFormValue, updateFormValues } = useForm();

  const onSubmit = (event) => {
    event.preventDefault();
    submitForm(form);
  };

  return (
    <Visibility
      show={
        GetPermissions([
          ...mainPermissions,
          CAN_PHYSICAL_PLANNING,
          CAN_PHYSICAL_PLANNING_DIVISION,
        ]) || GetPermissionRoles("pvAction")
      }
    >
      <div>
        {/* get permissions for users */}
        <form onSubmit={onSubmit}>
          <div className="panel panel-primary">
            <div className="panel-heading">
              <div className="panel-title">{title}</div>
            </div>

            <div className="panel-body">
              {error ? (
                error.non_field_errors ? (
                  <Error message={error.non_field_errors} />
                ) : (
                  <Error message="Failed to submit data" />
                )
              ) : null}

              <div className="row">
                <Visibility show={!hideActions}>
                  <div className="col-sm-12">
                    <SelectInput
                      options={actions}
                      label="Action"
                      onChange={(value, obj) =>
                        updateFormValues({
                          status: value,
                          status_name: obj.label,
                        })
                      }
                      error={error}
                      required
                    />
                  </div>
                </Visibility>

                <Visibility show={form?.status === "withdrawn"}>
                  <div className="col-sm-12">
                    <FormInput
                      label="Objector Name"
                      type="text"
                      name="objector_name"
                      onChange={onChange}
                      value={form.objector_name}
                      error={error}
                      required
                    />
                  </div>
                </Visibility>

                <div className="col-sm-12">
                  <TextArearInput
                    label="Comment"
                    value={form.action_comment}
                    name="action_comment"
                    onChange={onChange}
                    error={error}
                    required={[
                      STATUS_REJECTED,
                      STATUS_DEFFERED,
                      VALUATION_STATUS_DEFERRED,
                      STATUS_SENT_BACK,
                    ].includes(form?.status)}
                  />
                </div>

                <Visibility
                  show={
                    form.status_name === "SEND BACK" ||
                    form.status_name === "DEFER" ||
                    form.status_name === "REJECT" ||
                    form.status_name === "Reject" ||
                    form.status_name === "Deferred"
                  }
                >
                  <FileInput
                    label="Supporting document"
                    onChange={(value) =>
                      updateFormValue("supporting_documents", value)
                    }
                    name="supporting_documents"
                    // documentType={DOC_TYPE_REPORT}
                    multiple
                    // required
                  />
                </Visibility>
              </div>
            </div>
          </div>
          <div className="form-group">
            <SubmitButton submitting={submitting} />
          </div>
        </form>
      </div>
    </Visibility>
  );
};

GenericActionForm.propTypes = {
  submitting: PropTypes.bool.isRequired,
  hideActions: PropTypes.bool,
  submitForm: PropTypes.func,
  title: PropTypes.string,
  error: PropTypes.shape({ non_field_errors: PropTypes.string }),
  actions: PropTypes.arrayOf(PropTypes.shape({})),
};

export default GenericActionForm;
