import React, { useLayoutEffect, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { GRIEVANCES_QUERY_ASSIGNMENTS_API } from "../../../constants/api";
import useGenericGet from "../../../hooks/common/useGenericGet";
import Table from "../../common/Table";
import useEnquiries from "../../../hooks/enquiries/useEnquiries";
import { TopSection } from "../dashboard/DashboardContent";
import { EnquiriesSummary } from "../dashboard/DashboardConfig";
import { GetPermissions as GetPermissionsRoles } from "../../../utils/authUtils";

const AssignedEnquiries = () => {
  const { status } = useParams();
  const [filters, setFilter] = useState({});
  const location = useLocation();

  const { loadData: getEnquiry, data: enquiries, loading } = useGenericGet();
  const { assignedEnquiryTableData, enquiryTableColumns } = useEnquiries();

  useEffect(() => {
    setFilter({ status });
  }, [location]);

  useLayoutEffect(() => {
    getEnquiry({ api: GRIEVANCES_QUERY_ASSIGNMENTS_API });
  }, [filters]);

  return (
    <div>
      {GetPermissionsRoles("enquiries") && (
        <>
          <TopSection tileData={EnquiriesSummary()} />
          <br />
        </>
      )}
      <Table
        tableData={assignedEnquiryTableData(status, enquiries?.results || [])}
        count={enquiries?.count}
        tableColumns={enquiryTableColumns(status)}
        tableTitle="All Enquiries"
        loading={loading}
        onFilterChange={setFilter}
        filters={filters}
      />
    </div>
  );
};

export default AssignedEnquiries;
