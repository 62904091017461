import { Box } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { Wrapper } from "../../../components/elements/Styled";
import DashboardNavbar from "../../../components/nav-bar/dashboard/DashboardNavbar";
import { configColor, dimWhite } from "../../styled/Theme";
import { LeftMenu } from "../../layouts/LeftMenu";

export const drawerWidth = 240;
export default function DashboardContainer({ children }) {
  // set body background color
  document.body.style.backgroundColor = dimWhite;

  return (
    <>
      <DashboardNavbar />
      <Wrapper style={{ marginTop: 70, padding: 10 }} className="flex">
        <Box sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
          {/* Fixed Sidebar */}
          <Box
            className="overflow"
            position="fixed"
            sx={{
              display: { xs: "none", sm: "block" },
              backgroundColor: "white",
              width: drawerWidth,
              height: "calc(100% - 90px)",
              mb: 1,
              border: "1px solid #ddd",
              // boxShadow: "0px 10px 30px 0px #0000001a",
              borderRadius: 2,
            }}
          >
            <LeftMenu />
          </Box>
        </Box>

        {/* Port View */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width: { xs: "100%", sm: `calc(100% - ${drawerWidth}px)` },
            pl: { sm: 1.5 },
          }}
        >
          {children}
        </Box>
      </Wrapper>
    </>
  );
}

DashboardContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
