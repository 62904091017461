import styled from "styled-components";

export const ButtonLink = styled.button`
  cursor: pointer;
  background: none;
  border: none;
`;

export const LeftMenuButton = styled.button`
  padding: 10px 0 10px 15px;
  background: none;
  border: none;
`;

export const Button = styled.button`
  padding: 5px 0 6px 18px;
  background: none;
  border: none;
`;
