import { Alert } from "antd";
import React, { useEffect, useState } from "react";

export const AlertMessage = ({
  message,
  description,
  type = "error",
  onClose,
  margin,
  timeout,
  closable,
  width,
}) => {
  const [visible, setVisible] = useState(false);

  const handleClose = () => {
    setVisible(false);
    if (onClose) onClose();
  };

  useEffect(() => {
    if (message) setVisible(true);
    if (timeout) setTimeout(() => handleClose(), timeout);
  }, [message, timeout]);

  return (
    <>
      {visible && (
        <Alert
          style={{
            fontSize: 13,
            color: "inherit",
            width: width || "100%",
            padding: 12,
            margin: margin,
          }}
          showIcon
          message={message}
          description={description}
          type={type}
          closable={closable}
          afterClose={handleClose}
        />
      )}
    </>
  );
};
