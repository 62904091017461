import React, { useLayoutEffect, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import useGenericGet from "../../../../hooks/common/useGenericGet";
import Table from "../../../common/Table";
import { PROFESSIONALS_API } from "../../../../constants/api";
import useProfessionals from "../../../../hooks/configurables/professionals/useProfessionals";

const ProfessionalsRegister = () => {
  const { status } = useParams();
  const [filters, setFilter] = useState({});
  const location = useLocation();

  const {
    loadData: getProfessionals,
    data: professionals,
    loading,
  } = useGenericGet();

  const { professionalsTableData, professionalsTableColumns } = useProfessionals();

  useEffect(() => {
    setFilter({ status });
  }, [location]);
  
  useLayoutEffect(() => {
    getProfessionals({ api: PROFESSIONALS_API, params: filters });
  }, [filters]);

  return (
    <div>
        <Link className="btn btn-success" to="create" style={{ marginBottom: 10 }}>
          New Professional
        </Link>
        <Table
          tableData={professionalsTableData(professionals?.results || [])}
          count={professionals?.count}
          tableColumns={professionalsTableColumns}
          tableTitle="Professionals"
          loading={loading}
          onFilterChange={setFilter}
          filters={filters}
        />
    </div>
  );
};

export default ProfessionalsRegister;
