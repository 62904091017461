export const testURL = "http://localhost:9090";
export const ontrLink = "/ontrs/create";
export const playStoreLink =
  "https://play.google.com/store/apps/details?id=com.bzn.tch.prj.irasuganda";
export const prnUrl = "/prn/status/";
export const forgotPasswordUrl = "/user/forgot";
export const loginUrl = testURL + "/user/login-api-v3";
export const registerUrl = "/irasApi_v4/apis/iras-self-register-public";
export const activateUserUrl = "/irasApi_v4/apis/iras-activate-user";
export const yoyaUrl = "https://yoyatechnologies.com";
export const inquiryUrl = "/grievances/create";

// Manual Guides
export const userGuidePublic = "https://iras-v3.gitbook.io/public-user-manual/";
export const userGuideInternal = "https://iras-v3.gitbook.io/user-manual/";
export const posUserGuide = "https://iras.gitbook.io/";
