import { useState } from "react";
import { DASHBOARD_SUMMARY_API } from "../../constants/api";
import axiosInstance from "../../utils/axios";
import { STATUS_SUMMARY } from "../../constants/webapp";
import { getFinancialYear } from "../../utils/Date";
import moment from "moment";

export const currentDate = moment(new Date()).format("YYYY-MM-DD"); // YYYY-MM-DD
function useDashboard(status) {
  const [error, setError] = useState(false);
  const [reports, setReports] = useState([]);

  // default filter
  var defaultFilter = "start_date=" + currentDate;
  // Summary filter
  if (status === STATUS_SUMMARY) {
    defaultFilter = "fin_year=" + getFinancialYear();
  }

  // Filter
  const [filter, setFilter] = useState(defaultFilter);
  const [loading, setLoading] = useState(false);

  const updateFilter = (value) => {
    setFilter(filter + value);
  };

  // Get Reports
  const getSummaryData = async () => {
    setLoading(true);
    await axiosInstance
      .get(`${DASHBOARD_SUMMARY_API}?${filter}`)
      .then((res) => {
        // handle success
        setReports(res.data);
      })
      .catch((err) => {
        // handle error
        setError(err);
      });
    setLoading(false);
  };
  return {
    getSummaryData,
    reports,
    error,
    setFilter,
    updateFilter,
    filter,
    loading,
  };
}

export default useDashboard;
