import { useState } from "react";
import {
  DISTRICTS_API,
  PARISHES_API,
  STREETS_API,
  SUB_COUNTIES_API,
  VILLAGES_API,
} from "../../constants/api";
import axiosInstance from "../../utils/axios";

function useLocationData() {
  const [districts, setDistricts] = useState([]);
  const [divisions, setDivision] = useState([]);
  const [parishes, setParishes] = useState([]);
  const [villages, setVillages] = useState([]);
  const [streets, setStreets] = useState([]);
  const [loading, setLoading] = useState(false);

  const dataCall = (api, fallback, id, params) => {
    const API = id ? `${api}/${id}/` : api;
    setLoading(true);
    axiosInstance
      .get(API, { params })
      .then(({ data }) => {
        // handle success
        fallback(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        // handle error
      });
  };

  const getDistricts = () => {
    dataCall(DISTRICTS_API, setDistricts, null);
  };

  const getDivisions = (parent) => {
    dataCall(SUB_COUNTIES_API, setDivision, null, { parent: parent });
  };

  const getParishes = (parent) => {
    dataCall(PARISHES_API, setParishes, null, { parent: parent });
  };

  const getVillages = (parent) => {
    dataCall(VILLAGES_API, setVillages, null, { parent: parent });
  };
  const getStreets = (parent) => {
    dataCall(STREETS_API, setStreets, null, { parent: parent });
  };

  const locationViewData = (item, isFormData) => ({
    district: isFormData ? item.district_name : item?.district_details?.name,
    division: isFormData ? item.division_name : item?.division_details?.name,
    parish: isFormData ? item.parish_name : item?.parish_details?.name,
    village: isFormData ? item.village_name : item?.village_details?.name,
    street: isFormData ? item.street_name : item?.street_details?.name,
    physical_address: item.physical_address,
    latitude: item.latitude,
    longitude: item.longitude,
  });

  return {
    districts,
    getDistricts,
    divisions,
    getDivisions,
    parishes,
    getParishes,
    villages,
    getVillages,
    streets,
    getStreets,
    locationViewData,
    loading,
  };
}

export default useLocationData;
