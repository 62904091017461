import { ledgerTabs } from "../../../constants/settings";
import GenericTabView from "../../common/tabs/GenericTabView";

export const LedgerGeneralView= () => {
  return (
    <div className="radius6 whiteBg">
      <GenericTabView tabs={ledgerTabs} />
    </div>
  );
};
