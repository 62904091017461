import React from "react";
import { DatePicker, Space } from "antd";
import PropTypes from "prop-types";
import Error from "./Error";
import dayjs from "dayjs";

const DateInput = (props) => {
  const {
    label,
    name,
    value,
    error,
    defaultValue,
    disabledDate,
    onChange,
    ...rest
  } = props;

  return (
    <div className="form-group field-user-cin">
      {error && error[name] && <Error message={error[name]} />}
      <label>
        {label} {rest.required && <span className="text-danger">*</span>}
      </label>
      <div>
        <Space direction="vertical" style={{ width: "100%" }}>
          <DatePicker
            onChange={(date) => onChange(date)}
            value={value}
            style={{ width: "100%", height: 39 }}
            getPopupContainer={(node) => node.parentNode}
            disabledDate={disabledDate}
            // eslint-disable-next-line
            {...rest}
          />
        </Space>
      </div>
    </div>
  );
};

DateInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.shape({}),
  disabledDate: PropTypes.func,
};

export default DateInput;

// Can not select days after the current date
export const disableAfterToday = (current) => {
  return current && current > dayjs().endOf("day");
};
