import React from "react";
import CheckPRNDialog from "../dialog/CheckPRN";
import PermitStatus from "../dialog/PermitStatus";

export const Modals = () => {
  return (
    <>
      <CheckPRNDialog />
      <PermitStatus />
    </>
  );
};
