import { Notifications } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { List, Tag } from "antd";
import moment from "moment";
import useGenericSet from "../../../hooks/common/useGenericSet";
import { useEffect } from "react";
import { READ_ALL_NOTIFICATIONS_API } from "../../../constants/api";

export const NotificationsList = ({ notifications, count, setCount }) => {
  const { uploadData: updateNotifications } = useGenericSet();

  useEffect(() => {
    if (count !== 0) {
      setCount(0);
      updateNotifications({ api: READ_ALL_NOTIFICATIONS_API });
    }
  }, []);

  return (
    <List
      // size="small"
      dataSource={notifications?.results}
      renderItem={(item) => (
        <List.Item style={{ paddingInline: "20px" }}>
          <div className="flexSpaceCenter gap10 x100">
            <div className="flex gap10">
              <IconButton sx={{ height: 30, width: 30 }}>
                <Notifications fontSize="small" />
              </IconButton>
              <div className="font12">{item?.message}</div>
            </div>
            <div className="greyColor">
              <Tag style={{ fontSize: 10 }}>
                {moment(item?.created_at).fromNow()}
              </Tag>
              <p style={{ fontSize: 10, marginTop: 5 }}>
                {moment(item?.created_at).format("DD MMM YYYY")}
              </p>
            </div>
          </div>
          {/* <IconButton>
            <MoreVert />
          </IconButton> */}
        </List.Item>
      )}
    />
  );
};
