import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import useForm from "../../../hooks/common/useForm";
import useURALocationData from "../../../hooks/common/useURALocationData";
import Error from "../../common/Error";
import FormInput from "../../common/FormInput";
import { SOURCE_NO, SOURCE_YES } from "../../../constants/webapp";
import useGenericGet from "../../../hooks/common/useGenericGet";
import {
  GENDER_API,
  MARITAL_STATUS_API,
  TITLE_API,
  URA_BRN_VALIDATION_API,
  URA_NIN_VALIDATION_API,
  VALIDATE_TIN_API,
} from "../../../constants/api";
import DateInput from "../../common/DateInput";
import { SubmitButton } from "../../common/Button";
import SelectInput from "../../common/SelectInput";
import TextArearInput from "../../common/TextArearInput";
import { Visibility } from "../../common/Visibility";
import { useToasts } from "react-toast-notifications";
import moment from "moment";

const IndividualInstantTinForm = (props) => {
  const { id } = useParams();
  const { submitForm, submitting, error, formId } = props;
  const { form, updateFormValues, onChange, updateFormValue } = useForm({});
  const { data: maritalstatus, loadData: getMaritalStatus } = useGenericGet();
  const { data: gender, loadData: getGender } = useGenericGet();
  const { data: title, loadData: getTitle } = useGenericGet();
  const [source, setSource] = useState(SOURCE_YES);
  const [sourceincome, setSourceIncome] = useState(SOURCE_YES);
  const [sourceemployment, setSourceEmployment] = useState(SOURCE_YES);
  const { loadData: getBRNDetails, data: brnDetails } = useGenericGet();
  const { loadData: getNINDetails, data: nindetail } = useGenericGet();
  const { loadData: getTINDetails, data: tinDetails } = useGenericGet();
  const { addToast } = useToasts();

  const {
    districts,
    getDistricts,
    counties,
    getCounties,
    subcounties,
    getSubCounties,
    parishes,
    getParishes,
    villages,
    getVillages,
  } = useURALocationData();

  useEffect(() => {
    getMaritalStatus({
      api: MARITAL_STATUS_API,
    });
    getGender({
      api: GENDER_API,
    });
    getTitle({
      api: TITLE_API,
    });
  }, []);

  useEffect(() => {
    if (form.brn && form?.brn?.length === 14) {
      getBRNDetails({
        api: URA_BRN_VALIDATION_API,
        params: { brn: form.brn },
      });
    }
  }, [form.brn]);

  useEffect(() => {
    if (form.nin && form?.nin?.length === 14) {
      getNINDetails({
        api: URA_NIN_VALIDATION_API,
        params: { nin: form.nin },
      });
    }
  }, [form.nin]);

  useEffect(() => {
    if (form.employer_tin && form?.employer_tin?.length === 10) {
      getTINDetails({
        api: VALIDATE_TIN_API,
        params: { tin: form.employer_tin },
      });
    }
  }, [form.employer_tin]);

  useEffect(() => {
    if (brnDetails) {
      if (brnDetails?.isValid) {
        const dateArray = brnDetails?.incorporationDate?.split("/");
        updateFormValues({
          business_name: brnDetails?.entityName,
          establishment_date: moment(
            `${dateArray[1]}/${dateArray[0]}/${dateArray[2]}`
          ),
        });
      } else {
        addToast("Please enter a valid BRN", {
          appearance: "error",
          autoDismiss: false,
        });
      }
    }
  }, [brnDetails]);

  useEffect(() => {
    if (Object.keys(nindetail || {}).length) {
      if (nindetail?.isValid) {
        const dateArray = nindetail?.personDateOfBirth?.split("/");
        updateFormValues({
          surname: nindetail?.personSurname
            ? nindetail?.personSurname
            : form?.surname,
          first_name: nindetail?.personGivenNames
            ? nindetail?.personGivenNames
            : form?.first_name,
          middle_name: nindetail?.personOtherNames
            ? nindetail?.personOtherNames
            : form?.middle_name,
          date_of_birth: moment(
            `${dateArray[1]}/${dateArray[0]}/${dateArray[2]}`
          ),
        });
      } else {
        addToast("Please enter a valid NIN", {
          appearance: "error",
          autoDismiss: false,
        });
      }
    }
  }, [nindetail]);

  useEffect(() => {
    if (tinDetails) {
      if (tinDetails?.errorDesc === "INVALID TIN") {
        addToast("Please enter a correct/complete TIN", {
          appearance: "error",
          autoDismiss: false,
        });
      } else {
        updateFormValue("taxpayer_name", tinDetails?.taxPayerName);
      }
    }
  }, [tinDetails]);

  useEffect(() => {
    getDistricts();
  }, []);

  useEffect(() => {
    if (form?.district) getCounties(form?.district);
  }, [form?.district]);

  useEffect(() => {
    if (form?.county) getSubCounties(form?.district, form?.county);
  }, [form?.county]);

  useEffect(() => {
    if (form?.sub_county)
      getParishes(form?.district, form?.county, form?.sub_county);
  }, [form?.sub_county]);

  useEffect(() => {
    if (form?.parish)
      getVillages(form?.district, form?.county, form?.sub_county, form?.parish);
  }, [form?.parish]);

  const tinOptions = [
    {
      id: SOURCE_YES,
      name: "Yes",
    },
    {
      id: SOURCE_NO,
      name: "No",
    },
  ];

  const onSubmit = (e) => {
    e.preventDefault();
    submitForm({
      ...form,
      pwd_expiry_status: "active",
      first_name: form.first_name,
      last_name: form.last_name,
    });
  };

  return (
    <form onSubmit={onSubmit} id={formId}>
      {error ? (
        error.non_field_errors ? (
          <Error message={error.non_field_errors} />
        ) : (
          <Error message="Failed to create account" />
        )
      ) : null}
      <div className="panel panel-primary">
        <div className="panel-heading">
          <div className="panel-title">SECTION A: PERSONAL DETAILS</div>
        </div>

        <div className="panel-body">
          <div className="row">
            <div className="col-sm-6">
              <FormInput
                label="Identification Number(NIN)"
                placeholder="Enter Identification Number(NIN)"
                type="text"
                name="nin"
                onChange={onChange}
                value={form.nin}
                error={error}
                maxLength={14}
                minLength={14}
                required
              />
            </div>
            <div className="col-sm-6">
              <FormInput
                label="Surname"
                type="text"
                name="surname"
                onChange={onChange}
                value={form.surname}
                error={error}
                required
                readOnly
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <FormInput
                label="First Name"
                type="text"
                name="first_name"
                onChange={onChange}
                error={error}
                value={form.first_name}
                readOnly
              />
            </div>
            <div className="col-sm-6">
              <FormInput
                label="Other Name"
                type="text"
                name="middle_name"
                onChange={onChange}
                error={error}
                value={form.maiden_name}
                readOnly
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <SelectInput
                label="Gender"
                options={(gender || []).map((sup) => ({
                  label: sup.name,
                  value: sup.id,
                }))}
                onChange={(value) => updateFormValue("gender", value)}
                value={form.gender || ""}
                error={error}
                required
              />
            </div>
            <div className="col-sm-6">
              <SelectInput
                label="Marital Status"
                options={(maritalstatus || []).map((sup) => ({
                  label: sup.name,
                  value: sup.id,
                }))}
                onChange={(value) => updateFormValue("marital_status", value)}
                value={form.marital_status || ""}
                error={error}
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <SelectInput
                label="Title"
                options={(title || []).map((sup) => ({
                  label: sup.id,
                  value: sup.id,
                }))}
                onChange={(value) => updateFormValue("title", value)}
                value={form.title || ""}
                error={error}
                required
              />
            </div>
            <div className="col-sm-6">
              <DateInput
                label="Date of Birth"
                id="date_of_birth"
                name="date_of_birth"
                onChange={(value) => updateFormValue("date_of_birth", value)}
                value={form.date_of_birth}
                error={error}
                format="DD/MM/YYYY"
                required
                disabled
              />
            </div>
          </div>
        </div>
      </div>
      <div className="panel panel-primary">
        <div className="panel-heading">
          <div className="panel-title">SECTION:C: INCOME SOURCE DETAILS</div>
        </div>
        <div className="panel-body">
          <div className="row">
            <div className="col-sm-12">
              <SelectInput
                label="Is source of income a business ?"
                name="income_source_business"
                onChange={(value) => setSource(value)}
                options={tinOptions.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                value={source}
                error={error}
              />
            </div>
          </div>
          <Visibility show={source === SOURCE_YES}>
            <div className="row">
              <div className="col-sm-6">
                <FormInput
                  label="Business Registration Number"
                  type="text"
                  name="brn"
                  onChange={onChange}
                  value={form.brn}
                  maxLength={14}
                  minLength={14}
                  error={error}
                  required
                />
              </div>
              <div className="col-sm-6">
                <FormInput
                  label="Trading Or Business Name"
                  type="text"
                  name="business_name"
                  onChange={onChange}
                  value={form.business_name}
                  error={error}
                  required
                  readOnly
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <DateInput
                  label="Establishment Date"
                  type="date"
                  name="establishment_date"
                  format="DD/MM/YYYY"
                  onChange={onChange}
                  value={form.establishment_date}
                  error={error}
                  required
                  disabled
                />
              </div>
            </div>
          </Visibility>
          <div className="row">
            <div className="col-sm-12">
              <SelectInput
                label="Is source of income employment ?"
                name="income_source_employment"
                onChange={(value) => setSourceIncome(value)}
                options={tinOptions.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                value={sourceincome}
                error={error}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <FormInput
                label="Employer TIN"
                type="text"
                name="employer_tin"
                onChange={onChange}
                value={form.employer_tin}
                maxLength={10}
                minLength={10}
                error={error}
                required
              />
            </div>
            <div className="col-sm-6">
              <FormInput
                label="Taxpayer Name"
                type="text"
                name="taxpayer_name"
                onChange={onChange}
                value={form.taxpayer_name}
                error={error}
                required
                readOnly
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <SelectInput
                label="Is source of income informal business ?"
                name="incource_source_informal"
                onChange={(value) => setSourceEmployment(value)}
                options={tinOptions.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                value={sourceemployment}
                error={error}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <TextArearInput
                label="Business Description"
                placeholder="Business Description"
                type="text"
                name="description"
                onChange={onChange}
                value={form.description}
                error={error}
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <SelectInput
                label="Is source of income rent ?"
                options={tinOptions.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                onChange={(value) =>
                  updateFormValue("incource_source_rent", value)
                }
                name="incource_source_rent"
                value={form.incource_source_rent || ""}
                error={error}
              />
            </div>
            <div className="col-sm-6">
              <SelectInput
                label="Is source Propery ?"
                options={tinOptions.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                onChange={(value) =>
                  updateFormValue("incource_source_property", value)
                }
                name="incource_source_property"
                value={form.incource_source_property || ""}
                error={error}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="panel panel-primary">
        <div className="panel-heading">
          <div className="panel-title">PERSONAL DETAILS</div>
        </div>

        <div className="panel-body">
          <div className="row">
            <div className="col-sm-6">
              <FormInput
                label="Telephone Number"
                type="text"
                name="telephone_number"
                onChange={onChange}
                value={form.telephone_number}
                maxLength={10}
                error={error}
                required
              />
            </div>
            <div className="col-sm-6">
              <FormInput
                label="Email Address"
                type="text"
                name="email"
                onChange={onChange}
                error={error}
                value={form.email}
                required
              />
            </div>
          </div>
          <div class="row">
            <div className="col-lg-6 col-sm-12">
              <SelectInput
                options={districts.map((sup) => ({
                  label: sup.name,
                  value: sup.id,
                }))}
                id="district"
                name="district"
                label="Home District"
                onChange={(value) => updateFormValue("district", value)}
                value={form?.district || ""}
                error={error}
                required
              />
            </div>
            <div className="col-lg-6 col-sm-12">
              <SelectInput
                options={counties.map((sup) => ({
                  label: sup.name,
                  value: sup.id,
                }))}
                id="county"
                name="county"
                label="Home County"
                onChange={(value) => updateFormValue("county", value)}
                value={form?.county || ""}
                maxMenuHeight={250}
                error={error}
                required
              />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <SelectInput
                options={subcounties.map((sup) => ({
                  label: sup.name,
                  value: sup.id,
                }))}
                id="sub_county"
                name="sub_county"
                label="Home Sub County"
                onChange={(value) => updateFormValue("sub_county", value)}
                value={form?.sub_county || ""}
                maxMenuHeight={250}
                error={error}
                required
              />
            </div>
            <div className="col-sm-6">
              <SelectInput
                options={parishes.map((sup) => ({
                  label: sup.name,
                  value: sup.id,
                }))}
                id="parish"
                name="parish"
                label="Home Parish"
                onChange={(value) => updateFormValue("parish", value)}
                value={form?.parish || ""}
                maxMenuHeight={250}
                error={error}
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <SelectInput
                options={villages.map((sup) => ({
                  label: sup.name,
                  value: sup.id,
                }))}
                id="village"
                name="village"
                label="Home Village"
                onChange={(value) => updateFormValue("village", value)}
                value={form?.village || ""}
                maxMenuHeight={250}
                error={error}
                required
              />
            </div>
          </div>
        </div>
      </div>
      <div className="submit">
        <div className="form-group">
          <SubmitButton submitting={submitting} isEdit={id} />
        </div>
      </div>
    </form>
  );
};

IndividualInstantTinForm.propTypes = {
  form: PropTypes.shape({
    type: PropTypes.string,
  }),
  submitting: PropTypes.bool,
  onChange: PropTypes.func,
  updateFormValue: PropTypes.func,
  submitForm: PropTypes.func,
  error: PropTypes.shape({
    non_field_errors: PropTypes.string,
  }),
};

export default IndividualInstantTinForm;
