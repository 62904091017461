import { useState, useEffect } from "react";
import { Tabs, Button } from "antd";
import EditProfileDialog from "../../dialog/EditProfileDialog";
import { useToasts } from "react-toast-notifications";
import { useNavigate } from "react-router-dom";

const GenericTabView = ({ tabs, setShowAuditTable }) => {
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const { addToast } = useToasts();
  const navigate = useNavigate();

  const currentUrl = window.location.pathname;

  useEffect(() => {
    if (error) {
      addToast("Failed to submit", {
        appearance: "error",
        autoDismiss: true,
      });
    }

    if (success) {
      addToast("You have succesfully updated user profile", {
        appearance: "success",
        autoDismiss: true,
      });
      navigate("../users/profile", { replace: true });
    }
  }, [success, error]);

  const operations = (
    <Button
      onClick={() => {
        setOpen(true);
      }}
    >
      Edit Profile
    </Button>
  );

  const handleTabClick = (value) => {
    setShowAuditTable(value);
  };

  return (
    <>
      <EditProfileDialog
        open={open}
        setOpen={setOpen}
        setSuccess={setSuccess}
        setError={setError}
      />
      <Tabs
        tabBarExtraContent={currentUrl === "/users/profile" ? operations : ""}
        tabBarStyle={{ paddingInline: 20 }}
        defaultActiveKey="1"
        onTabClick={setShowAuditTable !== undefined ? handleTabClick : null}
        items={tabs.map((val, i) => {
          const { title, Icon, component } = val || {};
          return {
            label: (
              <span>
                {Icon}
                {title}
              </span>
            ),
            key: i,
            children: (
              <div
                style={{
                  padding: 15,
                  // overflow: "auto",
                  // height: "calc(100vh - 155px)",
                }}
              >
                {component}
              </div>
            ),
          };
        })}
      />
    </>
  );
};
export default GenericTabView;
