import "aos/dist/aos.css";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "webdatarocks/webdatarocks.css";
import { Toaster } from "react-hot-toast";
import { useEffect, useState } from "react";
import Aos from "aos";
import { ThemeProvider } from "@mui/material";
import { Helmet } from "react-helmet";
import { BrowserRouter } from "react-router-dom";
import MainContainer from "./components/layouts/MainContainer";
import { AppContext } from "./context/AppContext";
import { antdTheme, theme } from "./components/styled/Theme";
import { ConfigProvider } from "antd";
import { AuthProvider } from "./context/authContext";
import { ToastProvider } from "react-toast-notifications";
import { Modals } from "./components/layouts/Modals";

function App() {
  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);

  const [contextData, setContextData] = useState({});

  return (
    <ThemeProvider theme={theme}>
      <ConfigProvider theme={antdTheme}>
        <ToastProvider>
          <AppContext.Provider value={{ contextData, setContextData }}>
            <Toaster />
            <Helmet>
              <link rel="preconnect" href="https://fonts.googleapis.com" />
              <link
                rel="preconnect"
                href="https://fonts.gstatic.com"
                crossorigin
              />
              <link
                href="https://fonts.googleapis.com/css2?family=Khula:wght@300;400;600;800&display=swap"
                rel="stylesheet"
              />
            </Helmet>
            <BrowserRouter>
              <Modals />
              <AuthProvider>
                <MainContainer />
              </AuthProvider>
            </BrowserRouter>
          </AppContext.Provider>
        </ToastProvider>
      </ConfigProvider>
    </ThemeProvider>
  );
}

export default App;
