import React, { useState } from "react";
import moment from "moment";
import { FaEdit, FaEye } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/axios";
import { useAuth } from "../../context/authContext";
import {
  INDIVIDUAL,
  NON_INDIVIDUAL,
  NON_UGANDAN,
  STATUS_APPROVED,
  UGANDAN,
} from "../../constants/webapp";
import {
  ActivateButton,
  DeactivateButton,
} from "../../components/common/Button";
import { Tooltip } from "antd";
import { returnObject } from "../../utils/helperUtil";
import { DECTIVATE_USER_API } from "../../constants/api";

function useUsers() {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const [users, setUsers] = useState({});
  const [message, setMessage] = useState();
  const [singleUser, setSingleUser] = useState({});
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [id, setID] = useState();

  const deactivateApi = DECTIVATE_USER_API;
  const typeApi = "users";

  const { user } = useAuth();

  const activateUser = (id) => {
    setLoading(true);
    axiosInstance
      .get(`accounts/users/reactivate-user/?id=${id}`)
      .then(() => {
        // handle success
        setSubmitting(false);
        setMessage("User Succesfully Deactivated");
        navigate("../users", { replace: true });
      })
      .catch((err) => {
        // handle error
        setLoading(false);
        setError(err);
      });
  };

  // Get list of Users
  const getUsers = () => {
    setLoading(true);
    axiosInstance
      .get(`accounts/users/?type=1`)
      .then(({ data }) => {
        // handle success
        setUsers(data);
        setLoading(false);
      })
      .catch((err) => {
        // handle error
        setLoading(false);
        setError(err);
      });
  };

  // Add new user
  const addUser = (data) => {
    const body = {
      conf_password: data.conf_password,
      password: data.password,
      is_superuser: true,
      username: data.username,
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      type: data.type ? data.type : "3",
      profile: {
        department: data.department,
        lead_agency: data.lead_agency,
        alternative_phone_number: data.alternative_phone_number,
      },
    };
    setSubmitting(true);
    setError(false);
    axiosInstance
      .post("register-user/", body)
      .then(() => {
        // handle success
        setSubmitting(false);
        // Redirect to Profile;
        navigate("../users", { replace: true });
      })
      .catch(({ msg }) => {
        // handle error
        setLoading(false);
        setSubmitting(false);
        setError(msg);
      });
  };

  // Get single User
  const getSingleUser = (id) => {
    setLoading(true);
    axiosInstance
      .get(`accounts/users/${id}`)
      .then((res) => {
        // handle success
        setSingleUser(res.data);
        setLoading(false);
      })
      .catch((err) => {
        // handle error
        setLoading(false);
        setError(err);
      });
  };

  // Update User
  const updateUser = (id, data) => {
    const body = {
      conf_password: data.conf_password,
      password: data.password,
      username: data.username,
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      type: data.type ? data.type : "3",
      profile: {
        department: data.department,
        lead_agency: data.lead_agency,
        alternative_phone_number: data.alternative_phone_number,
      },
      is_active: data.is_active,
    };
    setSubmitting(true);
    axiosInstance
      .patch(`accounts/users/${id}/`, body)
      .then(() => {
        // handle success
        setSubmitting(false);
        // Redirect to Profile;
        navigate(`/users/view/${id}`, { replace: true });
      })
      .catch(({ msg }) => {
        // handle error
        setSubmitting(false);
        setLoading(false);
        setError(msg);
      });
  };

  const addLeadAgency = (data) => {
    const body = {
      conf_password: data.conf_password,
      password: data.password,
      is_superuser: true,
      username: data.username,
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      type: data.type ? data.type : "4",
      profile: {
        department: data.department,
        lead_agency: data.lead_agency,
        alternative_phone_number: data.alternative_phone_number,
      },
    };
    setSubmitting(true);
    setError(false);
    axiosInstance
      .post("register-user/", body)
      .then(() => {
        // handle success
        setSubmitting(false);
        navigate("../users", { replace: true });
      })
      .catch(({ msg }) => {
        // handle error
        setLoading(false);
        setSubmitting(false);
        setError(msg);
      });
  };

  // Change Password
  const changePassword = (data) => {
    setSubmitting(true);
    axiosInstance
      .post(`users/change-password/`, data)
      .then(() => {
        // handle success
        setSubmitting(false);
        setMessage("Password updated succesfully");
        navigate("../users", { replace: true });
        // Redirect to Practitioners Regiter;
      })
      .catch((err) => {
        // handle error
        setSubmitting(false);
        setLoading(false);
        if (err.response.data.old_password) {
          setError(err.response.data.old_password);
        }

        if (err.response.data.non_field_errors) {
          setError(err.response.data.non_field_errors);
        }
      });
  };

  // Request Password Reset
  const requestPasswordReset = (data) => {
    setSubmitting(true);
    axiosInstance
      .post("password_reset/", data)
      .then(() => {
        // handle success
        setSubmitting(false);
        setMessage(
          "Check your email for an instruction to reset your password"
        );
      })
      .catch((err) => {
        // handle error
        let msg = err.response?.data?.email || err.message;
        setSubmitting(false);
        setLoading(false);
        setError(msg);
      });
  };

  // Password Reset
  const resetPassword = (data) => {
    setSubmitting(true);
    axiosInstance
      .post("password_reset/confirm/", data)
      .then(() => {
        // handle success
        setSubmitting(false);
        setMessage(
          "Your password has been succesfully reset. You can proceed to login"
        );
        // navigate("../login", { replace: true });
      })
      .catch((err) => {
        // handle error
        setSubmitting(false);
        setLoading(false);
        setError(err?.response?.data);
      });
  };

  // Check token validity
  const tokenValidity = (token, password) => {
    const data = JSON.stringify({ token, password });
    const body = JSON.stringify({ token });
    setSubmitting(true);
    axiosInstance
      .post("password_reset/validate_token/", body)
      .then(() => {
        // handle success
        resetPassword(data);
      })
      .catch(() => {
        setSubmitting(false);
        setLoading(false);
        setError(
          "The token to reset your password is not valid. Request for a new password reset!"
        );
      });
  };

  const verifyOtp = (mobile_no, otp) => {
    localStorage.setItem("mobileNumber", mobile_no);

    setLoading(true);
    axiosInstance
      .get(`accounts/users/verify-otp/?mobile_no=${mobile_no}&otp=${otp}`)
      .then(() => {
        // handle success

        navigate("../user/set-password", { replace: true });
      })
      .catch((err) => {
        // handle error
        setLoading(false);
        setError(err.response.data.error);
      });
  };

  const requestOtp = (mobile_no) => {
    setLoading(true);
    axiosInstance
      .get(`accounts/users/request-otp/?mobile_no=${mobile_no}`)
      .then(() => {
        // handle success
        setMessage("Check your phone for your One Time Password");
      })
      .catch((err) => {
        // handle error
        setLoading(false);
        setError(err.response.data.error);
      });
  };

  // Set Password
  const setPassword = (data) => {
    setSubmitting(true);
    axiosInstance
      .post(`users/set-password/`, data)
      .then(() => {
        // handle success
        setSubmitting(false);
        setMessage("Password set succesfully");
        navigate("../user/login", { replace: true });
      })
      .catch((err) => {
        // handle error
        setSubmitting(false);
        setLoading(false);

        if (err.response.data.non_field_errors) {
          setError(err.response.data.non_field_errors);
        }
      });
  };

  const profileData = () => ({
    id: user.username,
    first_name: user.first_name,
    last_name: user.last_name,
    username: user.username,
    email: user.email,
    mobile_number: user.mobile_no,
  });

  const viewData = (item, isFormData, ownershipType, user) => ({
    ...returnObject(!isFormData, {
      first_name: `${item?.first_name}`,
      last_name: `${item?.last_name}`,
      username: item.username,
    }),
    ...returnObject(ownershipType === INDIVIDUAL, {
      first_name: item?.first_name,
      last_name: item?.last_name,
      nationality: item?.profile?.nationality,
      NIN: item.cin,
    }),
    ...returnObject(ownershipType === NON_INDIVIDUAL, {
      legal_name: item?.legal_name,
      brn: item?.brn,
    }),

    email: item.email,
    mobile_no: item.mobile_no,

    ...returnObject(item?.nationality === UGANDAN, {
      NIN: item.cin,
    }),
    ...returnObject(item?.nationality === NON_UGANDAN, {
      "Passport Number": item.cin,
    }),
    role: isFormData
      ? item?.role_name
        ? item?.role_name
        : user?.role_details?.name
      : item.role_details?.name,
    municipality: isFormData
      ? item?.municipality_name
        ? item?.municipality_name
        : user?.profile?.municipality_name
      : item?.profile?.municipality_details?.name,
    division: isFormData
      ? item?.division_name
        ? item?.division_name
        : user?.profile?.division_name
      : item?.profile?.division_details?.name,
    parish: isFormData
      ? item?.parish_name
        ? item?.parish_name
        : user?.profile?.parish_name
      : item?.profile?.parish_details?.name,
    village: isFormData
      ? item?.village_name
        ? item?.village_name
        : user?.profile?.village_name
      : item?.profile?.village_details?.name,
    street: isFormData
      ? item?.street_name
        ? item?.street_name
        : user?.profile?.street_name
      : item?.profile?.street_details?.name,
    department: isFormData
      ? item?.department_name
        ? item?.department_name
        : user?.profile?.department
      : item?.profile?.department_details?.name,
    status: item.is_active ? "Active" : "Deactivated",
    date_joined: moment(item?.date_joined).format("YYYY-MM-DD"),
    reason: item?.banned_reason,
  });

  const viewCompanyData = (item, isFormData) => ({
    ...returnObject(!isFormData, {
      full_name: `${item.profile?.full_name}`,
      username: item.username,
    }),
    full_name: item?.legal_name,
    email: item.email,
    mobile_no: item.mobile_no,
    nationality: item?.profile?.nationality,
    ...returnObject(item?.nationality === UGANDAN, {
      NIN: item.cin,
    }),
    ...returnObject(item?.nationality === NON_UGANDAN, {
      "Passport Number": item.cin,
    }),
    role: isFormData ? item?.role_name : item.role_details?.name,
    municipality: isFormData
      ? item?.municipality_name
      : item?.profile?.municipality_details?.name,
    division: isFormData
      ? item?.division_name
      : item?.profile?.division_details?.name,
    parish: isFormData
      ? item?.parish_name
      : item?.profile?.parish_details?.name,
    village: isFormData
      ? item?.village_name
      : item?.profile?.village_details?.name,
    street: isFormData
      ? item?.street_name
      : item?.profile?.street_details?.name,
    status: item.is_active ? "Active" : "Deactivated",
    date_joined: moment(item?.date_joined).format("YYYY-MM-DD"),
  });

  const viewRoleData = (item) => ({
    "ROLE DESCRIPTION": "ROLE DETAILS",
    name: item?.role_details?.name,
    can_admin: item?.role_details?.can_admin ? "Yes" : "No",
    can_public: item?.role_details?.can_public ? "Yes" : "No",
    can_collect: item?.role_details?.can_collect ? "Yes" : "No",
    can_registertax: item?.role_details?.can_registertax ? "Yes" : "No",
    can_municipality: item?.role_details?.can_municipality ? "Yes" : "No",
    can_division: item?.role_details?.can_division ? "Yes" : "No",
    can_parish: item?.role_details?.can_parish ? "Yes" : "No",
    can_village: item?.role_details?.can_village ? "Yes" : "No",
    can_street: item?.role_details?.can_street ? "Yes" : "No",
    can_managetaxpayer: item?.role_details?.can_managetaxpayer ? "Yes" : "No",
    can_viewglobal: item?.role_details?.can_viewglobal ? "Yes" : "No",
    can_enumerate: item?.role_details?.can_enumerate ? "Yes" : "No",
    can_collect_tax: item?.role_details?.can_collect_tax ? "Yes" : "No",
    app_can_public: item?.role_details?.app_can_public ? "Yes" : "No",
    app_can_admin: item?.role_details?.app_can_admin ? "Yes" : "No",
    app_can_register: item?.role_details?.app_can_register ? "Yes" : "No",
    app_can_approve: item?.role_details?.app_can_approve ? "Yes" : "No",
    can_register_planning: item?.role_details?.can_register_planning
      ? "Yes"
      : "No",
    can_approve_building_operation: item?.role_details
      ?.can_approve_building_operation
      ? "Yes"
      : "No",
    can_approve_development_permit: item?.role_details
      ?.can_approve_development_permit
      ? "Yes"
      : "No",
    can_market_admin: item?.role_details?.can_market_admin ? "Yes" : "No",
  });
  const usersTableData = (data) => [
    ...data.map((item) => {
      const row = {
        full_name: item?.display_name,
        username: item.username,
        email: item.email,
        mobile_no: item.mobile_no,
        cin: item.cin,
        role: item.role_name,
        municipality_name: item?.municipality_name,
        division_name: item?.division_name,
        reason: item?.banned_reason,
        status: item.is_active ? "Active" : "Deactivated",
        date_joined: moment(item?.date_joined).format("YYYY-MM-DD"),
        actions: (
          <>
            <Tooltip title="View">
              <Link
                class="mr-2"
                style={{ padding: 5, paddingBottom: 0 }}
                to={`/users/view/${item.id}`}
              >
                <FaEye />
              </Link>
            </Tooltip>
            <Tooltip title="Edit">
              <Link
                style={{ padding: 5, paddingBottom: 0 }}
                class=" mr-2"
                to={`/users/update/${item.id}`}
              >
                <FaEdit />
              </Link>
            </Tooltip>
            {item?.is_active === false && item?.is_active === false ? (
              <>
                <ActivateButton onClick={() => activateUser(item.id)} />
              </>
            ) : null}

            <DeactivateButton
              onClick={() => {
                // deactivateUser(item.id);
                setOpen(true);
                setID(item.id);
              }}
            />
          </>
        ),
      };
      return row;
    }),
  ];

  const usersTableColumns = [
    { id: "full_name", header: "Full Name", filter: {} },
    { id: "username", header: "Username", filter: {} },
    { id: "role", header: "Role", filter: {} },
    { id: "municipality_name", header: "Municipality", filter: {} },
    { id: "division_name", header: "Division", filter: {} },
    { id: "email", header: "Email ", filter: {} },
    { id: "mobile_no", header: "Mobile Number ", filter: {} },
    { id: "cin", header: "CIN ", filter: {} },
    { id: "reason", header: "Reason", filter: {} },
    { id: "status", header: "Status ", filter: {} },
    { id: "actions", header: "Actions" },
  ];

  const getActions = () => {
    return [
      {
        value: STATUS_APPROVED,
        label: "Approve",
      },
    ];
  };

  const getRedirect = (status) => {
    let redirect = "";
    switch (status) {
      default:
        redirect = `users`;
        break;
    }

    return redirect;
  };

  const resetMessages = () => {
    setMessage(null);
    setError(null);
  };

  return {
    typeApi,
    deactivateApi,
    usersTableData,
    usersTableColumns,
    getUsers,
    addUser,
    addLeadAgency,
    getSingleUser,
    updateUser,
    open,
    setOpen,
    id,
    singleUser,
    users,
    error,
    loading,
    submitting,
    message,
    changePassword,
    profileData,
    viewData,
    viewCompanyData,
    viewRoleData,
    requestPasswordReset,
    resetPassword,
    resetMessages,
    tokenValidity,
    getRedirect,
    getActions,
    verifyOtp,
    setPassword,
    requestOtp,
  };
}

export default useUsers;
