import React from "react";
import { Link } from "react-router-dom";

export const Breadcrumb = ({ viewData, titleLink }) => {
  return (
    <div>
      {titleLink && (
        <ul className="breadcrumb">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to={titleLink.link}>{titleLink.title}</Link>
          </li>
          {viewData?.name ? (
            <li className="active"> Details for {viewData?.name}</li>
          ) : null}
        </ul>
      )}
    </div>
  );
};
