import React from "react";
import spinner from "../../res/img/spinner.gif";
import { GenericLoader } from "./loaders/GenericLoader";

const Loader = () => <GenericLoader />;

export const ModalLoader = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <img src={spinner} alt="Loader" />
    </div>
  );
};

export default Loader;
