import { Table } from "antd";
import PropTypes from "prop-types";
import { useAppContext } from "../../../context/AppContext";

export const AntTable = (props) => {
  const { tableData, tableColumns, tableTitle, scroll } = props;
  const { contextData, updateContext } = useAppContext();

  const defaultTitle = () => <h3>{tableTitle}</h3>;

  const tableProps = {
    bordered: true,
    size: "small",
    tableLayout: "fixed",
    title: tableTitle ? defaultTitle : undefined,
    scroll: { x: scroll.x },
  };
  const onChange = (pagination, filters, sorter, extra) => {
    var balance = 0;
    var paid = 0;
    var amount = 0;
    extra.currentDataSource.forEach((element) => {
      balance = balance + parseInt(element.balance.replaceAll(",", ""));
      paid = paid + parseInt(element.amount_paid.replaceAll(",", ""));
      amount = amount + parseInt(element.amount.replaceAll(",", ""));
    });
    updateContext("filterData", {
      balance: balance,
      amount: amount,
      paid: paid,
    });
  };
  return (
    <Table
      {...tableProps}
      onChange={onChange}
      columns={tableColumns}
      dataSource={tableData}
    />
  );
};
AntTable.propTypes = {
  tableData: PropTypes.arrayOf({}),
  tableColumns: PropTypes.arrayOf({}),
  tableTitle: PropTypes.string,
};
