import { useState } from "react";
import axiosInstance from "../../utils/axios";
import {
  URA_COUNTIES_API,
  URA_DISTRICTS_API,
  URA_PARISHES_API,
  URA_SUB_COUNTIES_API,
  URA_VILLAGES_API,
} from "../../constants/api";

function useURALocationData() {
  const [districts, setDistricts] = useState([]);
  const [counties, setCounties] = useState([]);
  const [subcounties, setSubCounties] = useState([]);
  const [parishes, setParishes] = useState([]);
  const [villages, setVillages] = useState([]);
  const [loading, setLoading] = useState(false);

  const dataCall = (api, fallback, id, params) => {
    const API = id ? `${api}/${id}/` : api;
    setLoading(true);
    axiosInstance
      .get(API, { params })
      .then(({ data }) => {
        // handle success
        fallback(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        // handle error
      });
  };

  const getDistricts = () => {
    dataCall(URA_DISTRICTS_API, setDistricts, null);
  };

  const getCounties = (district_id) => {
    dataCall(URA_COUNTIES_API, setCounties, null, {
      district_id,
      classifier_id: 2,
    });
  };

  const getSubCounties = (district_id, county_id) => {
    dataCall(URA_SUB_COUNTIES_API, setSubCounties, null, {
      district_id,
      county_id,
      classifier_id: 3,
    });
  };

  const getParishes = (district_id, county_id, sub_county_id) => {
    dataCall(URA_PARISHES_API, setParishes, null, {
      district_id,
      county_id,
      sub_county_id,
      classifier_id: 4,
    });
  };

  const getVillages = (district_id, county_id, sub_county_id, parish_id) => {
    dataCall(URA_VILLAGES_API, setVillages, null, {
      district_id,
      county_id,
      sub_county_id,
      parish_id,
      classifier_id: 5,
    });
  };

  const locationViewData = (item, isFormData) => ({
    district: isFormData ? item.district_name : item?.district_details?.name,
    county: isFormData ? item.county_name : item?.county_details?.name,
    sub_county: isFormData
      ? item.sub_county_name
      : item?.sub_county_details?.name,
    parish: isFormData ? item.parish_name : item?.parish_details?.name,
    village: isFormData ? item.village_name : item?.village_details?.name,
    street: isFormData ? item.street_name : item?.street_details?.name,
    physical_address: item.physical_address,
    latitude: item.latitude,
    longitude: item.longitude,
  });

  return {
    districts,
    getDistricts,
    counties,
    getCounties,
    subcounties,
    getSubCounties,
    parishes,
    getParishes,
    villages,
    getVillages,
    locationViewData,
    loading,
  };
}

export default useURALocationData;
