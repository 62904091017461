import React, { useEffect } from "react";
import GenericView from "./GenericView";
import useGenericGet from "../../hooks/common/useGenericGet";
import { OUTSTANDING_BALANCE_API } from "../../constants/api";
import Loader from "./Loader";
import DetailsCard from "./cards/DetailsCard";

const OutstandingInvoiceView = ({ tax_id, tax }) => {

    const {
        loadData: getOutstandingBalance,
        loading,
        data: outstanding_balance,
    } = useGenericGet();

    useEffect(() => {
        getOutstandingBalance({
            api: OUTSTANDING_BALANCE_API,
            params: {
                tax_id: tax_id,
                tax: tax
            }
        });
    }, [])


    const outstandingInvoicesViewData = (item) => {
        return {
            amount_paid: item?.amount_paid,
            payment_status: item?.payment_status,
            period_name: item?.period_name,
            ref: item?.ref,
            amount: item?.amount,
        };
    };

    if (loading) return <Loader />;

    return (
        <>
            { outstanding_balance?.outstanding_invoices?.map((item) => {
                return (
                    <DetailsCard
                        viewData={outstandingInvoicesViewData(item) || {}}
                        action={true}
                    />
                );
            }) }  
        </>
    );
};

export default OutstandingInvoiceView;
