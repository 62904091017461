import React from "react";
import { FaEye, FaWpforms, FaRegCalendarCheck } from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  STATUS_PENDING,
  STATUS_ASSESSED,
  GRIEVANCES,
  ADMIN,
} from "../../constants/webapp";
import { returnObject } from "../../utils/helperUtil";
import { useAuth } from "../../context/authContext";
import { Visibility } from "../../components/common/Visibility";
import { Tooltip } from "antd";
import moment from "moment";

const useEnquiries = () => {
  const { userData } = useAuth();

  const enquiryTableData = (status, data) => [
    ...data?.map((item) => {
      const row = {
        ticket: item?.ticket,
        name: item?.name,
        mobile_number: item?.mobile_number,
        email: item?.email,
        address: item?.address,
        details: item?.details,
        location: item?.location,
        municipality: item?.municipality_details?.name,
        division: item?.division_details?.name,
        parish: item?.parish_details?.name,
        village: item?.village_details?.name,
        street: item?.street_details?.name,
        status_display: item?.status_display,
        created_at: moment(item?.created_at).format("YYYY-MM-DD"),
        actions: (
          <Visibility
            show={
              (userData?.support_user_group?.level === 1 &&
                userData?.role_details?.can_municipality === 1) ||
              (userData?.role_details?.can_admin === 1 &&
                userData?.support_user_group?.level === 2) ||
              (userData?.role_details?.can_admin === 1 &&
                userData?.support_user_group?.level === 3)
            }
          >
            <div style={{ whiteSpace: "nowrap" }}>
              <div style={{ whiteSpace: "nowrap" }}>
                <Tooltip title="View">
                  <Link
                    class="mr-2"
                    style={{ padding: 5, paddingBottom: 0 }}
                    to={`/grievances/view/${item.id}`}
                    // to={`/grievances/view/${item?.status}/${item.id}`}
                  >
                    <FaEye />
                  </Link>
                </Tooltip>
                {status === "100" ? (
                  <>
                    <Tooltip title="Assign Query">
                      <Link
                        class="mr-2"
                        style={{ padding: 5, paddingBottom: 0 }}
                        to={`/grievances/assign-query/${item.id}`}
                        // to={`/grievances/view/${item?.status}/${item.id}`}
                      >
                        <FaWpforms />
                      </Link>
                    </Tooltip>
                    <Tooltip title="Report Query">
                      <Link
                        class="mr-2"
                        style={{ padding: 5, paddingBottom: 0 }}
                        // to={`/grievances/view/${item?.user_query_details?.status}/${item?.user_query_details?.id}`}
                        to={`/grievances/report-query/${item?.user_query_details?.id}`}
                      >
                        <FaRegCalendarCheck />
                      </Link>
                    </Tooltip>
                  </>
                ) : null}
              </div>
            </div>
          </Visibility>
        ),
      };
      return row;
    }),
  ];

  const viewData = (item, isFormData) => {
    return {
      Ticket: item?.ticket,
      Name: item?.name,
      "Mobile Number": item?.mobile_number,
      Email: item?.email,
      Address: item?.address,
      Details: item?.details,
      Location: item?.location,
      Municipality: isFormData
        ? item?.municipality_name
        : item?.municipality_details?.name,
      Division: isFormData ? item?.division_name : item?.division_details?.name,
      Parish: isFormData ? item?.parish_name : item?.parish_details?.name,
      Village: isFormData ? item?.village_name : item?.village_details?.name,
      Street: isFormData ? item?.street_name : item?.street_details?.name,
      Status: item?.status_display,
      "Created At": moment(item?.created_at).format("YYYY-MM-DD"),
    };
  };

  const enquiryTableColumns = (status) => [
    { id: "ticket", header: "Ticket", filter: {} },
    ...returnObject(status === STATUS_ASSESSED, [
      { id: "name", header: "Name", filter: {} },
    ]),
    { id: "mobile_number", header: "Mobile Number ", filter: {} },
    { id: "email", header: "Email", filter: {} },
    { id: "address", header: "Address", filter: {} },
    { id: "details", header: "Details", filter: {} },
    { id: "location", header: "Location", filter: {} },
    { id: "municipality", header: "Municipality ", filter: {} },
    { id: "division", header: "Division", filter: {} },
    { id: "parish", header: "Parish ", filter: {} },
    { id: "village", header: "Village", filter: {} },
    { id: "street", header: "Street ", filter: {} },
    { id: "status_display", header: "Status", filter: {} },
    { id: "created_at", header: "Created At", filter: {} },
    ...returnObject(
      (userData?.support_user_group?.level === 1 &&
        userData?.role_details?.can_municipality === 1) ||
        (userData?.role_details?.can_admin === 1 &&
          userData?.support_user_group?.level === 2) ||
        (userData?.role_details?.can_admin === 1 &&
          userData?.support_user_group?.level === 3),
      [
        {
          id: "actions",
          header: "Actions",
          filter: {},
        },
      ]
    ),
  ];

  const assignedEnquiryTableData = (status, data) => [
    ...data?.map((item) => {
      const row = {
        ticket: item?.user_query_details?.ticket,
        name: item?.user_query_details?.name,
        mobile_number: item?.user_query_details?.mobile_number,
        email: item?.user_query_details?.email,
        address: item?.user_query_details?.address,
        details: item?.user_query_details?.details,
        location: item?.user_query_details?.location,
        municipality: item?.user_query_details?.municipality_details?.name,
        division: item?.user_query_details?.division_details?.name,
        parish: item?.user_query_details?.parish_details?.name,
        village: item?.user_query_details?.village_details?.name,
        street: item?.user_query_details?.street_details?.name,
        status_display: item?.user_query_details?.status_display,
        created_at: moment(item?.created_at).format("YYYY-MM-DD"),
        actions: (
          <div style={{ whiteSpace: "nowrap" }}>
            <div style={{ whiteSpace: "nowrap" }}>
              <Tooltip title="View">
                <Link
                  class="mr-2"
                  style={{ padding: 5, paddingBottom: 0 }}
                  // to={`/grievances/view/${item?.user_query_details?.status}/${item?.user_query_details?.id}`}
                  to={`/grievances/view/${item?.user_query_details?.id}`}
                >
                  <FaEye />
                </Link>
              </Tooltip>
              <Tooltip title="Report Query">
                <Link
                  class="mr-2"
                  style={{ padding: 5, paddingBottom: 0 }}
                  // to={`/grievances/view/${item?.user_query_details?.status}/${item?.user_query_details?.id}`}
                  to={`/grievances/report-query/${item?.user_query_details?.id}`}
                >
                  <FaRegCalendarCheck />
                </Link>
              </Tooltip>
            </div>
          </div>
        ),
      };
      return row;
    }),
  ];

  const getRedirect = (status) => {
    let redirect = "";
    switch (status) {
      case 100:
        redirect = `${GRIEVANCES}/100`;
        break;
      case 101:
        redirect = `${GRIEVANCES}/101`;
      case 102:
        redirect = `${GRIEVANCES}/102`;
      default:
        redirect = `${GRIEVANCES}/100`;
        break;
    }
    return redirect;
  };

  const assignedEnquiryRedirect = (status) => {
    let redirect = "";
    switch (status) {
      default:
        redirect = `grievances/all-enquiries`;
        break;
    }
    return `grievances/all-enquiries`;
  };

  const getActions = (status, approvedID, deferredID, rejectedID) => {
    status = status?.toLowerCase();

    let options = [];
    switch (status) {
      case STATUS_PENDING?.toLowerCase():
        options = [
          { value: approvedID, label: "Approve" },
          { value: rejectedID, label: "Reject" },
          { value: deferredID, label: "Deferred" },
        ];
        break;

      default:
        options = [];
    }

    return options;
  };

  return {
    enquiryTableColumns,
    enquiryTableData,
    assignedEnquiryTableData,
    assignedEnquiryRedirect,
    viewData,
    getRedirect,
    getActions,
  };
};

export default useEnquiries;
