import React, { useEffect } from "react";
import {
  FaAngleDoubleRight,
  FaBan,
  FaEdit,
  FaEye,
  FaGem,
  FaMoneyBill,
} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import {
  DEFERRED_STATUS,
  MARKET_RENTS,
  STATUS_APPROVED,
  STATUS_PENDING,
  STATUS_REJECTED,
  STATUS_SUBMITTED,
  STATUS_ASSESSED,
  MUN_NAME,
  DIV_NAME,
  PAR_NAME,
  VILL_NAME,
  STR_NAME,
  STATUS_DEACTIVATED,
} from "../../constants/webapp";
import {
  downloadThroughAnchorLink,
  returnObject,
} from "../../utils/helperUtil";
import { configColor } from "../../components/styled/Theme";
import { Visibility } from "../../components/common/Visibility";
import useLocationData from "../common/useLocationData";
import useGenericGet from "../common/useGenericGet";
import {
  INVOICE_API,
  MARKETS_API,
  MARKET_FACILITIES_API,
  MARKET_FACILITY_TYPE_API,
  NATURE_OF_MARKET_API,
} from "../../constants/api";
import { DownloadLink } from "../../components/common/Button";
import { Tooltip } from "antd";
import {
  sDivision,
  sMunicipality,
  sParish,
  sStreet,
  sTaxpayerName,
  sVillage,
} from "../../components/layouts/LeftMenu";
import { PanTool } from "@mui/icons-material";
import { taxpayerAmdendables } from "../../utils/statusUtils";
import { MakePaymentRevenueButton } from "../trading-license/useTradingLicense";
import { useAppContext } from "../../context/AppContext";

const useMarketRents = () => {
  const navigate = useNavigate();

  const { contextData, updateContext } = useAppContext();

  const userRole = contextData?.role_name;

  const Assess = (item) => {
    navigate(`/assessments?tax_id=${item.id}&tax=market_rent`, {
      state: { document_data: item },
    });
  };

  const marketRentTableData = (status, data) => [
    ...data.map((item) => {
      const row = {
        application_number: item?.application_number,
        branch_code: item?.branch_code,
        tin: item?.tin,
        market: item?.market_name,
        business_name: item?.business_name,
        facility_type: item?.facility_type_name,
        lock_up_number: item?.lock_up_number,
        mobile_number: item?.mobile_number,
        owner_name: item?.owner_name,
        nin: item?.nin,
        brn: item?.brn,
        grade: item?.grade,
        amount: Number(item?.amount).toLocaleString(),
        nature_of_market: item?.nature_of_market_name,
        [MUN_NAME]: item?.municipality_name,
        [DIV_NAME]: item?.division_name,
        [PAR_NAME]: item?.parish_name,
        [VILL_NAME]: item?.village_name,
        [STR_NAME]: item?.street_name,
        latitude: item?.r_lat,
        longitude: item?.r_lng,
        created_by: item?.created_by_name,
        assessments: (
          <a
            class="mr-2"
            href=""
            style={{ color: configColor }}
            onClick={() => {
              Assess(item);
            }}
          >
            Assessment
          </a>
        ),
        certificate: (
          <DownloadLink
            onClick={() =>
              downloadThroughAnchorLink(
                `${INVOICE_API}print-registration-certificate/?id=${item?.id} &table_name=market_rent`,
                "certificate"
              )
            }
            title="[Registration Certificate]"
          />
        ),
        prn: (
          <MakePaymentRevenueButton
            api={MARKET_RENTS}
            id={item?.id}
            revenue_source={"market_rent"}
          />
        ),

        actions: (
          <div style={{ whiteSpace: "nowrap" }}>
            <div style={{ whiteSpace: "nowrap" }}>
              <Link
                class="mr-2"
                style={{ padding: 5, paddingBottom: 0 }}
                to={`${MARKET_RENTS}/view/${item.id}`}
                // state={{ tax: "market_rent" }}
              >
                <FaEye />
              </Link>

              <Visibility
                show={
                  item?.status_name === "DEFERRED" &&
                  userRole !== "MIS Authority"
                }
              >
                <Link
                  style={{ padding: 5, paddingBottom: 0 }}
                  class=" mr-2"
                  to={`${MARKET_RENTS}/update/${item.id}`}
                >
                  <FaEdit />
                </Link>
              </Visibility>
              <Visibility
                show={
                  ["APPROVED", "PAID"].includes(item?.status_name) &&
                  userRole !== "MIS Authority"
                }
              >
                {item?.active_status === 1 && status === "assessed" ? (
                  <>
                    <Tooltip title="Amendment">
                      <Link
                        style={{ padding: 5, paddingBottom: 0 }}
                        class=" mr-2"
                        to={`/amendment/market_rent/${item.id}`}
                      >
                        <FaGem />
                      </Link>
                    </Tooltip>
                    <Tooltip title="Reassessment">
                      <Link
                        style={{ padding: 5, paddingBottom: 0 }}
                        class=" mr-2"
                        to={`/reassessment/market_rent/${item.id}`}
                      >
                        <FaMoneyBill />
                      </Link>
                    </Tooltip>
                    <Tooltip title="Business Transfer">
                      <Link
                        style={{ padding: 5, paddingBottom: 0 }}
                        class=" mr-2"
                        to={`../business-transfers/request/market-rents/${item.branch_code}`}
                      >
                        <FaAngleDoubleRight />
                      </Link>
                    </Tooltip>
                    <Tooltip title="Objection">
                      <Link
                        style={{ padding: 5, paddingBottom: 0 }}
                        class=" mr-2"
                        to={`/objection/market_rent/${item.id}`}
                      >
                        <PanTool sx={{ fontSize: 16 }} />
                      </Link>
                    </Tooltip>
                    <Visibility show={item?.outstanding_balance == 0}>
                      <Tooltip title="Deactivate">
                        <Link
                          style={{ padding: 5, paddingBottom: 0 }}
                          class=" mr-2"
                          to={`${MARKET_RENTS}/business_deactivation/${item.id}`}
                        >
                          <FaBan />
                        </Link>
                      </Tooltip>
                    </Visibility>
                  </>
                ) : null}
              </Visibility>
            </div>
          </div>
        ),
      };
      return row;
    }),
  ];

  const viewData = (item, isFormData) => {
    return {
      ...returnObject(!isFormData, {
        "Reference Number": item?.application_number,
      }),
      ...returnObject(
        item?.status_details === STATUS_APPROVED ||
          item?.status_details === STATUS_DEACTIVATED,
        {
          "Branch Code": item?.branch_code,
        }
      ),
      tin: item?.tin,
      ...returnObject(isFormData === true, {
        [sTaxpayerName]: `${item?.first_name}` + " " + `${item?.surname}`,
      }),
      ...returnObject(item?.owner_name, {
        [sTaxpayerName]: item?.owner_name,
      }),
      mobile_number: item?.mobile_number,
      email: item?.email,
      nin: item?.nin || item?.cin,
      brn: item?.brn,
      [sMunicipality]: isFormData
        ? item?.municipality_name
        : item?.municipality_name,
      [sDivision]: isFormData ? item?.division_name : item?.division_name,
      [sParish]: isFormData ? item?.parish_name : item?.parish_name,
      [sVillage]: isFormData ? item?.village_name : item?.village_name,
      [sStreet]: isFormData ? item?.street_name : item?.street_name,
      latitude: item?.r_lat,
      longitude: item?.r_lng,
      business_name: item?.business_name,
      market_name: isFormData ? item?.market_name : item?.r_market_name,
      nature_of_market: isFormData
        ? item?.nature_of_market_name
        : item?.nature_of_market_name,
      facility_type: isFormData
        ? item?.facility_type_name
        : item?.facility_type_name,
      grade: item?.grade,
      facility_number: item?.lock_up_number,
      amount: Number(item?.amount).toLocaleString(),
      ...returnObject(!isFormData, {
        outstanding_balance: Number(
          item?.outstanding_balance
        )?.toLocaleString(),
        // Status: item?.status_details,
      }),
      created_by: `${item?.created_by?.first_name} ${item?.created_by?.last_name}`,
    };
  };

  const marketRentTableColumns = (status) => [
    { id: "application_number", header: "Reference Number ", filter: {} },
    ...returnObject(
      status === STATUS_ASSESSED || status === STATUS_DEACTIVATED,
      [{ id: "branch_code", header: "Branch Code", filter: {} }]
    ),
    { id: "business_name", header: "Business Name ", filter: {} },
    { id: "mobile_number", header: "Mobile Number ", filter: {} },
    { id: "owner_name", header: "Owner Name ", filter: {} },
    { id: "nin", header: "NIN", filter: {} },
    { id: "brn", header: "BRN", filter: {} },
    { id: "tin", header: "TIN ", filter: {} },
    { id: "market", header: "Market Name", filter: {} },
    { id: "nature_of_market", header: "Nature of Market", filter: {} },
    { id: "facility_type", header: "Facility Type", filter: {} },
    { id: "lock_up_number", header: "Facility Number", filter: {} },
    { id: "mobile_number", header: "Mobile Number", filter: {} },
    { id: "grade", header: "Grade", filter: {} },
    { id: MUN_NAME, header: "Municipality ", filter: {} },
    { id: DIV_NAME, header: "Division", filter: {} },
    { id: PAR_NAME, header: "Parish ", filter: {} },
    { id: VILL_NAME, header: "Village", filter: {} },
    { id: STR_NAME, header: "Street ", filter: {} },
    { id: "amount", header: "Amount (UGX)", filter: {} },
    { id: "created_by", header: "Created By", filter: {} },
    ...returnObject(status === STATUS_ASSESSED, [
      { id: "assessments", header: "Invoices" },
      { id: "prn", header: "PRNs" },
      { id: "certificate", header: "Registration Certificate" },
    ]),

    { id: "actions", header: "Actions" },
  ];

  const getRedirect = (status) => {
    let redirect = "";
    switch (status.toLowerCase()) {
      case STATUS_SUBMITTED:
        redirect = `${MARKET_RENTS}/${STATUS_PENDING}`;
        break;

      case STATUS_APPROVED:
        redirect = `${MARKET_RENTS}/${STATUS_ASSESSED}`;
        break;

      case STATUS_REJECTED:
        redirect = `${MARKET_RENTS}/${STATUS_REJECTED}`;
        break;

      case DEFERRED_STATUS:
        redirect = `${MARKET_RENTS}/${DEFERRED_STATUS}`;
        break;

      default:
        break;
    }

    return redirect;
  };

  const getActions = (status, approvedID, deferredID, rejectedID) => {
    status = status?.toLowerCase();

    let options = [];
    switch (status) {
      case STATUS_PENDING?.toLowerCase():
        options = [
          { value: approvedID, label: "Approve" },
          { value: rejectedID, label: "Reject" },
          { value: deferredID, label: "Deferred" },
        ];
        break;

      default:
        options = [];
    }

    return options;
  };

  const amendableFields = [
    ...taxpayerAmdendables,
    {
      id: "Municipality/City/District",
      field: "municipality",
      type: "select",
      options: "districts",
    },
    {
      id: "Division/Town Council/Sub County",
      field: "division",
      type: "select",
      options: "divisions",
    },
    { id: "Parish/Ward", field: "parish", type: "select", options: "parishes" },
    {
      id: "Village/Cell/Zone",
      field: "village",
      type: "select",
      options: "villages",
    },
    { id: "Street", field: "street", type: "select", options: "streets" },
    { id: "latitude", field: "r_lat", type: "text" },
    { id: "longitude", field: "r_lng", type: "text" },
    { id: "business_name", field: "business_name", type: "text" },
    { id: "facility_type", type: "select", options: "facilitytypes" },
    { id: "grade", type: "text", field: "grade" },
    { id: "facility_number", field: "lock_up_number", type: "text" },
    {
      id: "nature_of_market",
      field: "nature_of_market",
      type: "select",
      options: "categories",
    },
  ];

  const objectionFields = [
    { id: "tin", field: "tin", type: "text" },
    { id: "mobile_number", field: "mobile_number", type: "tel" },
    { id: "email", field: "email", type: "text" },
    { id: "latitude", field: "r_lat", type: "text" },
    { id: "longitude", field: "r_lng", type: "text" },
    { id: "business_name", field: "business_name", type: "text" },
    { id: "facility_type", type: "select", options: "facilitytypes" },
    { id: "facility_number", field: "lock_up_number", type: "text" },
    {
      id: "nature_of_market",
      field: "nature_of_market",
      type: "select",
      options: "categories",
    },
  ];

  const reassessFields = [
    // {
    //   id: sMunicipality,
    //   field: "municipality",
    //   type: "select",
    //   options: "districts",
    // },
    // { id: sDivision, field: "division", type: "select", options: "divisions" },
    // { id: sParish, field: "parish", type: "select", options: "parishes" },
    // { id: sVillage, field: "village", type: "select", options: "villages" },
    // { id: sStreet, field: "street", type: "select", options: "streets" },
    // { id: "market_name", field: "market", type: "select", options: "markets" },
    {
      id: "amount",
      field: "amount",
      type: "number",
      props: { readOnly: false },
    },
  ];

  return {
    marketRentTableColumns,
    marketRentTableData,
    viewData,
    getRedirect,
    getActions,
    objectionFields,
    amendableFields,
    reassessFields,
  };
};

export const useMarketRentsFormSelect = (
  form,
  updateFormValue,
  municipality
) => {
  const {
    districts,
    getDistricts,
    divisions,
    getDivisions,
    parishes,
    getParishes,
    villages,
    getVillages,
    streets,
    getStreets,
  } = useLocationData();
  const { data: markets, loadData: getMarkets } = useGenericGet();
  const { data: facilitytypes, loadData: getFacilityTypes } = useGenericGet();
  const { data: facilities, loadData: getFacility } = useGenericGet();
  const { data: categories, loadData: getNatureOfMarket } = useGenericGet();
  const { data: grades, loadData: getGrades } = useGenericGet();

  useEffect(() => {
    getDistricts();
  }, []);

  useEffect(() => {
    if (form?.municipality) getDivisions(form?.municipality);
  }, [form?.municipality]);

  useEffect(() => {
    if (form?.division) getParishes(form?.division);
  }, [form?.division]);

  useEffect(() => {
    if (form?.parish) getVillages(form?.parish);
  }, [form?.parish]);

  useEffect(() => {
    if (form?.village) getStreets(form?.village);
  }, [form?.village]);

  useEffect(() => {
    getNatureOfMarket({ api: NATURE_OF_MARKET_API });
  }, []);

  useEffect(() => {
    getFacilityTypes({ api: MARKET_FACILITY_TYPE_API });
  }, []);

  useEffect(() => {
    if (form.facility_type) {
      getFacility({
        api: MARKET_FACILITIES_API,
        params: { facility_type: form.facility_type },
      });
    }
  }, [form.facility_type]);

  useEffect(() => {
    if (form.facility_type) {
      getGrades({
        api: MARKET_FACILITIES_API,
        params: { facility_type: form.facility_type },
      });
    }
  }, [form.facility_type]);

  useEffect(() => {
    if (form.municipality) {
      getMarkets({
        api: MARKETS_API,
        params: { local_government: form.municipality },
      });
    }
  }, [form.municipality]);

  return {
    districts,
    divisions,
    parishes,
    villages,
    streets,
    markets: markets?.results || [],
    facilitytypes: facilitytypes?.results || [],
    facilities: facilities?.results || [],
    categories: categories?.results || [],
    grades,
  };
};

export default useMarketRents;
