import { Drawer, IconButton } from "@mui/material";
import React from "react";
import { LeftMenu } from "../../layouts/LeftMenu";
import { drawerWidth } from "./DashboardContainer";
import { MenuRounded } from "@mui/icons-material";
import { OnRouteChange } from "../../common/drawer/DrawerComponent";

function LeftDrawer() {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  OnRouteChange(() => {
    if (mobileOpen) setMobileOpen(false);
  });

  return (
    <>
      {/* Drawer Button */}
      <IconButton onClick={handleDrawerToggle}>
        <MenuRounded />
      </IconButton>

      {/* Mobile Drawer */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            borderRadius: 0,
            width: drawerWidth,
            // backgroundColor: configColor,
          },
        }}
      >
        <LeftMenu />
      </Drawer>
    </>
  );
}

export default LeftDrawer;
