import moment from "moment";
import { Link } from "react-router-dom";
import { configColor } from "../../components/styled/Theme";
import DetailsCard from "../../components/common/cards/DetailsCard";
import { returnObject } from "../../utils/helperUtil";

function useArrearsLedger() {
  const ledgerTableData = (data) => [
    ...data.map((item) => {
      const row = {
        ref: item?.invoice_details?.ref,
        coa: item?.coa,
        revenue_source: item?.invoice_details?.revenue_source,
        municipality_name: item?.invoice_details?.municipality_name,
        division_name: item?.invoice_details?.division_name,
        ...returnObject(item?.transaction_type === "INV", {
          transaction_type: item?.transaction_type ? "Bill" : "Payment",
        }),
        ...returnObject(item?.transaction_type === "PYT", {
          transaction_type: item?.transaction_type ? "Payment" : "Bill",
        }),
        credit_account: item?.credit_account,
        debit_account: item?.debit_account,
        amount: Number(item?.amount || 0).toLocaleString(),
        created_at: moment(item?.invoice_details?.created_at).format(
          "DD-MM-YYYY"
        ),
        payment_status: (item?.invoice_details?.payment_status)
          .replaceAll("_", " ")
          .toUpperCase(),
        actions: (
          <div style={{ whiteSpace: "nowrap" }}>
            <div style={{ whiteSpace: "nowrap" }}>
              <Link
                to={`/arrears-ledger/view/${item.id}`}
                style={{ color: configColor }}
              >
                View
              </Link>
            </div>
          </div>
        ),
      };
      return row;
    }),
  ];

  const pafViewData = (item) => {
    return {
      PRN: item?.ref,
      revenue_source: item?.revenue_source,
      owner_name: item?.owner_name,
      mobile_number: item?.mobile_number,
      amount: Number(item?.amount || 0).toLocaleString(),
      payment_mode: item?.payment_mode,
    };
  };

  const invoiceViewData = (item) => {
    return {
      Amount: item?.invoice_details?.amount,
    };
  };

  const viewData = (item) => {
    return {
      revenue_source: item?.invoice_details?.revenue_source,
      credit_account: item?.credit_account,
      debit_account: item?.debit_account,
      amount: Number(item?.amount || 0).toLocaleString(),

      ...returnObject(item?.pafs?.length, {
        PAFs: (item?.pafs || []).map((it) => (
          <DetailsCard viewData={pafViewData(it)} />
        )),
      }),
      "INVOICE DETAILS": "",
      "Reference Number": item?.invoice_details?.ref,
      Amount: item?.invoice_details?.amount,
      Status: item?.invoice_details?.payment_status,
      Amount: item?.invoice_details?.amount,
      Period: item?.invoice_details?.period_details,
    };
  };

  const ledgerTableColumns = [
    { id: "ref", header: "Ref Account No.", filter: {} },
    { id: "coa", header: "COA Code", filter: {} },
    { id: "revenue_source", header: "Revenue Source", filter: {} },
    { id: "municipality_name", header: "Municipality", filter: {} },
    { id: "division_name", header: "Division", filter: {} },
    { id: "transaction_type", header: "Narration", filter: {} },
    { id: "credit_account", header: "Credit Account", filter: {} },
    { id: "debit_account", header: "Debit Account", filter: {} },
    { id: "amount", header: "Amount", filter: {} },
    { id: "created_at", header: "Billing Date", filter: {} },
    { id: "payment_status", header: "Payment Status", filter: {} },
    { id: "actions", header: "Actions" },
  ];

  return {
    ledgerTableData,
    ledgerTableColumns,
    viewData,
  };
}

export default useArrearsLedger;
