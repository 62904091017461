import { useAppContext } from "../../context/AppContext";

export const useModals = () => {
  const { updateContext } = useAppContext();

  const setOpenPrn = () => {
    updateContext("openPRN", true);
  };

  const setOpenPermit = (status) => {
    updateContext("openPermit", true);
    updateContext("permitStatus", status);
  };

  return { setOpenPrn, setOpenPermit };
};
