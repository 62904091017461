import React, { useLayoutEffect } from "react";
import { UPDATE_USER } from "../../../constants/webapp";
import useUsers from "../../../hooks/users/useUsers";
import { useAuth } from "../../../context/authContext";
import GenericView from "../../common/GenericView";
import { AccountCircle, CreditCard } from "@mui/icons-material";
import { TagContainer } from "../dashboard/DashboardContent";
import { Button, Progress } from "antd";
import { EditFilled } from "@ant-design/icons";
import { useAppContext } from "../../../context/AppContext";
import { configColor, configSecondary } from "../../styled/Theme";
import styled from "styled-components";
import useGenericGet from "../../../hooks/common/useGenericGet";
import useInvoicesOwner from "../../../hooks/invoices-owner/useInvoicesOwner";
import { INVOICE_OWNER_API } from "../../../constants/api";
import Loader from "../../common/Loader";
import { useUserData } from "../../../hooks/common/useUserData";

export const PaymentTile = ({ data }) => {
  const { title, amount } = data || {};
  return (
    <div
      className="radius6 flexNullCenter gap20 lightBg"
      style={{ padding: "15px 20px", minWidth: 200, width: "100%" }}
    >
      <CreditCard fontSize="large" />
      <div>
        <p className="font13">{title}</p>
        <h2>{amount}</h2>
      </div>
    </div>
  );
};

const UserProfile = () => {
  const { contextData, updateContext } = useAppContext();
  const { role_name, filterData } = contextData;
  const { userData: user } = contextData;

  const profileData = () => ({
    id: user.username,
    first_name: user.first_name,
    last_name: user.last_name,
    username: user.username,
    email: user.email,
    mobile_number: user.mobile_no,
  });

  const {
    loadData: getInvoicesOwner,
    data: invoicesOwner,
    loading,
  } = useGenericGet();

  useLayoutEffect(() => {
    getInvoicesOwner({ api: INVOICE_OWNER_API });
  }, []);

  const { invoiceOwnerTableData, getPaymentData } = useInvoicesOwner(
    invoicesOwner?.results
  );

  useLayoutEffect(() => {
    updateContext("invoicesOwner", invoicesOwner);
    invoiceOwnerTableData();
    getPaymentData();
  }, [invoicesOwner]);

  const paymentDataDetails = [
    {
      title: "Overall Amount Assessed",
      amount: Number(filterData?.amount).toLocaleString() + "/=",
    },
    {
      title: "Overall Amount Paid",
      amount: Number(filterData?.paid).toLocaleString() + "/=",
    },
    {
      title: "Overall Balance",
      amount: Number(filterData?.balance).toLocaleString() + "/=",
    },
  ];

  const PaymentSummary = () => (
    <SummaryWrapper className="panel panel-primary">
      {loading ? (
        <Loader />
      ) : (
        <div className="flex flexColumn gap20">
          <div>
            <h1>Payment Summary</h1>
            <p className="font13">For all business payments and assessments</p>
          </div>

          {paymentDataDetails.map((data, i) => (
            <PaymentTile key={i} data={data} />
          ))}
        </div>
      )}
    </SummaryWrapper>
  );

  const BusinessSummary = () => (
    <SummaryWrapper className="panel panel-primary">
      <div className="flex flexColumn gap20">
        <div>
          <h1>Business Summary</h1>
          <p className="font13">Number of Businesses</p>
        </div>

        <div className="flexSpaceCenter">
          <Progress
            type="dashboard"
            percent={20}
            steps={4}
            format={(percent) => (
              <p className="font13">
                {percent}
                <br />
                Approved
              </p>
            )}
            gapDegree={30}
            strokeColor={configColor}
          />

          <Progress
            type="circle"
            percent={75}
            strokeColor={configSecondary}
            format={(percent) => (
              <p className="font13">
                {percent}
                <br />
                Registered
              </p>
            )}
          />
        </div>
        <div className="font12">
          <div>
            <p>Registered</p>
            <Progress percent={75} strokeColor={configColor} />
          </div>
          <div>
            <p>Approved</p>
            <Progress percent={20} strokeColor={configSecondary} />
          </div>
          <div>
            <p>Pending</p>
            <Progress percent={45} status="active" strokeColor={configColor} />
          </div>
        </div>
      </div>
    </SummaryWrapper>
  );

  return (
    <div className="flex gap20 wrap">
      {/* <FlexWrapper>
        <BusinessSummary />
      </FlexWrapper> */}

      <FlexWrapper>
        <PaymentSummary />
      </FlexWrapper>

      <FlexWrapper>
        <div>
          <div className="panel panel-primary">
            <div className="panel-body">
              <div className="gap20 flexNullCenter wrap">
                <AccountCircle sx={{ fontSize: 70 }} />
                <div>
                  <h2>{user?.username}</h2>
                  <p className="font13" style={{ marginBottom: 10 }}>
                    {user?.email}
                  </p>
                  {role_name && <TagContainer>{role_name}</TagContainer>}
                </div>
                {/* <Button
                  href={UPDATE_USER / user.id}
                  icon={<EditFilled />}
                  style={{ width: "100%" }}
                >
                  Edit Profile
                </Button> */}
              </div>
            </div>
          </div>

          <GenericView viewData={profileData()} action={false} />
        </div>
      </FlexWrapper>
    </div>
  );
};

export default UserProfile;

const FlexWrapper = styled.div`
  flex: 1;
`;

const SummaryWrapper = styled.div`
  padding: 20px;
  height: 100%;
`;
