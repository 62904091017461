import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { PAYMENTS_USER_API } from "../../../constants/api";
import useGenericGet from "../../../hooks/common/useGenericGet";
import usePayments from "../../../hooks/payments/usePayments";
import Loader from "../../common/Loader";
import { AntTable } from "../../common/tables/AntTable";
import { sPayments } from "../../layouts/LeftMenu";
import { PaymentDetails } from "../billing/PaymentDetails";
import { useAppContext } from "../../../context/AppContext";

export const PaymentsPublicUser = () => {
  const location = useLocation();
  const [filters, setFilter] = useState({});
  const { paymentsTableData, paymentsTableColumns_PublicUser } = usePayments();
  const { loadData: getPayments, data: payments, loading } = useGenericGet();
  const { contextData } = useAppContext();
  const { filterData } = contextData;

  useEffect(() => {
    getPayments({ api: PAYMENTS_USER_API, params: filters });
  }, [location, filters]);

  if (loading) return <Loader />;

  return (
    <div>
      <PaymentDetails filterData={filterData} />

      {/* <Table
        tableData={paymentsTableData(payments?.results || [])}
        tableColumns={paymentsTableColumns}
        tableTitle=sPayments
        loading={loading}
        count={payments?.count}
        onFilterChange={setFilter}
        filters={filters}
      /> */}
      <AntTable
        scroll={{ x: 1500 }}
        tableTitle={sPayments}
        tableData={paymentsTableData(payments?.results || [])}
        tableColumns={paymentsTableColumns_PublicUser}
      />
    </div>
  );
};
