// number formatter
const formatter = Intl.NumberFormat("en-US");

export const currencyFormatter = (value) => {
  let formatted = 0;
  if (value) formatted = formatter.format(Math.round(value));
  return `${formatted}/=`;
};

export const numberFormatter = (value) => {
  // Number(Math.round(7735984296.154999)).toLocaleString();
  let formatted = 0;
  if (value) formatted = formatter.format(Math.round(value));
  return formatted;
};

export const scaleFormatter = (value) => {
  let label = Intl.NumberFormat("en", { notation: "compact" }).format(value);
  return label;
};
