import React from "react";
import styled from "styled-components";
import { PaymentTile } from "../users/UserProfile";

export const PaymentDetails = ({ filterData }) => {
  const paymentDataDetails = [
    {
      title: "Overall Amount Assessed",
      amount: Number(filterData?.amount).toLocaleString() + "/=",
    },
    {
      title: "Overall Amount Paid",
      amount: Number(filterData?.paid).toLocaleString() + "/=",
    },
    {
      title: "Overall Balance",
      amount: Number(filterData?.balance).toLocaleString() + "/=",
    },
  ];

  return (
    <GridWrapper>
      {paymentDataDetails.map((data, i) => (
        <PaymentTile key={i} data={data} />
      ))}
    </GridWrapper>
  );
};

const GridWrapper = styled.div`
  gap: 20px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 960px) {
    grid-template-columns: 1fr;
  }
`;
