import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useGenericGet from "../../../hooks/common/useGenericGet";
import Loader from "../../common/Loader";
import Table from "../../common/Table";
import {
  GENERAL_LEDGER_API,
  GENERAL_LEDGER_CSV_API,
  GENERAL_LEDGER_EXCEL_API,
} from "../../../constants/api";
import useGeneralLedger from "../../../hooks/payments/useGeneralLedger";

const GeneralLedgerRegister = () => {
  const location = useLocation();
  const [filters, setFilter] = useState({});
  const { loadData: getLedger, data: ledger, loading } = useGenericGet();

  const { ledgerTableData, ledgerTableColumns } = useGeneralLedger();

  useEffect(() => {
    getLedger({ api: GENERAL_LEDGER_API, params: filters });
  }, [location, filters]);

  if (loading) return <Loader />;

  return (
    <div>
      <Table
        tableData={ledgerTableData(ledger?.results || [])}
        tableColumns={ledgerTableColumns}
        tableTitle="Sub Ledger"
        loading={loading}
        count={ledger?.count}
        onFilterChange={setFilter}
        filters={filters}
      />
    </div>
  );
};

export default GeneralLedgerRegister;
