import { TagSpan } from "../../components/elements/Styled";
import { NOT_PAID, PAID, PARTIALLY_PAID } from "../../constants/webapp";
import { Download, Visibility } from "@mui/icons-material";
import { Button, CircularProgress, IconButton } from "@mui/material";
import { INVOICE_API } from "../../constants/api";
import { useDownloadThroughAnchorLink } from "../../utils/helperUtil";
import { useAppContext } from "../../context/AppContext";
import moment from "moment";

export const CertificateDownloadButton = ({ downloadUrl, filename }) => {
  const { downloadThroughAnchorLink, loading } = useDownloadThroughAnchorLink();

  return (
    <Button
      size="small"
      onClick={() => downloadThroughAnchorLink(downloadUrl, filename)}
    >
      {loading ? (
        <CircularProgress size={16} sx={{ mr: 1 }} />
      ) : (
        <>
          <Download fontSize="small" sx={{ mr: 0.5 }} />
          Certificate
        </>
      )}
    </Button>
  );
};

const useInvoicesOwner = (data = []) => {
  var balance = 0;
  var amount = 0;
  var paid = 0;

  function getRevenueFilters() {
    var revenueFilters = [];

    data?.map((value) => {
      const RevenueSource = {
        text: value?.revenue_source,
        value: value?.revenue_source,
      };

      const checkRevenueSource = (obj) => obj.text === value?.revenue_source;
      if (!revenueFilters.some(checkRevenueSource))
        revenueFilters.push(RevenueSource);
    });
    return revenueFilters;
  }

  const invoiceOwnerTableColumns = () => [
    {
      // width: 100,
      dataIndex: "revenue_source",
      title: "Revenue Source",
      filters: getRevenueFilters(),
      filterSearch: true,
      onFilter: (value, record) =>
        record.revenue_source.toLowerCase() === value.toLowerCase(),
    },
    {
      width: 150,
      dataIndex: "payment_status",
      title: "Payment Status",
      filters: [
        {
          text: "Paid",
          value: "paid",
        },
        {
          text: "Not Paid",
          value: "not_paid",
        },
        {
          text: "Partially Paid",
          value: "partially_paid",
        },
      ],
      onFilter: (value, record) => record.payment_status.props.id === value,
    },
    { dataIndex: "created_at", title: "Billing Date" },
    { dataIndex: "period", title: "Period" },
    {
      // width: 100,
      dataIndex: "amount",
      title: "Amount Assessed",
    },
    { dataIndex: "amount_paid", title: "Amount Paid" },
    {
      // width: 100,
      dataIndex: "balance",
      title: "Balance",
      sorter: (a, b) =>
        parseFloat(a.balance.replaceAll(",", "")) -
        parseFloat(b.balance.replaceAll(",", "")),
    },

    {
      fixed: "right",
      // width: 150,
      title: "Actions",
      dataIndex: "action",
    },
  ];

  function getPaymentStatus(payment_status) {
    var status;
    var tagError = false;
    var id = payment_status;
    switch (payment_status) {
      case NOT_PAID:
        status = "Not Paid";
        tagError = true;
        break;
      case PARTIALLY_PAID:
        status = "Partially Paid";
        tagError = true;
        break;
      case PAID:
        status = "Paid";
        break;
      default:
        break;
    }
    return { status, tagError, id };
  }

  const invoiceOwnerTableData = (setOpenModal) => [
    ...data?.map((item) => {
      const balance_amount = item?.amount - item?.amount_paid;
      const paid_amount = item?.amount_paid;
      const assessed_amount = item?.amount;
      const revenue_source = item?.revenue_source;
      const period = item?.period_details;
      const paymentStatus = getPaymentStatus(item?.payment_status);

      const row = {
        amount_paid: Number(paid_amount).toLocaleString(),
        payment_status: (
          <TagSpan id={paymentStatus.id} error={paymentStatus.tagError}>
            {paymentStatus.status}
          </TagSpan>
        ),
        amount: Number(assessed_amount).toLocaleString(),
        period: period,
        balance: Number(balance_amount).toLocaleString(),
        revenue_source: revenue_source,
        created_at: moment(item?.created_at)?.format("DD-MMM-YYYY"),
        action: (
          <div className="flex wrap">
            <IconButton
              onClick={() => {
                updateContext("invoice_id", item?.id);
                setOpenModal(true);
              }}
            >
              <Visibility fontSize="small" />
            </IconButton>

            {item?.payment_status === PAID ? (
              revenue_source.includes("Trade License") ||
              revenue_source.includes("Permit") ? (
                <CertificateDownloadButton
                  downloadUrl={`${INVOICE_API}print-certificate/?invoice_id=${item?.id}`}
                  filename={`Certificate-${revenue_source}-${period}`}
                />
              ) : null
            ) : null}
          </div>
        ),
      };

      paid = paid + paid_amount;
      amount = amount + assessed_amount;
      balance = balance + balance_amount;

      return row;
    }),
  ];

  const { updateContext } = useAppContext();

  function getPaymentData() {
    const updateData = {
      paid: paid,
      amount: amount,
      balance: balance,
    };
    updateContext("filterData", updateData);
  }

  return {
    invoiceOwnerTableColumns,
    invoiceOwnerTableData,
    getPaymentData,
  };
};

export default useInvoicesOwner;
