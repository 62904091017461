import { profileTabs } from "../../../constants/settings";
import GenericTabView from "../../common/tabs/GenericTabView";

export const UserProfileView = () => {
  return (
    <div className="radius6 whiteBg">
      <GenericTabView tabs={profileTabs} />
    </div>
  );
};
