import mLogo from "./logo.svg";
import mLogoWhite from "./logo-white.svg";
import mProfile from "./login/profile.svg";
import mFPassword from "./login/forgot-password.svg";
import mNin from "./login/nin.svg";
import mVerified from "./login/verified.svg";
import bg_cube from "./others/bg-cube.svg";
import { ReactComponent as mCollectionsIcon } from "./dashboard/collections.svg";
import { ReactComponent as mAssessIcon } from "./dashboard/assessment.svg";
import { ReactComponent as mRegisterIcon } from "./dashboard/register.svg";

// Logo
export const Logo = mLogo;
export const LogoWhite = mLogoWhite;

export const ProfileSVG = mProfile;
export const FPassword = mFPassword;
export const NinSVG = mNin;
export const VerifiedSVG = mVerified;
export const bgCube = bg_cube;

// Custom SVG Icons
export const CollectionsIcon = mCollectionsIcon;
export const AssessIcon = mAssessIcon;
export const RegisterIcon = mRegisterIcon;
