import React, { useState } from "react";
import { FaEdit, FaEye } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { LST_CATEGORIES_API } from "../../../constants/api";
import axiosInstance from "../../../utils/axios";
import { DeleteButton } from "../../../components/common/Button";

function useLstCategories() {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const lstCategoryTableColumns = [
    { id: "name", header: "Name", filter: {} },
    { id: "actions", header: "Actions" },
  ];

  // Delete
  const deleteLstCategory = (id) => {
    setLoading(true);
    axiosInstance
      .delete(`configurables/lst-categories/${id}`)
      .then(() => {
        // handle success
        setLoading(false);
        navigate("/lst-categories", { replace: true });
      })
      .catch((err) => {
        // handle error
        setLoading(false);
        setError(err);
      });
  };

  // Get list of Lst Categories
  const getLstCateogories = () => {
    axiosInstance
      .get(LST_CATEGORIES_API)
      .then((res) => {
        // handle success
        setCategories(res.data.results);
      })
      .catch((err) => {
        // handle error
        setError(err);
      });
  };

  //Loading data into the lst categories UI table
  const lstCategoryTableData = (data) => [
    ...data.map((item) => {
      const row = {
        name: item.name,
        actions: (
          <>
            <Link
              class="mr-2"
              style={{ padding: 5, paddingBottom: 0 }}
              to={`/lst-categories/view/${item.id}`}
            >
              <FaEye />
            </Link>
            <Link
              style={{ padding: 5, paddingBottom: 0 }}
              class=" mr-2"
              to={`/lst-categories/update/${item.id}`}
            >
              <FaEdit />
            </Link>
            <DeleteButton onClick={() => deleteLstCategory(item.id)} />
          </>
        ),
      };
      return row;
    }),
  ];

  const viewData = (item) => {
    return {
      Name: item?.name,
    };
  };

  const getRedirect = (status) => {
    let redirect = "";
    switch (status) {
      default:
        redirect = "/lst-categories";
        break;
    }

    return redirect;
  };

  return {
    lstCategoryTableColumns,
    lstCategoryTableData,
    categories,
    getLstCateogories,
    getRedirect,
    viewData,
  };
}

export default useLstCategories;
