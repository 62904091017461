import styled from "styled-components";

import { Logo } from "../../../assets/svg/SvgIcons";

export const GenericLoader = () => {
  return (
    <LoaderWrapper className="flexCenter flexColumn">
      <img
        src={Logo}
        style={{
          // filter: "brightness(0.3)",
          height: 170,
          // marginBottom: 20,
          marginRight: 5,
        }}
      />

      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </LoaderWrapper>
  );
};

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  align-self: center;
  min-height: 50vh;
  @media (min-width: 1024px) {
    max-width: calc(100vw - 240px);
  }
`;
