import React from "react";
import PropTypes from "prop-types";
import { Select } from "antd";
import Error from "./Error";

const SelectInput = (props) => {
  const {
    onChange,
    onSearch,
    label,
    value,
    name,
    options,
    error,
    multiple,
    disabled,
    ...rest
  } = props;

  return (
    <div style={{ marginBottom: "1em" }}>
      {label && (
        <label>
          {label} {rest.required && <span className="text-danger">*</span>}
        </label>
      )}
      <div>
        <Select
          onSearch={onSearch}
          showSearch
          allowClear
          optionFilterProp="children"
          onChange={onChange}
          value={value}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          style={{ display: "block", width: "100%" }}
          options={options}
          mode={multiple}
          disabled={disabled}
          size="large"
          getPopupContainer={(node) => node.parentNode}
          // eslint-disable-next-line
          {...rest}
        />
      </div>
      {error && error[name] && <Error message={error[name]} />}
    </div>
  );
};

SelectInput.propTypes = {
  placeholder: "Select...",
};

SelectInput.propTypes = {
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  multiple: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  error: PropTypes.shape({}),
  disabled: PropTypes.bool,
};

export default SelectInput;
