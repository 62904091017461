import React from "react";
import PropTypes from "prop-types";
import { DeleteButton } from "../Button";

const DetailsCard = ({ viewData, onDelete }) => {
  return (
    <div id="content">
      <div className="outer">
        <div className="inner bg-light lter">
          <div className="trade-license-view container-fluid">
            {onDelete ? <DeleteButton onClick={onDelete} /> : null}
            <div className="row">
              <table
                id="w1"
                className="table table-striped table-bordered detail-view"
              >
                <tbody>
                  {Object.keys(viewData).map((key) => (
                    <tr>
                      <th style={{ textTransform: "capitalize", width: "50%" }}>
                        {String(key).replaceAll("_", " ")}
                      </th>
                      <td style={{ width: "50%" }}>{viewData[key]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

DetailsCard.propTypes = {
  viewData: PropTypes.shape({}),
  onDelete: PropTypes.func,
};

export default DetailsCard;
