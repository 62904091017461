import { Drawer } from "antd";
import { useEffect, useState } from "react";
import { configColor } from "../../styled/Theme";
import { useLocation } from "react-router-dom";

export function OnRouteChange(event) {
  // listen to route change events
  const location = useLocation();
  useEffect(() => {
    // close the drawer
    event();
  }, [location]);
}

export const DrawerComponent = ({
  children,
  placement,
  title,
  button,
  dark = false,
}) => {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  OnRouteChange(() => {
    if (open) setOpen(false);
  });

  return (
    <>
      <div onClick={showDrawer}>{button}</div>

      <Drawer
        title={title}
        placement={placement}
        onClose={onClose}
        // contentWrapperStyle={{ width: width && width }}
        bodyStyle={{ padding: 0, backgroundColor: dark && configColor }}
        open={open}
      >
        {children}
      </Drawer>
    </>
  );
};
