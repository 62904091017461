import { KeyboardArrowDown } from "@mui/icons-material";
import { Tooltip } from "antd";
import styled from "styled-components";

export const FlagIndicator = () => {
  return (
    <Tooltip title="Uganda">
      <FlagWrapper className="flexCenter overflow pointer animate whiteBg">
        <div
          style={{ width: 30, height: 20, borderRadius: 5 }}
          className="overflow"
        >
          <ColorWrapper color="black" />
          <ColorWrapper color="#fadc1f" />
          <ColorWrapper color="#da282e" />
          <ColorWrapper color="black" />
          <ColorWrapper color="#fadc1f" />
          <ColorWrapper color="#da282e" />
        </div>

        <KeyboardArrowDown sx={{ fontSize: 20, ml: 0.5 }} />
      </FlagWrapper>
    </Tooltip>
  );
};

const FlagWrapper = styled.div`
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 8px;
  :hover {
    filter: brightness(80%);
  }
`;

const ColorWrapper = styled.div`
  height: 16.66%;
  width: 100%;
  background-color: ${(props) => props.color};
`;
