import { useState } from "react";
import useAxios from "../axos/useAxios";

const useGenericGet = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const { get } = useAxios();

  const loadData = (options) => {
    const { api, id, params, replace = true } = options;
    if (replace) {
      setData(null);
    }
    setSuccess(false);
    // If id is passed get single instance, other fetch list
    get({
      api: id ? `${api}${id}/` : api,
      loader: setLoading,
      errorHandler: setError,
      successHandler: (res) => {
        setData(res);
        setSuccess(true);
      },
      params,
    });
  };

  return { loading, error, data, loadData, setData, success, setError };
};

export default useGenericGet;
