import React, { useEffect } from "react";
import PropTypes from "prop-types";
import FormInput from "../../common/FormInput";
import useGenericGet from "../../../hooks/common/useGenericGet";
import useForm from "../../../hooks/common/useForm";
import {
  CONFIGURABLES_API,
  TRADING_LICENSE_AMOUNT_API,
  TURNOVER_THRESHOLD_API,
} from "../../../constants/api";
import {
  APPLICATION_FEES,
  SIGN_FEES,
  WASTE_FEES,
} from "../../../constants/webapp";
import useAssessments from "../../../hooks/billing/useAssessments";
import { TRADING_LICENSE_API } from "../../../constants/api";
import Loader from "../../common/Loader";

const TradeLicenseAssessment = ({ tax_id, formId, proceed }) => {
  const { form, updateFormValue } = useForm();
  const { getUsers } = useAssessments();
  const { loadData: getTurnoverThreshold } = useGenericGet();
  const { loadData: getApplicationFees } = useGenericGet();
  const { loadData: getWasteFees } = useGenericGet();
  const { loadData: getSignFees } = useGenericGet();
  const { loadData: getLicenseAmount } = useGenericGet();
  const { loadData, data: tradinglicense, loading } = useGenericGet();

  useEffect(() => {
    if (tax_id) {
      loadData({ api: TRADING_LICENSE_API, id: tax_id });
    }
  }, [tax_id]);

  useEffect(() => {
    getTurnoverThreshold({ api: TURNOVER_THRESHOLD_API });
    getApplicationFees({
      api: CONFIGURABLES_API,
      params: { field: APPLICATION_FEES },
    });
    getWasteFees({ api: CONFIGURABLES_API, params: { field: WASTE_FEES } });
    getSignFees({ api: CONFIGURABLES_API, params: { field: SIGN_FEES } });
    getUsers();
  }, []);

  useEffect(() => {
    updateFormValue("r_turn_amount", form?.turnover_threshold);
  }, [form?.turnover_threshold]);

  useEffect(() => {
    getLicenseAmount({
      api: TRADING_LICENSE_AMOUNT_API,
      params: {
        grade: tradinglicense?.street_details?.grade,
        category: tradinglicense?.trade_sub_category_details?.id,
      },
    });
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();
    proceed({ amount: tradinglicense?.assessed_value });
  };

  if (loading) return <Loader />;

  return (
    <form id={formId} onSubmit={onSubmit}>
      <div className="panel panel-primary">
        <div className="panel-heading">
          <div className="panel-title">Generate Bill for Trade License</div>
        </div>

        <div className="panel-body">
          <div className="row">
            <div className="col-lg-6">
              <FormInput
                label="Grade"
                value={tradinglicense?.grade || ""}
                disabled
                required
              />
            </div>
            <div className="col-lg-6">
              <FormInput
                label="Trading License Amount"
                value={Number(
                  tradinglicense?.r_trd_amount || 0
                ).toLocaleString()}
                disabled
                required
              />
            </div>
            <div className="col-lg-6">
              <FormInput
                label="Business Turnover"
                value={tradinglicense?.r_lst_threshold_details?.name || ""}
                disabled
                required
              />
            </div>
            <div className="col-lg-6">
              <FormInput
                label="LST Amount"
                value={Number(
                  tradinglicense?.r_turn_amount || 0
                ).toLocaleString()}
                disabled
                required
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <FormInput
                label="Application Fees"
                value={Number(tradinglicense?.r_app_fees || 0).toLocaleString()}
                disabled
                required
              />
            </div>
            <div className="col-lg-6">
              <FormInput
                label="Waste Fees"
                value={Number(
                  tradinglicense?.r_waste_fees || 0
                ).toLocaleString()}
                disabled
                required
              />
            </div>
            <div className="col-lg-6">
              <FormInput
                label="Sign Fees"
                value={Number(
                  tradinglicense?.r_sky_signs || 0
                ).toLocaleString()}
                disabled
                required
              />
            </div>

            <div className="col-lg-6">
              <FormInput
                label="Total Amount Payable"
                value={Number(
                  tradinglicense?.assessed_value || 0
                ).toLocaleString()}
                disabled
                required
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

TradeLicenseAssessment.propTypes = {
  tradinglicense: PropTypes.shape({
    amount: PropTypes.number,
    amount: PropTypes.number,
  }),
};

export default TradeLicenseAssessment;
