import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import {
  URA_BRN_VALIDATION_API,
  URA_ENTITY_SUB_TYPE_API,
  URA_ENTITY_TYPE_API,
} from "../../../constants/api";
import { DOC_TYPE_REPORT } from "../../../constants/webapp";
import useForm from "../../../hooks/common/useForm";
import SelectInput from "../../common/SelectInput";
import FormInput from "../../common/FormInput";
import Error from "../../common/Error";
import { SubmitButton } from "../../common/Button";
import FileInput from "../../common/FileInput";
import InstantTinTable from "./InstantTinTable";
import useGenericGet from "../../../hooks/common/useGenericGet";
import useEnityTypeData from "../../../hooks/common/useEntityTypesData";
import useURALocationData from "../../../hooks/common/useURALocationData";
import DateInput from "../../common/DateInput";
import TextArearInput from "../../common/TextArearInput";
import moment from "moment";

const NonIndividualInstantTinForm = (props) => {
  const { id } = useParams();
  const { submitForm, submitting, error, formId } = props;

  const { form, updateFormValues, onChange, updateFormValue } = useForm({});

  const { data: entitytypes, loadData: getEntityTypes } = useGenericGet();
  const { data: entitysutypes, loadData: getEntitySubTypes } = useGenericGet();
  const { data: brnDetail, loadData: getBRNDetails } = useGenericGet();

  const {
    districts,
    getDistricts,
    counties,
    getCounties,
    subcounties,
    getSubCounties,
    parishes,
    getParishes,
    villages,
    getVillages,
  } = useURALocationData();
  const {
    mainactivities,
    getMainActivities,
    mainactivitiesdivision,
    getMainActivitiesDivision,
    mainactivitiesclass,
    getMainActivitiesClass,
  } = useEnityTypeData();

  useEffect(() => {
    getDistricts();
    getMainActivities();
    getEntityTypes({ api: URA_ENTITY_TYPE_API });
  }, []);

  useEffect(() => {
    if (form?.district) getCounties(form?.district);
  }, [form?.district]);

  useEffect(() => {
    if (form?.county) getSubCounties(form?.county);
  }, [form?.county]);

  useEffect(() => {
    if (form?.sub_county) getParishes(form?.sub_county);
  }, [form?.sub_county]);

  useEffect(() => {
    if (form?.parish) getVillages(form?.parish);
  }, [form?.parish]);

  useEffect(() => {
    if (form?.main_activity) getMainActivitiesDivision(form?.main_activity);
  }, [form?.main_activity]);

  useEffect(() => {
    if (form?.main_activity_division)
      getMainActivitiesClass(form?.main_activity_division);
  }, [form?.main_activity_division]);

  useEffect(() => {
    if (form.entity_type) {
      getEntitySubTypes({
        api: URA_ENTITY_SUB_TYPE_API,
        params: { parent: form.entity_type },
      });
    }
  }, [form.entity_type]);

  useEffect(() => {
    if (form.brn) {
      getBRNDetails({
        api: URA_BRN_VALIDATION_API,
        params: { brn: form.brn },
      });
    }
  }, [form.brn]);

  useEffect(() => {
    if (brnDetail) {
      if (brnDetail?.isValid) {
        const dateArray = brnDetail?.incorporationDate?.split("/");
        updateFormValues({
          registered_name: brnDetail?.entityName,
          legal_name: brnDetail?.entityName,
          entity_establishment_date: moment(
            `${dateArray[1]}/${dateArray[0]}/${dateArray[2]}`
          ),
        });
      } else {
      }
    }
  }, [brnDetail]);

  const tinOptions = [
    {
      id: "Y",
      name: "Yes",
    },
    {
      id: "N",
      name: "No",
    },
  ];

  const countries = [
    { name: "Åland Islands", code: "AX" },
    { name: "Afghanistan", code: "AF" },
    { name: "Albania", code: "AL" },
    { name: "Algeria", code: "DZ" },
    { name: "American Samoa", code: "AS" },
    { name: "Andorra", code: "AD" },
    { name: "Angola", code: "AO" },
    { name: "Anguilla", code: "AI" },
    { name: "Antarctica", code: "AQ" },
    { name: "Antigua and Barbuda", code: "AG" },
    { name: "Argentina", code: "AR" },
    { name: "Armenia", code: "AM" },
    { name: "Aruba", code: "AW" },
    { name: "Australia", code: "AU" },
    { name: "Austria", code: "AT" },
    { name: "Azerbaijan", code: "AZ" },
    { name: "Bahamas (the)", code: "BS" },
    { name: "Bahrain", code: "BH" },
    { name: "Bangladesh", code: "BD" },
    { name: "Barbados", code: "BB" },
    { name: "Belarus", code: "BY" },
    { name: "Belgium", code: "BE" },
    { name: "Belize", code: "BZ" },
    { name: "Benin", code: "BJ" },
    { name: "Bermuda", code: "BM" },
    { name: "Bhutan", code: "BT" },
    { name: "Bolivia (Plurinational State of)", code: "BO" },
    { name: "Bonaire, Sint Eustatius and Saba", code: "BQ" },
    { name: "Bosnia and Herzegovina", code: "BA" },
    { name: "Botswana", code: "BW" },
    { name: "Bouvet Island", code: "BV" },
    { name: "Brazil", code: "BR" },
    { name: "British Indian Ocean Territory (the)", code: "IO" },
    { name: "Brunei Darussalam", code: "BN" },
    { name: "Bulgaria", code: "BG" },
    { name: "Burkina Faso", code: "BF" },
    { name: "Burundi", code: "BI" },
    { name: "Cabo Verde", code: "CV" },
    { name: "Cambodia", code: "KH" },
    { name: "Cameroon", code: "CM" },
    { name: "Canada", code: "CA" },
    { name: "Cayman Islands (the)", code: "KY" },
    { name: "Central African Republic (the)", code: "CF" },
    { name: "Chad", code: "TD" },
    { name: "Chile", code: "CL" },
    { name: "China", code: "CN" },
    { name: "Christmas Island", code: "CX" },
    { name: "Cocos (Keeling) Islands (the)", code: "CC" },
    { name: "Colombia", code: "CO" },
    { name: "Comoros (the)", code: "KM" },
    { name: "Congo (the Democratic Republic of the)", code: "CD" },
    { name: "Congo (the)", code: "CG" },
    { name: "Cook Islands (the)", code: "CK" },
    { name: "Costa Rica", code: "CR" },
    { name: "Croatia", code: "HR" },
    { name: "Cuba", code: "CU" },
    { name: "Curaçao", code: "CW" },
    { name: "Cyprus", code: "CY" },
    { name: "Czechia", code: "CZ" },
    { name: "Côte d'Ivoire", code: "CI" },
    { name: "Denmark", code: "DK" },
    { name: "Djibouti", code: "DJ" },
    { name: "Dominica", code: "DM" },
    { name: "Dominican Republic (the)", code: "DO" },
    { name: "Ecuador", code: "EC" },
    { name: "Egypt", code: "EG" },
    { name: "El Salvador", code: "SV" },
    { name: "Equatorial Guinea", code: "GQ" },
    { name: "Eritrea", code: "ER" },
    { name: "Estonia", code: "EE" },
    { name: "Eswatini", code: "SZ" },
    { name: "Ethiopia", code: "ET" },
    { name: "Falkland Islands (the) [Malvinas]", code: "FK" },
    { name: "Faroe Islands (the)", code: "FO" },
    { name: "Fiji", code: "FJ" },
    { name: "Finland", code: "FI" },
    { name: "France", code: "FR" },
    { name: "French Guiana", code: "GF" },
    { name: "French Polynesia", code: "PF" },
    { name: "French Southern Territories (the)", code: "TF" },
    { name: "Gabon", code: "GA" },
    { name: "Gambia (the)", code: "GM" },
    { name: "Georgia", code: "GE" },
    { name: "Germany", code: "DE" },
    { name: "Ghana", code: "GH" },
    { name: "Gibraltar", code: "GI" },
    { name: "Greece", code: "GR" },
    { name: "Greenland", code: "GL" },
    { name: "Grenada", code: "GD" },
    { name: "Guadeloupe", code: "GP" },
    { name: "Guam", code: "GU" },
    { name: "Guatemala", code: "GT" },
    { name: "Guernsey", code: "GG" },
    { name: "Guinea", code: "GN" },
    { name: "Guinea-Bissau", code: "GW" },
    { name: "Guyana", code: "GY" },
    { name: "Haiti", code: "HT" },
    { name: "Heard Island and McDonald Islands", code: "HM" },
    { name: "Holy See (the)", code: "VA" },
    { name: "Honduras", code: "HN" },
    { name: "Hong Kong", code: "HK" },
    { name: "Hungary", code: "HU" },
    { name: "Iceland", code: "IS" },
    { name: "India", code: "IN" },
    { name: "Indonesia", code: "ID" },
    { name: "Iran (Islamic Republic of)", code: "IR" },
    { name: "Iraq", code: "IQ" },
    { name: "Ireland", code: "IE" },
    { name: "Isle of Man", code: "IM" },
    { name: "Israel", code: "IL" },
    { name: "Italy", code: "IT" },
    { name: "Jamaica", code: "JM" },
    { name: "Japan", code: "JP" },
    { name: "Jersey", code: "JE" },
    { name: "Jordan", code: "JO" },
    { name: "Kazakhstan", code: "KZ" },
    { name: "Kenya", code: "KE" },
    { name: "Kiribati", code: "KI" },
    { name: "Korea (the Democratic People's Republic of)", code: "KP" },
    { name: "Korea (the Republic of)", code: "KR" },
    { name: "Kuwait", code: "KW" },
    { name: "Kyrgyzstan", code: "KG" },
    { name: "Lao People's Democratic Republic (the)", code: "LA" },
    { name: "Latvia", code: "LV" },
    { name: "Lebanon", code: "LB" },
    { name: "Lesotho", code: "LS" },
    { name: "Liberia", code: "LR" },
    { name: "Libya", code: "LY" },
    { name: "Liechtenstein", code: "LI" },
    { name: "Lithuania", code: "LT" },
    { name: "Luxembourg", code: "LU" },
    { name: "Macao", code: "MO" },
    { name: "Madagascar", code: "MG" },
    { name: "Malawi", code: "MW" },
    { name: "Malaysia", code: "MY" },
    { name: "Maldives", code: "MV" },
    { name: "Mali", code: "ML" },
    { name: "Malta", code: "MT" },
    { name: "Marshall Islands (the)", code: "MH" },
    { name: "Martinique", code: "MQ" },
    { name: "Mauritania", code: "MR" },
    { name: "Mauritius", code: "MU" },
    { name: "Mayotte", code: "YT" },
    { name: "Mexico", code: "MX" },
    { name: "Micronesia (Federated States of)", code: "FM" },
    { name: "Moldova (the Republic of)", code: "MD" },
    { name: "Monaco", code: "MC" },
    { name: "Mongolia", code: "MN" },
    { name: "Montenegro", code: "ME" },
    { name: "Montserrat", code: "MS" },
    { name: "Morocco", code: "MA" },
    { name: "Mozambique", code: "MZ" },
    { name: "Myanmar", code: "MM" },
    { name: "Namibia", code: "NA" },
    { name: "Nauru", code: "NR" },
    { name: "Nepal", code: "NP" },
    { name: "Netherlands (the)", code: "NL" },
    { name: "New Caledonia", code: "NC" },
    { name: "New Zealand", code: "NZ" },
    { name: "Nicaragua", code: "NI" },
    { name: "Niger (the)", code: "NE" },
    { name: "Nigeria", code: "NG" },
    { name: "Niue", code: "NU" },
    { name: "Norfolk Island", code: "NF" },
    { name: "Northern Mariana Islands (the)", code: "MP" },
    { name: "Norway", code: "NO" },
    { name: "Oman", code: "OM" },
    { name: "Pakistan", code: "PK" },
    { name: "Palau", code: "PW" },
    { name: "Palestine, State of", code: "PS" },
    { name: "Panama", code: "PA" },
    { name: "Papua New Guinea", code: "PG" },
    { name: "Paraguay", code: "PY" },
    { name: "Peru", code: "PE" },
    { name: "Philippines (the)", code: "PH" },
    { name: "Pitcairn", code: "PN" },
    { name: "Poland", code: "PL" },
    { name: "Portugal", code: "PT" },
    { name: "Puerto Rico", code: "PR" },
    { name: "Qatar", code: "QA" },
    { name: "Republic of North Macedonia", code: "MK" },
    { name: "Romania", code: "RO" },
    { name: "Russian Federation (the)", code: "RU" },
    { name: "Rwanda", code: "RW" },
    { name: "Réunion", code: "RE" },
    { name: "Saint Barthélemy", code: "BL" },
    { name: "Saint Helena, Ascension and Tristan da Cunha", code: "SH" },
    { name: "Saint Kitts and Nevis", code: "KN" },
    { name: "Saint Lucia", code: "LC" },
    { name: "Saint Martin (French part)", code: "MF" },
    { name: "Saint Pierre and Miquelon", code: "PM" },
    { name: "Saint Vincent and the Grenadines", code: "VC" },
    { name: "Samoa", code: "WS" },
    { name: "San Marino", code: "SM" },
    { name: "Sao Tome and Principe", code: "ST" },
    { name: "Saudi Arabia", code: "SA" },
    { name: "Senegal", code: "SN" },
    { name: "Serbia", code: "RS" },
    { name: "Seychelles", code: "SC" },
    { name: "Sierra Leone", code: "SL" },
    { name: "Singapore", code: "SG" },
    { name: "Sint Maarten (Dutch part)", code: "SX" },
    { name: "Slovakia", code: "SK" },
    { name: "Slovenia", code: "SI" },
    { name: "Solomon Islands", code: "SB" },
    { name: "Somalia", code: "SO" },
    { name: "South Africa", code: "ZA" },
    { name: "South Georgia and the South Sandwich Islands", code: "GS" },
    { name: "South Sudan", code: "SS" },
    { name: "Spain", code: "ES" },
    { name: "Sri Lanka", code: "LK" },
    { name: "Sudan (the)", code: "SD" },
    { name: "Suriname", code: "SR" },
    { name: "Svalbard and Jan Mayen", code: "SJ" },
    { name: "Sweden", code: "SE" },
    { name: "Switzerland", code: "CH" },
    { name: "Syrian Arab Republic", code: "SY" },
    { name: "Taiwan (Province of China)", code: "TW" },
    { name: "Tajikistan", code: "TJ" },
    { name: "Tanzania, United Republic of", code: "TZ" },
    { name: "Thailand", code: "TH" },
    { name: "Timor-Leste", code: "TL" },
    { name: "Togo", code: "TG" },
    { name: "Tokelau", code: "TK" },
    { name: "Tonga", code: "TO" },
    { name: "Trinidad and Tobago", code: "TT" },
    { name: "Tunisia", code: "TN" },
    { name: "Turkey", code: "TR" },
    { name: "Turkmenistan", code: "TM" },
    { name: "Turks and Caicos Islands (the)", code: "TC" },
    { name: "Tuvalu", code: "TV" },
    { name: "Uganda", code: "UG" },
    { name: "Ukraine", code: "UA" },
    { name: "United Arab Emirates (the)", code: "AE" },
    {
      name: "United Kingdom of Great Britain and Northern Ireland (the)",
      code: "GB",
    },
    { name: "United States Minor Outlying Islands (the)", code: "UM" },
    { name: "United States of America (the)", code: "US" },
    { name: "Uruguay", code: "UY" },
    { name: "Uzbekistan", code: "UZ" },
    { name: "Vanuatu", code: "VU" },
    { name: "Venezuela (Bolivarian Republic of)", code: "VE" },
    { name: "Viet Nam", code: "VN" },
    { name: "Virgin Islands (British)", code: "VG" },
    { name: "Virgin Islands (U.S.)", code: "VI" },
    { name: "Wallis and Futuna", code: "WF" },
    { name: "Western Sahara", code: "EH" },
    { name: "Yemen", code: "YE" },
    { name: "Zambia", code: "ZM" },
    { name: "Zimbabwe", code: "ZW" },
  ];

  const onSubmit = (e) => {
    e.preventDefault();
    submitForm({
      ...form,
      pwd_expiry_status: "active",
      first_name: form.first_name,
      last_name: form.last_name,
    });
  };

  useEffect(() => {
    if (id)
      updateFormValues({
        is_superuser: false,
      });
  }, []);

  return (
    <form onSubmit={onSubmit} id={formId}>
      {error ? (
        error.non_field_errors ? (
          <Error message={error.non_field_errors} />
        ) : (
          <Error message="Failed to create account" />
        )
      ) : null}
      <div className="panel panel-primary">
        <div className="panel-heading">
          <div className="panel-title">SECTION A: Master Data</div>
        </div>

        <div className="row">
          <div className="col-sm-6">
            <SelectInput
              options={(entitytypes?.results || []).map((sup) => ({
                label: sup.name,
                value: sup.entity_id,
              }))}
              id="entity_type"
              name="entity_type"
              label="Entity Type..."
              onChange={({ value, label }) =>
                updateFormValues({
                  entity_type: value,
                  entity_type_name: label,
                })
              }
              value={form?.entity_type || ""}
              error={error}
              required
              labelInValue
            />
          </div>
          <div className="col-sm-6">
            <SelectInput
              options={(entitysutypes?.results || []).map((sup) => ({
                placeholder: "Select Entity Sub Types...",
                label: sup.name,
                value: sup.id,
              }))}
              onChange={({ value, label }) =>
                updateFormValues({
                  entity_sub_type: value,
                  entity_sub_type: label,
                })
              }
              name="entity_sub_type"
              value={form.entity_sub_type || ""}
              label="Entity Sub Type"
              required
              labelInValue
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <FormInput
              label="Business Registration Number"
              type="text"
              name="brn"
              onChange={onChange}
              value={form.brn}
              error={error}
              maxLength={14}
              required
            />
          </div>

          <div className="col-sm-6">
            <FormInput
              label="Registered Name"
              type="text"
              name="registered_name"
              onChange={onChange}
              value={form.registered_name}
              error={error}
              required
              readOnly
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6">
            <FormInput
              label="Legal Name"
              type="text"
              name="legal_name"
              onChange={onChange}
              value={form.legal_name}
              error={error}
              required
              readOnly
            />
          </div>
          <div className="col-sm-6">
            <DateInput
              label="Entity Establishment Date"
              id="entity_establishment_date"
              name="entity_establishment_date"
              onChange={(value) =>
                updateFormValue("entity_establishment_date", value)
              }
              value={form.entity_establishment_date}
              error={error}
              format="DD/MM/YYYY"
              required
              disabled
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <TextArearInput
              label="Business Activity Description"
              type="text"
              name="description"
              onChange={onChange}
              value={form.description}
              error={error}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <SelectInput
              label="Is Resident Tax Payer"
              options={tinOptions.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              onChange={(value) =>
                updateFormValue("is_resident_taxpayer", value)
              }
              name="is_resident_taxpayer"
              value={form.is_resident_taxpayer || ""}
              error={error}
            />
          </div>
          <div className="col-sm-6">
            <SelectInput
              label="Country Of Registration"
              name="country"
              onChange={(value) => updateFormValue("country", value)}
              options={countries.map((item) => ({
                value: item.name,
                label: item.name,
              }))}
              value={form.country}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6">
            <SelectInput
              label="Telephone Country Code"
              name="country_code"
              onChange={(value) => updateFormValue("country_code", value)}
              options={countries.map((item) => ({
                value: item.code,
                label: item.code,
              }))}
              value={form.country_code}
            />
          </div>
          <div className="col-sm-6">
            <FormInput
              label="Telephone"
              type="text"
              name="telephone"
              onChange={onChange}
              value={form.telephone}
              maxLength={10}
              error={error}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <FormInput
              label="Email Address"
              type="temailext"
              name="email"
              onChange={onChange}
              value={form.email}
              error={error}
              required
            />
          </div>
        </div>
      </div>
      <div className="panel panel-primary">
        <div className="panel-heading">
          <div className="panel-title">SECTION B: Business Classification</div>
        </div>

        <div className="row">
          <div className="col-sm-6">
            <SelectInput
              options={mainactivities?.map((sup) => ({
                placeholder: "Select Main Activity...",
                label: sup.name,
                value: sup.id,
              }))}
              onChange={({ value, label }) =>
                updateFormValues({
                  main_activity: value,
                  main_activity_name: label,
                })
              }
              name="main_activity"
              value={form.main_activity || ""}
              label="Main Activity"
              required
              labelInValue
            />
          </div>
          <div className="col-sm-6">
            <SelectInput
              options={mainactivitiesdivision?.map((sup) => ({
                placeholder: "Select Main Activity Division...",
                label: sup.name,
                value: sup.id,
              }))}
              onChange={({ value, label }) =>
                updateFormValues({
                  main_activity_division: value,
                  main_activity_division_name: label,
                })
              }
              name="main_activity_division"
              value={form.main_activity_division || ""}
              label="Main Activity Division"
              required
              labelInValue
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <SelectInput
              options={mainactivitiesclass?.map((sup) => ({
                placeholder: "Select Main Activity Class...",
                label: sup.name,
                value: sup.id,
              }))}
              onChange={({ value, label }) =>
                updateFormValues({
                  activity_class: value,
                  activity_class: label,
                })
              }
              name="activity_class"
              value={form.activity_class || ""}
              label="Main Activity Class"
              required
              labelInValue
            />
          </div>
          <div className="col-sm-6">
            <SelectInput
              label="Is Source Of Income Rental?"
              options={tinOptions.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              onChange={(value) =>
                updateFormValue("incource_source_rent", value)
              }
              name="incource_source_rent"
              value={form.incource_source_rent || ""}
              error={error}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <SelectInput
              label="Is Source Of Income Property?"
              options={tinOptions.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              onChange={(value) =>
                updateFormValue("incource_source_property", value)
              }
              name="incource_source_property"
              value={form.incource_source_property || ""}
              error={error}
            />
          </div>

          <div className="col-sm-6">
            <SelectInput
              label="Is Source Of Income Business?"
              options={tinOptions.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              onChange={(value) =>
                updateFormValue("incource_source_business", value)
              }
              name="incource_source_business"
              value={form.incource_source_business || ""}
              error={error}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <SelectInput
              label="Has Stamp Duty?"
              options={tinOptions.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              onChange={(value) => updateFormValue("stamp_duty", value)}
              name="stamp_duty"
              value={form.stamp_duty || ""}
              error={error}
            />
          </div>
          <div className="col-sm-6">
            <SelectInput
              label="Has Imports And Exports?"
              options={tinOptions.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              onChange={(value) => updateFormValue("has_import", value)}
              name="has_import"
              value={form.has_import || ""}
              error={error}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <SelectInput
              label="Has Exemption Tax?"
              options={tinOptions.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              onChange={(value) => updateFormValue("has_exemption", value)}
              name="has_exemption"
              value={form.has_exemption || ""}
              error={error}
            />
          </div>
          <div className="col-sm-6">
            <SelectInput
              label="Has Gaming Tax?"
              options={tinOptions.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              onChange={(value) => updateFormValue("has_gaming_tax", value)}
              name="has_gaming_tax"
              value={form.has_gaming_tax || ""}
              error={error}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6">
            <SelectInput
              label="Has Income Tax?"
              options={tinOptions.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              onChange={(value) => updateFormValue("has_income_tax", value)}
              name="has_income_tax"
              value={form.has_income_tax || ""}
              error={error}
            />
          </div>
          <div className="col-sm-6">
            <SelectInput
              label="Has Local Excise Duty"
              options={tinOptions.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              onChange={(value) => updateFormValue("has_excise_duty", value)}
              name="has_excise_duty"
              value={form.has_excise_duty || ""}
              error={error}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <SelectInput
              label="Has Value Added Tax?"
              options={tinOptions.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              onChange={(value) =>
                updateFormValue("has_value_added_tax", value)
              }
              name="has_value_added_tax"
              value={form.has_value_added_tax || ""}
              error={error}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <SelectInput
              label="Has Motor Vehicle?"
              options={tinOptions.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              onChange={(value) => updateFormValue("motor_vehicle", value)}
              name="motor_vehicle"
              value={form.motor_vehicle || ""}
              error={error}
            />
          </div>
        </div>
      </div>

      <div className="panel panel-primary">
        <div className="panel-heading">
          <div className="panel-title">SECTION C: Business Contact Details</div>
        </div>

        <div class="row">
          <div className="col-lg-6 col-sm-12">
            <SelectInput
              options={districts.map((sup) => ({
                label: sup.name,
                value: sup.id,
              }))}
              id="district"
              name="district"
              label="Home District"
              onChange={(value) => updateFormValue("district", value)}
              value={form?.district || ""}
              error={error}
              required
            />
          </div>
          <div className="col-lg-6 col-sm-12">
            <SelectInput
              options={counties.map((sup) => ({
                label: sup.name,
                value: sup.id,
              }))}
              id="county"
              name="county"
              label="Home County"
              onChange={(value) => updateFormValue("county", value)}
              value={form?.county || ""}
              maxMenuHeight={250}
              error={error}
              required
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6">
            <SelectInput
              options={subcounties.map((sup) => ({
                label: sup.name,
                value: sup.id,
              }))}
              id="sub_county"
              name="sub_county"
              label="Home Sub County"
              onChange={(value) => updateFormValue("sub_county", value)}
              value={form?.sub_county || ""}
              maxMenuHeight={250}
              error={error}
              required
            />
          </div>
          <div className="col-sm-6">
            <SelectInput
              options={parishes.map((sup) => ({
                label: sup.name,
                value: sup.id,
              }))}
              id="parish"
              name="parish"
              label="Home Parish"
              onChange={(value) => updateFormValue("parish", value)}
              value={form?.parish || ""}
              maxMenuHeight={250}
              error={error}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <SelectInput
              options={villages.map((sup) => ({
                label: sup.name,
                value: sup.id,
              }))}
              id="village"
              name="village"
              label="Home Village"
              onChange={(value) => updateFormValue("village", value)}
              value={form?.village || ""}
              maxMenuHeight={250}
              error={error}
              required
            />
          </div>
        </div>
      </div>
      <div className="panel panel-primary">
        <div className="panel-heading">
          <div className="panel-title">
            SECTION D: Authorized Contact Details
          </div>
          <p className="regular font13">
            (The applicant must provide at least one director and one other
            authorized contact person)
          </p>
        </div>
        <div style={{ overflow: "auto" }}>
          <InstantTinTable form={form} updateFormValue={updateFormValue} />
        </div>
      </div>
      <div className="panel panel-primary">
        <div className="panel-heading">
          <div className="panel-title">SECTION E: SUPPORTING DOCUMENTS</div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <FileInput
              label="Attachments"
              onChange={(value) => updateFormValue("attachement", value)}
              name="attachement"
              documentType={DOC_TYPE_REPORT}
              multiple
              required
            />
          </div>
        </div>
      </div>
      <div className="submit">
        <div className="form-group">
          <SubmitButton submitting={submitting} isEdit={id} />
        </div>
      </div>
    </form>
  );
};

NonIndividualInstantTinForm.propTypes = {
  form: PropTypes.shape({
    type: PropTypes.string,
  }),
  submitting: PropTypes.bool,
  onChange: PropTypes.func,
  updateFormValue: PropTypes.func,
  submitForm: PropTypes.func,
  error: PropTypes.shape({
    non_field_errors: PropTypes.string,
  }),
};

export default NonIndividualInstantTinForm;
