import React, { useEffect } from "react";
import { FaBan, FaEdit, FaEye, FaGem, FaMoneyBill } from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  COMMERCIAL_ROAD_USER,
  STATUS_APPROVED,
  STATUS_ASSESSED,
  STATUS_DEACTIVATED,
  STATUS_DEFFERED,
  STATUS_PENDING,
  STATUS_REJECTED,
  STATUS_SUBMITTED,
} from "../../constants/webapp";
import {
  downloadThroughAnchorLink,
  returnObject,
} from "../../utils/helperUtil";
import { configColor } from "../../components/styled/Theme";
import { Visibility } from "../../components/common/Visibility";
import { ObjectionsButton } from "../trading-license/useTradingLicense";
import { Tooltip } from "antd";
import { DownloadLink } from "../../components/common/Button";
import {
  INVOICE_API,
  PARKS_API,
  ROUTES_API,
  STAGES_API,
  VEHICLE_CLASSIFICATION_API,
} from "../../constants/api";
import useLocationData from "../common/useLocationData";
import useGenericGet from "../common/useGenericGet";

const useUserParkFees = () => {
  const parkTableData = (status, data) => [
    ...data.map((item) => {
      const row = {
        application_number: item?.application_number,
        branch_code: item?.branch_code,
        owner_name: item?.owner_name,
        email: item?.email,
        mobile_number: item?.mobile_number,
        number_plate: item?.number_plate,
        owner_surname: item?.owner_surname,
        owner_firstname: item?.owner_firstname,
        make_year: item?.make_year,
        model: item?.model,
        color: item?.color,
        engine_number: item?.engine_number,
        chasis_number: item?.chasis_number,
        log_book_date: item?.log_book_date,
        tin: item?.tin,
        municipality_name: item?.municipality_name,
        division_name: item?.division_name,
        parish_name: item?.parish_name,
        village_name: item?.village_name,
        street_name: item?.street_name,
        vehicle_type: item?.vehicle_type_name,
        park: item?.park_name,
        stage: item?.stage_name,
        route_name: item?.route_name,
        route_number: item?.route_number,
        status: item?.status_details,
        route: (item?.route_category).replaceAll("_", " ").toUpperCase(),
        created_by: item?.created_by_name,
        assessments: (
          <Link
            to={`/assessments?tax_id=${item.id}&tax=commercial_vehicle`}
            style={{ color: configColor }}
          >
            Assessment
          </Link>
        ),
        certificate: (
          <DownloadLink
            onClick={() =>
              downloadThroughAnchorLink(
                `${INVOICE_API}print-registration-certificate/?id=${item?.id}&table_name=commercial_vehicle`,
                "certificate"
              )
            }
            title="[Registration Certificate]"
          />
        ),
        actions: (
          <div style={{ whiteSpace: "nowrap" }}>
            <div style={{ whiteSpace: "nowrap" }}>
              <Link
                class="mr-2"
                style={{ padding: 5, paddingBottom: 0 }}
                to={`${COMMERCIAL_ROAD_USER}/view/${item.id}`}
                state={{ tax: "commercial_vehicle" }}
              >
                <FaEye />
              </Link>
              <Visibility show={item?.status_details === "DEFERRED"}>
                <Link
                  style={{ padding: 5, paddingBottom: 0 }}
                  class=" mr-2"
                  to={`${COMMERCIAL_ROAD_USER}/update/${item.id}`}
                >
                  <FaEdit />
                </Link>
              </Visibility>
              <Visibility
                show={["APPROVED", "PAID"].includes(item?.status_details)}
              >
                <Tooltip title="Amendment">
                  <Link
                    style={{ padding: 5, paddingBottom: 0 }}
                    class=" mr-2"
                    to={`/amendment/commercial_vehicle/${item.id}`}
                  >
                    <FaGem />
                  </Link>
                </Tooltip>

                <Tooltip title="Reassessment">
                  <Link
                    style={{ padding: 5, paddingBottom: 0 }}
                    class=" mr-2"
                    to={`/reassessment/commercial_vehicle/${item.id}`}
                  >
                    <FaMoneyBill />
                  </Link>
                </Tooltip>

                <ObjectionsButton
                  branch_code={item?.branch_code}
                  revenue_source="park_user_fees"
                />
                <Visibility show={item?.outstanding_balance == 0}>
                  <Tooltip title="Deactivate">
                    <Link
                      style={{ padding: 5, paddingBottom: 0 }}
                      class=" mr-2"
                      to={`${COMMERCIAL_ROAD_USER}/business_deactivation/${item.id}`}
                    >
                      <FaBan />
                    </Link>
                  </Tooltip>
                </Visibility>
              </Visibility>
            </div>
          </div>
        ),
      };
      return row;
    }),
  ];

  const viewData = (item, isFormData) => {
    return {
      ...returnObject(!isFormData, {
        "Reference Number": item?.application_number,
        "Branch Code": item?.branch_code,
      }),
      "Tax Payer Name": item?.owner_name,
      Email: item?.email,
      "Mobile Number": item?.mobile_number,
      "Number Plate": item?.number_plate,
      Surname: item?.owner_surname,
      "First Name": item?.owner_firstname,
      "Make Year": item?.make_year,
      Model: item?.model,
      Color: item?.color,
      "Engine number": item?.engine_number,
      "Chasis Number": item?.chasis_number,
      "Log Book Date": item?.log_book_date,
      TIN: item?.tin,
      "Municipality/City/District": item?.municipality_name,
      "Division/Town Council/Sub County": item?.division_name,
      "Parish/Ward": item?.parish_name,
      "Village/Cell/Zone": item?.village_name,
      Street: item?.street_name,
      "Vehicle Type": item?.vehicle_type_name,
      Park: item?.park_name,
      Stage: item?.stage_name,
      route_name: item?.route_name,
      route_number: item?.route_number,
      route: item?.route_category?.replaceAll("_", " ").toUpperCase(),
      ...returnObject(!isFormData, {
        amount: Number(item?.amount)?.toLocaleString(),
      }),
      ...returnObject(!isFormData, {
        outstanding_balance: Number(
          item?.outstanding_balance
        )?.toLocaleString(),
        Status: item?.status_name,
      }),
      created_by: item?.created_by_name,
    };
  };

  const parkTableColumns = (status) => [
    { id: "application_number", header: "Reference Number ", filter: {} },
    ...returnObject(
      status === STATUS_ASSESSED || status === STATUS_DEACTIVATED,
      [{ id: "branch_code", header: "Branch Code", filter: {} }]
    ),
    { id: "owner_name", header: "Tax Payer Name", filter: {} },
    { id: "mobile_number", header: "Mobile Number ", filter: {} },
    { id: "number_plate", header: "Number Plate ", filter: {} },
    { id: "owner_surname", header: "Vehicle Owner Name", filter: {} },
    { id: "make_year", header: "Make Year ", filter: {} },
    { id: "model", header: "Model", filter: {} },
    { id: "color", header: "Color", filter: {} },
    { id: "engine_number", header: "Engine Number", filter: {} },
    { id: "chasis_number", header: "Chasis Number", filter: {} },
    { id: "log_book_date", header: "Log Book Date", filter: {} },
    { id: "tin", header: "TIN", filter: {} },
    { id: "municipality_name", header: "Municipality ", filter: {} },
    { id: "division_name", header: "Division", filter: {} },
    { id: "parish_name", header: "Parish ", filter: {} },
    { id: "village_name", header: "Village", filter: {} },
    { id: "street_name", header: "Street ", filter: {} },
    {
      id: "vehicle_type",
      header: "Vehicle Type",
      filter: {},
    },
    { id: "park", header: "Park", filter: {} },
    { id: "stage", header: "Stage", filter: {} },
    { id: "route_name", header: "Route", filter: {} },
    { id: "route_number", header: "Route Number ", filter: {} },
    { id: "route", header: "Route Category", filter: {} },
    { id: "created_by", header: "Created By", filter: {} },
    // { id: "status", header: "Status", filter: {} },
    ...returnObject(status === STATUS_ASSESSED, [
      { id: "assessments", header: "Invoices" },

      { id: "certificate", header: "Registration Certificate" },
    ]),
    { id: "actions", header: "Actions" },
  ];

  const getRedirect = (status) => {
    let redirect = "";
    switch (status.toLowerCase()) {
      case STATUS_SUBMITTED:
        redirect = `${COMMERCIAL_ROAD_USER}/${STATUS_APPROVED}`;
        break;

      case STATUS_APPROVED:
        redirect = `${COMMERCIAL_ROAD_USER}/${STATUS_APPROVED}`;
        break;

      case STATUS_REJECTED:
        redirect = `${COMMERCIAL_ROAD_USER}/${STATUS_REJECTED}`;
        break;

      case STATUS_DEFFERED:
        redirect = `${COMMERCIAL_ROAD_USER}/${STATUS_DEFFERED}`;
        break;

      default:
        break;
    }

    return redirect;
  };

  const getActions = (status, approvedID, deferredID, rejectedID) => {
    status = status?.toLowerCase();

    let options = [];
    switch (status) {
      case STATUS_PENDING?.toLowerCase():
        options = [
          { value: approvedID, label: "Approve" },
          { value: rejectedID, label: "Reject" },
          { value: deferredID, label: "Deferred" },
        ];
        break;

      default:
        options = [];
    }

    return options;
  };

  const reassessFields = [
    {
      id: "amount",
      field: "amount",
      type: "number",
    },
  ];

  const amendableFields = [
    { id: "Tax Payer Name", field: "owner_name", type: "text" },
    { id: "Email", field: "email", type: "text" },
    { id: "Mobile Number", field: "mobile_number", type: "text" },
    { id: "Number Plate", field: "number_plate", type: "text" },
    { id: "Surname", field: "owner_surname", type: "text" },
    { id: "First Name", field: "owner_firstname", type: "text" },
    {
      id: "Make Year",
      field: "make_year",
      type: "text",
      props: { readOnly: true },
    },
    {
      id: "Model",
      field: "model",
      type: "text",
      props: { readOnly: true },
    },
    {
      id: "Color",
      field: "color",
      type: "text",
      props: { readOnly: true },
    },
    {
      id: "Engine number",
      field: "engine_number",
      type: "text",
      props: { readOnly: true },
    },
    {
      id: "Chasis Number",
      field: "chasis_number",
      type: "text",
      props: { readOnly: true },
    },
    {
      id: "Log Book Date",
      field: "log_book_date",
      type: "text",
      props: { type: "date" },
    },
    {
      id: "Municipality/City/District",
      field: "municipality_name",
      type: "select",
      options: "districts",
    },
    {
      id: "Division/Town Council/Sub County",
      field: "division_name",
      type: "select",
      options: "divisions",
    },
    {
      id: "Parish/Ward",
      field: "parish_name",
      type: "select",
      options: "parishes",
    },
    {
      id: "Village/Cell/Zone",
      field: "village_name",
      type: "select",
      options: "villages",
    },
    {
      id: "Street",
      field: "street_name",
      type: "select",
      options: "streets",
    },
    {
      id: "Vehicle Type",
      field: "vehicle_type",
      type: "select",
      options: "vehicleTypes",
    },
    {
      id: "Park",
      field: "park_name",
      type: "select",
      options: "parks",
    },
    {
      id: "Stage",
      field: "stage_name",
      type: "select",
      options: "stages",
    },
    {
      id: "amount",
      field: "amount",
      type: "text",
    },
  ];

  return {
    parkTableColumns,
    parkTableData,
    amendableFields,
    viewData,
    reassessFields,
    getRedirect,
    getActions,
  };
};

export const useParkUserFeesFormSelect = (form, updateFormValue) => {
  const {
    districts,
    getDistricts,
    divisions,
    getDivisions,
    parishes,
    getParishes,
    villages,
    getVillages,
    streets,
    getStreets,
  } = useLocationData();

  const { data: vehicleTypes, loadData: getVehicleTypes } = useGenericGet();
  const { data: parks, loadData: getParks } = useGenericGet();
  const { data: stages, loadData: getStages } = useGenericGet();
  const { data: routes, loadData: getRoute } = useGenericGet();

  useEffect(() => {
    getDistricts();
  }, []);

  useEffect(() => {
    if (form?.municipality_name) getDivisions(form?.municipality_name);
  }, [form?.municipality_name]);

  useEffect(() => {
    if (form?.division_name) getParishes(form?.division_name);
  }, [form?.division_name]);

  useEffect(() => {
    if (form?.parish_name) getVillages(form?.parish_name);
  }, [form?.parish_name]);

  useEffect(() => {
    if (form?.village_name) getStreets(form?.village_name);
  }, [form?.village_name]);

  useEffect(() => {
    getVehicleTypes({ api: VEHICLE_CLASSIFICATION_API });
  }, []);

  useEffect(() => {
    if (form.municipality_name) {
      getParks({
        api: PARKS_API,
        params: { municipality: form?.municipality_name },
      });
    }
  }, [form?.municipality_name]);

  useEffect(() => {
    if (form.park_name) {
      getStages({
        api: STAGES_API,
        params: { park: form.park_name },
      });
    }
  }, [form.park_name]);

  useEffect(() => {
    if (form?.stage_name) {
      getRoute({
        api: ROUTES_API,
        params: { id: form?.stage_name },
      });
    }
  }, [form?.stage_name]);

  useEffect(() => {
    if (form?.route_name) {
      updateFormValue("route_number", form?.route_number);
    }
  }, [form?.route_name]);

  return {
    districts,
    divisions,
    parishes,
    villages,
    streets,
    vehicleTypes: vehicleTypes?.results || [],
    parks: parks?.results || [],
    stages: stages?.results || [],
  };
};

export default useUserParkFees;
