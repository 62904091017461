import { useState } from "react";
import axiosInstance from "../../utils/axios";
import { MAIN_ACTIVITIES_API, MAIN_ACTIVITIES_CLASS_API, MAIN_ACTIVITIES_DIVISION_API } from "../../constants/api";

function useEnityTypeData() {
  const [mainactivities, setMainActivities] = useState([]);
  const [mainactivitiesdivision, setMainActivitiesDivision] = useState([]);
  const [mainactivitiesclass, setMainActivitiesClass] = useState([]);
  const [loading, setLoading] = useState(false);

  const dataCall = (api, fallback, id, params) => {
    const API = id ? `${api}/${id}/` : api;
    setLoading(true);
    axiosInstance
      .get(API, { params })
      .then(({ data }) => {
        // handle success
        fallback(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        // handle error
      });
  };

  const getMainActivities = () => {
    dataCall(MAIN_ACTIVITIES_API, setMainActivities, null);
  };

  const getMainActivitiesDivision = (parent) => {
    dataCall(MAIN_ACTIVITIES_DIVISION_API, setMainActivitiesDivision, null, { parent: parent });
  };

  const getMainActivitiesClass = (parent) => {
    dataCall(MAIN_ACTIVITIES_CLASS_API, setMainActivitiesClass, null, { parent: parent });
  };


  const activityViewData = (item, isFormData) => ({
    main_activity: isFormData ? item.main_activity_name : item?.main_activity_details?.name,
    main_activity_division: isFormData ? item.main_activity_division_name : item?.main_activity_division_details?.name,
    activity_class: isFormData ? item.activity_class_name : item?.activity_class_details?.name,
  });

  return {
    mainactivities,
    getMainActivities,
    mainactivitiesdivision,
    getMainActivitiesDivision,
    mainactivitiesclass,
    getMainActivitiesClass,
    activityViewData,
    loading
  };
}

export default useEnityTypeData;
