import React from "react";
import PropTypes from "prop-types";
import Error from "./Error";

const FormInput = (props) => {
  const {
    label,
    type = "text",
    name,
    value,
    error,
    defaultValue,
    onChange,
    ...rest
  } = props;

  return (
    <div className="form-group field-user-cin">
      <label>
        {label} {rest.required && <span className="text-danger">*</span>}
      </label>
      <input
        className="form-control"
        type={type}
        name={name}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        // eslint-disable-next-line
        {...rest}
      />
      {error && error[name] && <Error message={error[name]} />}
    </div>
  );
};

FormInput.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.shape({}),
};

export default FormInput;
