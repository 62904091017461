import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../common/Loader";
import useGenericGet from "../../../hooks/common/useGenericGet";
import GenericView from "../../common/GenericView";
import {
  ACTION_HISTORY_API,
  STATUSES_API,
  TRADING_LICENSE_API,
} from "../../../constants/api";
import {
  APPROVED_STATUS,
  STATUS_PENDING,
  UPDATE_TRADINDG_LICENSE,
} from "../../../constants/webapp";
import useTradingLicense from "../../../hooks/trading-license/useTradingLicense";
import GenericActionForm from "../../common/GenericActionForm";
import { Visibility } from "../../common/Visibility";
import { PAID } from "../../../constants/webapp";
import useGenericSet from "../../../hooks/common/useGenericSet";
import { Breadcrumb } from "../../common/Breadcrumb";
import { useToasts } from "react-toast-notifications";
import {
  auditActivityTableColumns,
  auditActivityTableData,
  objectWithUnsetKeys,
  prepareAttachmentsPost,
} from "../../../utils/helperUtil";
import { CreatedByUser } from "../../../utils/permissionsUtils";
import OutstandingInvoiceView from "../../common/OutstandingInvoiceView";
import GenericTabView from "../../common/tabs/GenericTabView";
import Table from "../../common/Table";
import { useAppContext } from "../../../context/AppContext";

const TradingLicenseDetails = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [showAuditTable, setShowAuditTable] = useState(0);
  const [filters, setFilter] = useState();
  const { contextData } = useAppContext();

  const {
    loadData: getTradingLicense,
    loading,
    data: tradinglicense,
  } = useGenericGet();

  const status = tradinglicense?.status_details;

  const { viewData, getActions, getRedirect, viewBalance } =
    useTradingLicense();

  const { data: deferred, loadData: getDeferred } = useGenericGet();
  const { data: approved, loadData: getApproved } = useGenericGet();
  const { data: rejected, loadData: getRejected } = useGenericGet();
  const { submitting, error, uploadData, success, data } = useGenericSet();

  const { loadData: getAuditActivity, data: AuditActivity } = useGenericGet();

  useEffect(() => {
    getTradingLicense({ api: TRADING_LICENSE_API, id });
  }, []);

  useEffect(() => {
    if (error) {
      addToast("Failed to submit  Trading License Application", {
        appearance: "error",
        autoDismiss: true,
      });
    }

    if (success) {
      addToast("You have succesfully submitted Trading License Application", {
        appearance: "success",
        autoDismiss: true,
      });
      navigate(`../trading-license/view/${data?.id}`, {
        replace: true,
      });
    }
  }, [success, error]);

  useEffect(() => {
    if (id) {
      getAuditActivity({
        api: ACTION_HISTORY_API,
        params: { trade_license: id },
      });
    }
  }, [id]);

  const onSubmit = (payload) => {
    var app_status = "";

    if (payload?.status_name === "Approve") {
      app_status = "approved";
    } else if (payload?.status_name === "Reject") {
      app_status = "rejected";
    } else if (payload?.status_name === "Deferred") {
      app_status = "deferred";
    }
    uploadData({
      api: TRADING_LICENSE_API,
      id,
      params: {
        ...prepareAttachmentsPost(
          [payload?.supporting_documents],
          "attachments"
        ),
        ...objectWithUnsetKeys(payload, ["supporting_documents"]),
      },
      type: "formdata",
      manyToMany: [
        {
          data: payload?.supporting_documents?.file || [],
          key: "supporting_documents",
        },
      ],
      redirect: getRedirect(app_status),
    });
  };

  useEffect(() => {
    getDeferred({ api: STATUSES_API, params: { name: "DEFERRED" } });
  }, []);

  useEffect(() => {
    getApproved({ api: STATUSES_API, params: { name: "APPROVED" } });
  }, []);

  useEffect(() => {
    getRejected({ api: STATUSES_API, params: { name: "REJECTED" } });
  }, []);

  if (loading) return <Loader />;

  let deferredID = deferred?.results?.map((item) => item.id)[0];
  let approvedID = approved?.results?.map((item) => item.id)[0];
  let rejectedID = rejected?.results?.map((item) => item.id)[0];

  const DetailsComponent = () => (
    <>
      <GenericView
        viewData={viewData(tradinglicense) || {}}
        navLinks={[
          {
            title: "Update Trading License Details",
            link: `${UPDATE_TRADINDG_LICENSE}/${id}`,
          },
        ]}
        action={true}
      />

      <Visibility
        show={
          ![PAID.toUpperCase()].includes(tradinglicense?.status_details) &&
          CreatedByUser(tradinglicense) &&
          contextData.role_name !== "Division Admin"
        }
      >
        {tradinglicense?.status_details?.toLowerCase() ===
        STATUS_PENDING?.toLowerCase() ? (
          <GenericActionForm
            title="Action"
            submitForm={onSubmit}
            submitting={submitting}
            actions={getActions(
              tradinglicense?.status_details,
              approvedID,
              deferredID,
              rejectedID
            )}
          />
        ) : null}
      </Visibility>
    </>
  );

  var permitTabs = [];

  if (
    tradinglicense?.status_details?.toLowerCase() === APPROVED_STATUS ||
    tradinglicense?.status_details?.toLowerCase() === PAID
  ) {
    permitTabs = [
      {
        title: "Details",
        component: <DetailsComponent />,
      },
      {
        title: "Outstanding Balance",
        component: <OutstandingInvoiceView tax_id={id} tax={"trade_license"} />,
      },
    ];
  } else {
    permitTabs = [
      {
        title: "Details",
        component: <DetailsComponent />,
      },
    ];
  }

  const AuditTableActivity = () => (
    <>
      <Table
        tableData={auditActivityTableData(AuditActivity?.results || [])}
        count={AuditActivity?.count}
        tableColumns={auditActivityTableColumns(status)}
        tableTitle={`Trade License Audit Activity`}
        onFilterChange={setFilter}
        filters={filters}
      />
    </>
  );

  return (
    <>
      <div>
        <Breadcrumb
          viewData={viewData(tradinglicense) || {}}
          titleLink={{
            title: "Trade License",
            link: "/trading-license/" + status?.toLowerCase(),
          }}
        />
        <GenericTabView
          tabs={permitTabs}
          setShowAuditTable={setShowAuditTable}
        />
      </div>
      {showAuditTable === 0 && <AuditTableActivity />}
    </>
  );
};

TradingLicenseDetails.propTypes = {
  status: PropTypes.string.isRequired,
};

export default TradingLicenseDetails;
