import React, { useEffect } from "react";
import { FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";
import { displayImages, returnObject } from "../../../utils/helperUtil";
import {
  PROPERTY_VALUATION,
  VALUATION_STATUS_ACCEPTED,
  VALUATION_STATUS_APPROVED,
  VALUATION_STATUS_OBJECT,
  VALUATION_STATUS_REJECTED,
  STATUS_SUBMITTED,
  PROPERTY_VALUATION_STATUS_APPROVED,
  YES,
  NO,
  EXEMPTED,
  WALL_FINISH,
  FLOORS_FINISH,
  ROOF_COVERINGS,
  WATER_ACCESSIBILITY,
  POWER_SUPPLY,
  SANITATION_TYPE,
  PARKING_SPACE_AVAILABLE,
  SECURITY_MEASURE,
  BOUNDARY_FENCES,
  PRT_STATUS,
  ACCOMMODATION_CATEGORIES,
  VALUATION_STATUS_DEFERRED,
  PENDING_STATUS,
  VALUATION_APPROVER,
  PROPERTY_VALUATION_STATUS_REJECTED,
  STATUS_DEFFERED,
  DOORS,
  WINDOWS,
  VALUATION_ROLL_STATUS,
  NEIGHBOURHOOD_STATUS,
  CEILINGS,
  RENT,
  RATE,
  STATUS_PENDING,
  STATUS_APPROVED,
  DATE_TIME_FORMAT,
  VALUATION_STATUS_PENDING,
  VALUATION_DATA_ENTRANT,
} from "../../../constants/webapp";
import moment from "moment";
import axiosInstance from "../../../utils/axios";
import {
  API_ALL_CONFIGURABLES_,
  CONFIGURABLES_API,
  STATUSES_API,
  USAGE_API,
} from "../../../constants/api";
import useGenericGet from "../../common/useGenericGet";
import useLocationData from "../../common/useLocationData";
import { Visibility } from "../../../components/common/Visibility";
import { useAuth } from "../../../context/authContext";
import { IsValuationApprover } from "../../../utils/permissionsUtils";
import MapContainer from "../../../components/common/map/MapContainer";

const usePropertyValuation = () => {
  const { userData } = useAuth();
  const propertyValuationTableData = (_, data) => [
    ...data.map((item) => {
      const row = {
        application_number: item?.application_number,
        valuation_roll_status: item?.valuation_roll_status_details,
        mobile_number: item?.mobile_number,
        municipality_name: item?.municipality_name,
        division_name: item?.division_name,
        parish_name: item?.parish_name,
        village_name: item?.village_name,
        street_name: item?.street_name,
        latitude: item?.r_lat,
        longitude: item?.r_lng,
        plot_number: item?.plot_number,
        house_number: item?.house_number,
        usage: item?.usage_details,
        sub_usage: item?.sub_usage_details,
        accomodation_category: item?.accomodation_category_details,
        accomodation_description: item?.accomodation_description,
        type_of_rent: item?.type_of_rent,
        rent: Number(item?.rent || 0)?.toLocaleString(),
        number_of_units: item?.number_of_units,
        total_built_up_area: item?.total_built_up_area,
        rate_per_square_meter: item?.rate_per_square_meter,
        owner_name: item?.owner_name,
        property_owner_name: item?.property_owner_name,
        gross_value: Number(item?.gross_value || 0)?.toLocaleString(),
        ratable_value: Number(item?.ratable_value || 0)?.toLocaleString(),
        annual_rate: item?.annual_rate,
        lettable_area_percentage: item?.lettable_area_perc,
        lettable_area: item?.lettable_area,
        gross_income: item?.gross_income,
        gross_value_perc: item?.gross_value_perc,
        rateable_value_perc: item?.rateable_value_perc,
        created_at: moment(
          item?.created_at === null
            ? item.created_on
            : item?.created_at || item?.created_at
        ).format(DATE_TIME_FORMAT),
        created_by: item?.created_by_name,
        approved_on: moment(item?.approved_at).format(DATE_TIME_FORMAT),
        approved_by: item?.approved_by_name,
        rejected_on: moment(item?.rejected_at).format(DATE_TIME_FORMAT),
        rejected_by: item?.rejected_by_name,
        actions: (
          <div style={{ whiteSpace: "nowrap" }}>
            <div style={{ whiteSpace: "nowrap" }}>
              <Link
                class="mr-2"
                style={{ padding: 5, paddingBottom: 0 }}
                to={
                  _.toLowerCase() === STATUS_PENDING ||
                  _.toLowerCase() === STATUS_APPROVED ||
                  (_.toLowerCase() === STATUS_DEFFERED &&
                    userData?.role_details?.name === VALUATION_DATA_ENTRANT) ||
                  userData?.role_details?.name === VALUATION_APPROVER
                    ? `${PROPERTY_VALUATION}/view/edit/${item.id}`
                    : `${PROPERTY_VALUATION}/view/${item.id}`
                }
              >
                <FaEye />
              </Link>
              <Visibility
                show={
                  ["Pending", "Approved", "Deferred"].includes(item?.status) &&
                  userData?.role_details?.name === VALUATION_DATA_ENTRANT
                }
              >
                {/* <Tooltip title="Edit">
                  <Link
                    style={{ padding: 5, paddingBottom: 0 }}
                    class=" mr-2"
                    to={`${PROPERTY_VALUATION}/update/${item.id}`}
                  >
                    <FaEdit />
                  </Link>
                </Tooltip> */}
              </Visibility>
            </div>
          </div>
        ),
      };
      return row;
    }),
  ];

  const amendableFields = [
    { id: "rent", field: "rent", type: "number" },
    {
      id: "valuation_roll_status",
      field: "valuation_roll_status",
      type: "select",
      options: "valuationstatuses",
    },
    {
      id: "property_owner_name",
      field: "property_owner_name",
      type: "text",
    },
    {
      id: "owner_name",
      field: "owner_name",
      type: "text",
    },

    {
      id: "tin",
      field: "tin",
      type: "number",
    },
    {
      id: "mobile_number",
      field: "mobile_number",
      type: "number",
    },
    {
      id: "registered_user_same_as_propertyowner",
      field: "registered_user_same_as_propertyowner",
      type: "select",
      options: "valuationstatuses",
    },
    {
      id: "municipality",
      field: "municipality",
      type: "select",
      options: "districts",
    },
    {
      id: "division",
      field: "division",
      type: "select",
      options: " divisions",
    },
    {
      id: "division",
      field: "division",
      type: "select",
      options: "divisions",
    },
    {
      id: "parish",
      field: "parish",
      type: "select",
      options: "parishes",
    },
    {
      id: "village",
      field: "village",
      type: "select",
      options: "villages",
    },
    {
      id: "street",
      field: "street",
      type: "select",
      options: "streets",
    },
    {
      id: "latitude",
      field: "latitude",
      type: "select",
      options: "streets",
    },
    {
      id: "street",
      field: "street",
      type: "select",
      options: "streets",
    },
    {
      id: "latitude",
      field: "latitude",
      type: "text",
    },
    {
      id: "longitude",
      field: "longitude",
      type: "text",
    },
    {
      id: "plot_number",
      field: "plot_number",
      type: "text",
    },
    {
      id: "house_number",
      field: "house_number",
      type: "text",
    },
    {
      id: "building_type",
      field: "building_type",
      type: "select",
      options: "buildingtypes",
    },
    {
      id: "construction_completion_status",
      field: "construction_completion_status",
      type: "select",
      options: "constructioncompletion",
    },
    {
      id: "building_condition",
      field: "building_condition",
      type: "select",
      options: "buildingconditions",
    },
    {
      id: "usage",
      field: "usage",
      type: "select",
      options: "usage",
    },
    {
      id: "sub_usage",
      field: "sub_usage",
      type: "select",
      options: "subusage",
    },
    {
      id: "type_of_road_access",
      field: "type_of_road_access",
      type: "select",
      options: "typeofroadaccess",
    },
    {
      id: "neighborhood_status",
      field: "neighborhood_status",
      type: "text",
    },
    {
      id: "walls",
      field: "walls",
      type: "select",
      options: "walls",
    },
    {
      id: "walls",
      field: "walls",
      type: "select",
      options: "walls",
    },
    {
      id: "walls_finish",
      field: "walls_finish",
      type: "select",
      options: "wallsfinish",
    },
    {
      id: "floor_finish",
      field: "floor_finish",
      type: "select",
      options: "floorfinish",
    },
    {
      id: "roof_covering",
      field: "roof_covering",
      type: "select",
      options: "roofcoverings",
    },
    {
      id: "ceiling",
      field: "ceiling",
      type: "select",
      options: "ceiling",
    },
    {
      id: "water_accessibility",
      field: "water_accessibility",
      type: "select",
      options: "wateraccessibility",
    },
    {
      id: "power_supply",
      field: "power_supply",
      type: "select",
      options: "powersupply",
    },
    {
      id: "sanitation_type",
      field: "sanitation_type",
      type: "select",
      options: "sanitationtype",
    },
    {
      id: "parking_space_available",
      field: "parking_space_available",
      type: "select",
      options: "parkingspace",
    },
    {
      id: "security_measures",
      field: "security_measures",
      type: "select",
      options: "securitymeasures",
    },
    {
      id: "boundary_fence",
      field: "boundary_fence",
      type: "select",
      options: "boundaryfence",
    },
    {
      id: "accomodation_category",
      field: "accomodation_category",
      type: "select",
      options: "accomodationcategory",
    },
    {
      id: "accomodation_description",
      field: "accomodation_description",
      type: "text",
    },
    {
      id: "prt_status",
      field: "prt_status",
      type: "select",
      options: "prtstatus",
    },
    {
      id: "prt_no",
      field: "prt_no",
      type: "text",
    },
    {
      id: "current_annual_rate",
      field: "current_annual_rate",
      type: "text",
    },
    {
      id: "rent",
      field: "rent",
      type: "text",
    },
    {
      id: "number_of_units",
      field: "number_of_units",
      type: "text",
    },
    {
      id: "total_built_up_area",
      field: "total_built_up_area",
      type: "text",
    },
    {
      id: "rate_per_square_meter",
      field: "rate_per_square_meter",
      type: "text",
    },
    {
      id: "gross_value",
      field: "gross_value",
      type: "text",
    },
    {
      id: "gross_income",
      field: "gross_income",
      type: "text",
    },
    {
      id: "gross_value_percentage",
      field: "gross_value_percentage",
      type: "text",
    },
    {
      id: "ratable_value",
      field: "ratable_value",
      type: "text",
    },
    {
      id: "annual_rate",
      field: "annual_rate",
      type: "text",
    },
    {
      id: "lettable_area_percentage",
      field: "lettable_area_percentage",
      type: "number",
    },
    {
      id: "lettable_area",
      field: "lettable_area",
      type: "text",
    },
    {
      id: "doors",
      field: "doors",
      type: "text",
    },
    {
      id: "windows",
      field: "windows",
      type: "text",
    },
  ];

  const updateFields = [
    { id: "rent", field: "rent", type: "number" },
    {
      id: "valuation_roll_status",
      field: "valuation_roll_status",
      type: "select",
      options: "valuationstatuses",
    },
    {
      id: "Registered User same as Property Owner?",
      field: "registered_user_same_as_propertyowner",
      type: "select",
      options: "same_as_propertowner",
    },
    {
      id: "mobile_number",
      field: "mobile_number",
      type: "text",
      props: {
        maxLength: 12,
      },
    },
    {
      id: "property_owner_mobile_number",
      field: "property_owner_mobile_number",
      type: "text",
      props: { readOnly: true },
      maxLength: "10",
    },
    {
      id: "taxpayer_name",
      field: "owner_name",
      type: "text",
    },
    {
      id: "Property Owner Name",
      field: "property_owner_name",
      type: "text",
    },
    {
      id: "Property Owner NAme",
      field: "owner_name",
      type: "text",
    },
    {
      id: "division",
      field: "division",
      type: "select",
      options: "divisions",
    },
    {
      id: "division",
      field: "division",
      type: "select",
      options: "divisions",
    },
    {
      id: "parish",
      field: "parish",
      type: "select",
      options: "parishes",
    },
    {
      id: "village",
      field: "village",
      type: "select",
      options: "villages",
    },
    {
      id: "street",
      field: "street",
      type: "select",
      options: "streets",
    },
    {
      id: "street",
      field: "street",
      type: "select",
      options: "streets",
    },
    {
      id: "latitude",
      field: "latitude",
      type: "text",
    },
    {
      id: "longitude",
      field: "longitude",
      type: "text",
    },
    {
      id: "plot_number",
      field: "plot_number",
      type: "text",
    },
    {
      id: "house_number",
      field: "house_number",
      type: "text",
    },
    {
      id: "General Comment",
      field: "general_comment",
      type: "text",
    },
    {
      id: "building_type",
      field: "building_type",
      type: "select",
      options: "buildingtypes",
    },
    {
      id: "construction_completion_status",
      field: "construction_completion_status",
      type: "select",
      options: "constructioncompletion",
    },
    {
      id: "building_condition",
      field: "building_condition",
      type: "select",
      options: "buildingconditions",
    },
    {
      id: "usage",
      field: "usage",
      type: "select",
      options: "usage",
    },
    {
      id: "sub_usage",
      field: "sub_usage",
      type: "select",
      options: "subusage",
    },
    {
      id: "type_of_road_access",
      field: "type_of_road_access",
      type: "select",
      options: "typeofroadaccess",
    },
    {
      id: "neighborhood_status",
      field: "neighborhood_status",
      type: "select",
      options: "neighbourhoodstatus",
    },
    {
      id: "walls",
      field: "walls",
      type: "select",
      options: "walls",
    },
    {
      id: "walls",
      field: "walls",
      type: "select",
      options: "walls",
    },
    {
      id: "walls_finish",
      field: "walls_finish",
      type: "select",
      options: "wallsfinish",
    },
    {
      id: "floor_finish",
      field: "floor_finish",
      type: "select",
      options: "floorfinish",
    },
    {
      id: "roof_covering",
      field: "roof_covering",
      type: "select",
      options: "roofcoverings",
    },
    {
      id: "ceiling",
      field: "ceiling",
      type: "select",
      options: "ceiling",
    },
    {
      id: "water_accessibility",
      field: "water_accessibility",
      type: "select",
      options: "wateraccessibility",
    },
    {
      id: "power_supply",
      field: "power_supply",
      type: "select",
      options: "powersupply",
    },
    {
      id: "sanitation_type",
      field: "sanitation_type",
      type: "select",
      options: "sanitationtype",
    },
    {
      id: "parking_space_available",
      field: "parking_space_available",
      type: "select",
      options: "parkingspace",
    },
    {
      id: "security_measures",
      field: "security_measures",
      type: "select",
      options: "securitymeasures",
    },
    {
      id: "boundary_fence",
      field: "boundary_fence",
      type: "select",
      options: "boundaryfence",
    },
    {
      id: "accomodation_category",
      field: "accomodation_category",
      type: "select",
      options: "accommodationcategory",
    },
    {
      id: "accomodation_description",
      field: "accomodation_description",
      type: "text",
    },
    {
      id: "prt_status",
      field: "prt_status",
      type: "select",
      options: "prtstatus",
    },
    {
      id: "prt_no",
      field: "prt_no",
      type: "text",
    },
    {
      id: "type_of_rent",
      field: "type_of_rent",
      type: "select",
      options: "types",
    },
    {
      id: "rent",
      field: "rent",
      type: "text",
    },
    {
      id: "number_of_units",
      field: "number_of_units",
      type: "text",
    },
    {
      id: "total_built_up_area",
      field: "total_built_up_area",
      type: "text",
    },
    {
      id: "rate_per_square_meter",
      field: "rate_per_square_meter",
      type: "text",
    },
    {
      id: "gross_value",
      field: "gross_value",
      type: "number",
      props: { readOnly: true },
    },
    {
      id: "gross_income",
      field: "gross_income",
      type: "number",
      props: { readOnly: true },
    },
    {
      id: "gross_value_percentage",
      field: "gross_value_perc",
      type: "text",
    },
    {
      id: "rateable_value_percentage",
      field: "rateable_value_perc",
      type: "text",
    },
    {
      id: "rateable_value",
      field: "ratable_value",
      type: "number",
      props: { readOnly: true },
    },
    {
      id: "annual_rate",
      field: "annual_rate",
      type: "text",
    },
    {
      id: "lettable_area_perc",
      field: "lettable_area_perc",
      type: "text",
    },
    {
      id: "lettable_area",
      field: "lettable_area",
      type: "number",
      props: { readOnly: true },
    },
    {
      id: "doors",
      field: "doors",
      type: "select",
      options: "doors",
    },
    {
      id: "windows",
      field: "windows",
      type: "select",
      options: "windows",
    },
    {
      id: "property_owner_name",
      field: "property_owner_name",
      type: "text",
    },
    {
      id: "PRT status (Does property pay PRT?)",
      field: "prt_status",
      type: "select",
      options: "prtstatus",
    },
    {
      id: "rent",
      field: "rent",
      type: "text",
    },
    {
      id: "annual_rate",
      field: "annual_rate",
      type: "text",
      props: { readOnly: true },
    },
  ];

  const viewData = (item, isFormData, status) => {
    return {
      ...returnObject(!isFormData, {
        "Reference Number": item?.application_number,
      }),
      ...returnObject(isFormData, {
        taxpayer_name: `${item?.first_name} ${item?.surname}`,
      }),
      ...returnObject(!isFormData, {
        taxpayer_name: item?.owner_name,
      }),
      ...returnObject(!isFormData, {
        tin: item?.property_owner_details?.tin,
      }),
      ...returnObject(isFormData, {
        mobile_number: item?.mobile_number,
      }),

      "Registered User same as Property Owner?":
        item?.registered_user_same_as_propertyowner,
      ...returnObject(item?.registered_user_same_as_propertyowner === "No", {
        "Property Owner": item?.property_owner_name,
      }),
      ...returnObject(item?.registered_user_same_as_propertyowner === "Yes", {
        ...returnObject(!isFormData, {
          "Property Owner": item?.property_owner_name,
        }),
      }),

      ...returnObject(item?.registered_user_same_as_propertyowner === "Yes", {
        ...returnObject(isFormData, {
          "Property Owner": item?.owner_name,
        }),
      }),

      municipality: isFormData
        ? item?.municipality_name
        : item?.municipality_name,
      division: isFormData ? item?.division_name : item?.division_name,
      parish: isFormData ? item?.parish_name : item?.parish_name,
      village: isFormData ? item?.village_name : item?.village_name,
      street: isFormData ? item?.street_name : item?.street_name,
      latitude: item?.latitude || item?.r_lat,
      longitude: item?.longitude || item?.r_lng,
      plot_number: item?.plot_number,
      house_number: item?.house_number,
      building_type: isFormData
        ? item?.building_type_name
        : item?.building_type_details,
      construction_completion_status: isFormData
        ? item.construction_completion_status_name
        : item?.construction_completion_status_details,
      building_condition: isFormData
        ? item.building_condition_name
        : item?.building_condition_details,
      usage: isFormData ? item?.usage_name : item?.usage_details,
      sub_usage: isFormData ? item?.sub_usage_name : item?.sub_usage_details,
      type_of_road_access: isFormData
        ? item?.type_of_road_access_name
        : item?.type_of_road_access_details,
      neighborhood_status: isFormData
        ? item?.neighborhood_status_name
        : item?.neighborhood_status_details,
      walls: isFormData ? item?.walls_name : item?.walls_details,
      walls_finish: isFormData
        ? item?.walls_finish_name
        : item?.walls_finish_details,
      doors: isFormData ? item?.doors_name : item?.doors_details,
      windows: isFormData ? item?.windows_name : item?.windows,
      floor_finish: isFormData
        ? item?.floor_finish_name
        : item?.floor_finish_details,
      roof_covering: isFormData
        ? item?.roof_covering_name
        : item?.roof_covering_details,
      ceiling: isFormData ? item?.ceiling_name : item?.ceiling_details,
      water_accessibility: isFormData
        ? item?.water_accessibility_name
        : item?.water_accessibility_details,
      power_supply: isFormData
        ? item?.power_supply_name
        : item?.power_supply_details,
      sanitation_type: isFormData
        ? item?.sanitation_type_name
        : item?.sanitation_type_details,
      parking_space_available: isFormData
        ? item?.parking_space_available_name
        : item?.parking_space_available_details,
      security_measures: isFormData
        ? item?.security_measures_name
        : item?.security_measures_details,
      boundary_fence: isFormData
        ? item?.boundary_fence_name
        : item?.boundary_fence_details,
      accomodation_category: isFormData
        ? item?.accomodation_category_name
        : item?.accomodation_category_details,
      accomodation_description: item?.accomodation_description,
      "General Comment": item?.general_comment,

      ...returnObject(!isFormData, {
        Photos: displayImages(item?.property_valuations_attachments),
      }),

      valuation_roll_status: isFormData
        ? item?.valuation_roll_status_name
        : item?.valuation_roll_status_details,

      "PRT status (Does property pay PRT?)": isFormData
        ? item?.prt_status_name
        : item?.prt_status_details,
      prt_no: item?.prt_no,
      valuation_roll_status: item?.valuation_roll_status,
      type_of_rent: isFormData ? item?.type_of_rent_name : item?.type_of_rent,

      ...returnObject(item?.type_of_rent === "Rate", {
        total_built_up_area: item?.total_built_up_area,
        rate_per_square_meter: item?.rate_per_square_meter,

        ...returnObject(!isFormData, {
          lettable_area_perc: item?.lettable_area_perc,
          lettable_area: item?.lettable_area,
        }),
      }),

      ...returnObject(item?.type_of_rent === "Rent", {
        rent: item?.rent,
        number_of_units: item?.number_of_units,
      }),

      ...returnObject(!isFormData, {
        gross_income: Number(item?.gross_income || 0).toLocaleString(),
        gross_value_percentage: item?.gross_value_perc,
        gross_value: Number(item?.gross_value || 0).toLocaleString(),
        rateable_value_percentage: item?.rateable_value_perc,
        ratable_value: Number(item?.ratable_value || 0).toLocaleString(),
      }),

      ...returnObject(!isFormData, {
        "Created On": moment(item?.created_at).format(DATE_TIME_FORMAT),
        ...returnObject(!isFormData, {
          Status: item?.status,
        }),
      }),
    };
  };

  const prtViewData = (
    item,
    isFormData,
    form,
    updateFormValues,
    setCoordinates,
    annual_rate_percentage
  ) => {
    return {
      ...returnObject(!isFormData, {
        "Reference Number": item?.application_number,
      }),

      ...returnObject(isFormData, {
        taxpayer_name: `${item?.first_name} ${item?.surname}`,
      }),
      ...returnObject(!isFormData, {
        taxpayer_name: item?.owner_name,
      }),
      ...returnObject(!isFormData, {
        tin: item?.property_owner_details?.tin,
      }),
      ...returnObject(isFormData, {
        mobile_number: item?.mobile_number,
      }),

      ...returnObject(!isFormData, {
        mobile_number: item?.mobile_number,
      }),

      "Registered User same as Property Owner?":
        item?.registered_user_same_as_propertyowner,
      ...returnObject(item?.registered_user_same_as_propertyowner === "No", {
        "Property Owner Name": item?.property_owner_name,
      }),
      ...returnObject(item?.registered_user_same_as_propertyowner === "Yes", {
        property_owner_name: item?.property_owner_name,
      }),

      municipality: isFormData
        ? item?.municipality_name
        : item?.municipality_name,
      division: isFormData ? item?.division_name : item?.division_name,
      parish: isFormData ? item?.parish_name : item?.parish_name,
      village: isFormData ? item?.village_name : item?.village_name,
      street: isFormData ? item?.street_name : item?.street_name,
      latitude: item?.latitude,
      longitude: item?.longitude,
      Map: (
        <div className="row">
          <MapContainer
            onUpdateValues={setCoordinates}
            longitude={form?.longitude}
            latitude={form?.latitude}
          />
        </div>
      ),
      plot_number: item?.plot_number,
      house_number: item?.house_number,
      building_type: isFormData
        ? item?.building_type_name
        : item?.building_type_details,
      construction_completion_status: isFormData
        ? item.construction_completion_status_name
        : item?.construction_completion_status_details,
      building_condition: isFormData
        ? item.building_condition_name
        : item?.building_condition_details,
      usage: isFormData ? item?.usage_name : item?.usage_details,
      sub_usage: isFormData ? item?.sub_usage_name : item?.sub_usage_details,
      type_of_road_access: isFormData
        ? item?.type_of_road_access_name
        : item?.type_of_road_access_details,
      neighborhood_status: isFormData
        ? item?.neighborhood_status_name
        : item?.neighborhood_status_details,
      walls: isFormData ? item?.walls_name : item?.walls_details,
      walls_finish: isFormData
        ? item?.walls_finish_name
        : item?.walls_finish_details,
      doors: isFormData ? item?.doors_name : item?.doors,
      windows: isFormData ? item?.windows_name : item?.windows,
      floor_finish: isFormData
        ? item?.floor_finish_name
        : item?.floor_finish_details,
      roof_covering: isFormData
        ? item?.roof_covering_name
        : item?.roof_covering_details,
      ceiling: isFormData ? item?.ceiling_name : item?.ceiling_details,
      water_accessibility: isFormData
        ? item?.water_accessibility_name
        : item?.water_accessibility_details,
      power_supply: isFormData
        ? item?.power_supply_name
        : item?.power_supply_details,
      sanitation_type: isFormData
        ? item?.sanitation_type_name
        : item?.sanitation_type_details,
      parking_space_available: isFormData
        ? item?.parking_space_available_name
        : item?.parking_space_available_details,
      security_measures: isFormData
        ? item?.security_measures_name
        : item?.security_measures_details,
      boundary_fence: isFormData
        ? item?.boundary_fence_name
        : item?.boundary_fence_details,
      accomodation_category: isFormData
        ? item?.accomodation_category_name
        : item?.accomodation_category_details,
      accomodation_description: item?.accomodation_description,
      "General Comment": item?.general_comment,

      ...returnObject(!isFormData, {
        Photos: displayImages(item?.property_valuations_attachments),
      }),
      valuation_roll_status: item?.valuation_roll_status_details,
      "PRT status (Does property pay PRT?)": isFormData
        ? item?.prt_status_name
        : item?.prt_status_details,
      prt_no: item?.prt_no,
      //valuation_roll_status: item?.valuation_roll_status,
      type_of_rent: isFormData ? item?.type_of_rent_name : item?.type_of_rent,
      ...returnObject(
        /*item?.type_of_rent === "Rate" ||*/ form?.type_of_rent === "Rate",
        {
          rate_per_square_meter: Number(item?.rate_per_square_meter || 0),
          total_built_up_area: Number(item?.total_built_up_area || 0),

          ...returnObject(!isFormData, {
            lettable_area_perc: Number(item?.lettable_area_perc || 0),
            lettable_area: Number(item?.lettable_area || 0),
          }),
        }
      ),

      ...returnObject(
        /*item?.type_of_rent === "Rent" ||*/ form?.type_of_rent === "Rent",
        {
          rent: item?.rent,
          number_of_units: item?.number_of_units,
          total_built_up_area: item?.total_built_up_area,
        }
      ),

      ...returnObject(IsValuationApprover(), {
        gross_income: Number(item?.gross_income || 0).toLocaleString(),
        gross_value_percentage: item?.gross_value_perc,
        gross_value: Number(item?.gross_value || 0).toLocaleString(),
        rateable_value_percentage: item?.rateable_value_perc,
        rateable_value: Number(item?.ratable_value || 0).toLocaleString(),
        annual_rate_percentage: annual_rate_percentage,
        annual_rate: item?.annual_rate,
      }),

      ...returnObject(!isFormData, {
        "Created On": moment(item?.created_at).format(DATE_TIME_FORMAT),
        ...returnObject(!isFormData, {
          Status: item?.status,
        }),
      }),
    };
  };

  const propertyValuationTableColumns = (status) => [
    { id: "application_number", header: "Reference Number ", filter: {} },
    { id: "valuation_roll_status", header: "Roll Status ", filter: {} },
    { id: "owner_name", header: "TaxPayer Name", filter: {} },
    { id: "property_owner_name", header: "Property Owner", filter: {} },
    { id: "mobile_number", header: "Mobile Number", filter: {} },
    { id: "municipality_name", header: "Municipality", filter: {} },
    { id: "division_name", header: "Division", filter: {} },
    { id: "parish_name", header: "Parish", filter: {} },
    { id: "village_name", header: "Village", filter: {} },
    { id: "street_name", header: "Street", filter: {} },
    { id: "latitude", header: "Latitude", filter: {} },
    { id: "longitude", header: "Longitude", filter: {} },
    { id: "plot_number", header: "Plot Number ", filter: {} },
    { id: "house_number", header: "House Number", filter: {} },
    { id: "usage", header: "Usage", filter: {} },
    { id: "sub_usage", header: "Sub Usage", filter: {} },
    {
      id: "accomodation_category",
      header: "Accommodation Category",
      filter: {},
    },
    {
      id: "accomodation_description",
      header: "Accommodation Description",
      filter: {},
    },
    { id: "rent", header: "Rent", filter: {} },
    { id: "number_of_units", header: "Number of Units", filter: {} },
    { id: "gross_value", header: "Gross Value", filter: {} },
    { id: "ratable_value", header: "Rateable Value", filter: {} },
    { id: "gross_value_perc", header: "Gross Value Percentage", filter: {} },
    {
      id: "rateable_value_perc",
      header: "Ratable Value Percentage",
      filter: {},
    },

    { id: "created_at", header: "Created On", filter: { type: "date" } },
    { id: "created_by", header: "Created By", filter: {} },
    ...returnObject(status === PROPERTY_VALUATION_STATUS_APPROVED, [
      { id: "approved_on", header: "Approved On", filter: {} },
      { id: "approved_by", header: "Approved By", filter: {} },
    ]),
    ...returnObject(
      [PROPERTY_VALUATION_STATUS_REJECTED, STATUS_DEFFERED].includes(status),
      [
        { id: "rejected_on", header: "Rejected On", filter: {} },
        { id: "rejected_by", header: "Rejected By", filter: {} },
      ]
    ),
    { id: "actions", header: "Actions" },
  ];

  const getActions = (status) => {
    let options;

    switch (status?.toLowerCase()) {
      case PENDING_STATUS:
        options = [
          { value: VALUATION_STATUS_APPROVED, label: "Approve" },
          { value: VALUATION_STATUS_DEFERRED, label: "Defer" },
        ];
        break;

      case STATUS_APPROVED:
        options = [
          { value: VALUATION_STATUS_REJECTED, label: "Reject" },
          { value: VALUATION_STATUS_DEFERRED, label: "Defer" },
        ];
        break;

      case STATUS_DEFFERED:
        options = [{ value: VALUATION_STATUS_APPROVED, label: "Re-Submit" }];
        break;

      default:
        options = [];
    }

    return options;
  };

  const getObjectionStatus = (status) => {
    let options;

    switch (status) {
      case PROPERTY_VALUATION_STATUS_APPROVED:
        options = [
          { value: VALUATION_STATUS_ACCEPTED, label: "Accept" },
          { value: VALUATION_STATUS_OBJECT, label: "Object" },
        ];
        break;

      default:
        options = [];
    }

    return options;
  };

  const getStatus = (name) => {
    var id = 0;
    axiosInstance(STATUSES_API, { params: { name } })
      .then((response) => {
        id = response?.data?.results?.map((item) => item?.id);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getRedirect = (status) => {
    let redirect = "";
    switch (status) {
      case STATUS_SUBMITTED:
        redirect = `${PROPERTY_VALUATION}/${VALUATION_STATUS_PENDING}`;
        break;

      case VALUATION_STATUS_APPROVED:
        redirect = `${PROPERTY_VALUATION}/${VALUATION_STATUS_APPROVED}`;
        break;

      case VALUATION_STATUS_REJECTED:
        redirect = `${PROPERTY_VALUATION}/${VALUATION_STATUS_REJECTED}`;
        break;

      case VALUATION_STATUS_DEFERRED:
        redirect = `${PROPERTY_VALUATION}/${VALUATION_STATUS_DEFERRED}`;
        break;

      default:
        break;
    }

    return redirect;
  };

  return {
    propertyValuationTableColumns,
    propertyValuationTableData,
    viewData,
    prtViewData,
    getActions,
    getRedirect,
    getStatus,
    getObjectionStatus,
    amendableFields,
    updateFields,
  };
};

export const usePropertyValuationFormSelect = (
  form,
  updateFormValue,
  municipality
) => {
  const {
    districts,
    getDistricts,
    divisions,
    getDivisions,
    parishes,
    getParishes,
    villages,
    getVillages,
    streets,
    getStreets,
  } = useLocationData();
  const { data: usage, loadData: getUsage } = useGenericGet();
  const { data: subusage, loadData: getSubUsage } = useGenericGet();
  const { data: buildingtypes, loadData: getBuildingTypes } = useGenericGet();
  const {
    data: constructioncompletion,
    loadData: getConstructionCompletionStatus,
  } = useGenericGet();
  const { data: wallsfinish, loadData: getWallFinish } = useGenericGet();

  const { data: buildingcondition, loadData: getBuildingConditions } =
    useGenericGet();

  const { data: typeofroadaccess, loadData: getTypeOfRoadAccess } =
    useGenericGet();
  const { data: walls, loadData: getWalls } = useGenericGet();
  const { data: floorfinish, loadData: getFloorFinish } = useGenericGet();
  const { data: ceiling, loadData: getCeiling } = useGenericGet();
  const { data: roofcoverings, loadData: getRoofCoverings } = useGenericGet();
  const { data: wateraccessibility, loadData: getWaterAccessibility } =
    useGenericGet();
  const { data: powersupply, loadData: getPowerSupply } = useGenericGet();
  const { data: sanitationtype, loadData: getSanitationType } = useGenericGet();
  const { data: parkingspace, loadData: getParkingSpace } = useGenericGet();
  const { data: securitymeasures, loadData: getSecurityMeasures } =
    useGenericGet();
  const { data: boundaryfence, loadData: getBoundaryFence } = useGenericGet();
  const { data: accommodationcategory, loadData: getAccomodationCategory } =
    useGenericGet();
  const { data: prtstatus, loadData: getPrtStatus } = useGenericGet();
  const { data: doors, loadData: getDoors } = useGenericGet();
  const { data: windows, loadData: getWindows } = useGenericGet();
  const { data: valuationstatuses, loadData: getValuationRollStatus } =
    useGenericGet();
  const { data: neighbourhoodstatus, loadData: getNeighbourhoodStatus } =
    useGenericGet();

  const same_as_propertowner = [
    {
      id: "Yes",
      name: "Yes",
    },
    {
      id: "No",
      name: "No",
    },
  ];

  const prtstatuses = [
    {
      id: YES,
      name: "Yes",
    },
    {
      id: NO,
      name: "No",
    },
    {
      id: EXEMPTED,
      name: "Exempted",
    },
  ];

  const types = [
    {
      id: RENT,
      name: "Rent",
    },
    {
      id: RATE,
      name: "Rate",
    },
  ];

  useEffect(() => {
    getDistricts();
  }, []);

  useEffect(() => {
    if (municipality) getDivisions(municipality);
  }, [municipality]);

  useEffect(() => {
    if (form?.division) getParishes(form?.division);
  }, [form?.division]);

  useEffect(() => {
    if (form?.parish) getVillages(form?.parish);
  }, [form?.parish]);

  useEffect(() => {
    if (form?.village) getStreets(form?.village);
  }, [form?.village]);

  useEffect(() => {
    if (form?.usage) {
      getSubUsage({
        api: API_ALL_CONFIGURABLES_,
        params: { parent: form?.usage },
      });
    }
  }, [form?.usage]);

  useEffect(() => {
    getUsage({
      api: USAGE_API,
    });
    getBuildingTypes({
      api: API_ALL_CONFIGURABLES_,
      params: { field: "Building Type" },
    });
    getConstructionCompletionStatus({
      api: API_ALL_CONFIGURABLES_,
      params: { field: "Construction Completion Status" },
    });
    getBuildingConditions({
      api: API_ALL_CONFIGURABLES_,
      params: { field: "Building Condition" },
    });
    getTypeOfRoadAccess({
      api: CONFIGURABLES_API,
      params: { field: "Type of road access" },
    });
    getWalls({
      api: API_ALL_CONFIGURABLES_,
      params: { field: "Walls" },
    });
    getWallFinish({
      api: API_ALL_CONFIGURABLES_,
      params: { field: WALL_FINISH },
    });
    getFloorFinish({
      api: API_ALL_CONFIGURABLES_,
      params: { field: FLOORS_FINISH },
    });
    getRoofCoverings({
      api: API_ALL_CONFIGURABLES_,
      params: { field: ROOF_COVERINGS },
    });
    getCeiling({
      api: CONFIGURABLES_API,
      params: { field: CEILINGS },
    });
    getWaterAccessibility({
      api: API_ALL_CONFIGURABLES_,
      params: { field: WATER_ACCESSIBILITY },
    });
    getPowerSupply({
      api: API_ALL_CONFIGURABLES_,
      params: { field: POWER_SUPPLY },
    });
    getSanitationType({
      api: API_ALL_CONFIGURABLES_,
      params: { field: SANITATION_TYPE },
    });
    getParkingSpace({
      api: API_ALL_CONFIGURABLES_,
      params: { field: PARKING_SPACE_AVAILABLE },
    });
    getSecurityMeasures({
      api: API_ALL_CONFIGURABLES_,
      params: { field: SECURITY_MEASURE },
    });
    getBoundaryFence({
      api: API_ALL_CONFIGURABLES_,
      params: { field: BOUNDARY_FENCES },
    });
    getAccomodationCategory({
      api: API_ALL_CONFIGURABLES_,
      params: { field: ACCOMMODATION_CATEGORIES },
    });
    getPrtStatus({
      api: API_ALL_CONFIGURABLES_,
      params: { field: PRT_STATUS },
    });
    getDoors({
      api: API_ALL_CONFIGURABLES_,
      params: { field: DOORS },
    });
    getWindows({
      api: API_ALL_CONFIGURABLES_,
      params: { field: WINDOWS },
    });
    getValuationRollStatus({
      api: API_ALL_CONFIGURABLES_,
      params: { field: VALUATION_ROLL_STATUS },
    });
    getNeighbourhoodStatus({
      api: API_ALL_CONFIGURABLES_,
      params: { field: NEIGHBOURHOOD_STATUS },
    });
  }, []);

  return {
    districts,
    divisions,
    parishes,
    villages,
    streets,
    usage: usage?.results || [],
    subusage: subusage || [],
    valuationstatuses,
    same_as_propertowner,
    prtstatuses,
    buildingtypes: buildingtypes || [],
    constructioncompletion: constructioncompletion || [],
    buildingconditions: buildingcondition || [],
    typeofroadaccess: typeofroadaccess?.results || [],
    walls: walls || [],
    doors: doors || [],
    windows: windows || [],
    wallsfinish: wallsfinish || [],
    floorfinish: floorfinish || [],
    ceiling: ceiling?.results || [],
    roofcoverings: roofcoverings || [],
    wateraccessibility: wateraccessibility || [],
    powersupply: powersupply || [],
    sanitationtype: sanitationtype || [],
    parkingspace: parkingspace || [],
    securitymeasures: securitymeasures || [],
    boundaryfence: boundaryfence || [],
    accommodationcategory: accommodationcategory || [],
    prtstatus: prtstatus || [],
    neighbourhoodstatus: neighbourhoodstatus || [],
    types,
  };
};

export default usePropertyValuation;
