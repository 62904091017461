export function sortAlpha(array) {
  return array?.sort((a, b) => a.label.localeCompare(b.name));
}

export function sortAlphaByKey(array, key) {
  return array?.sort((a, b) => a[key]?.localeCompare(b[key]));
}

export function removeEmpty(array, key) {
  return array?.filter((val) => {
    return val[key] > 0;
  });
}

// level_details.name
// export function checkForPermit(permit) {
//   // returns true if permit is found
//   return results.some(
//     (e) => e.config_details.scrutiny_workflow_details.name_display === permit
//   );
// }
