import { CircularProgress } from "@mui/material";
import React from "react";
import { configColor } from "../styled/Theme";

export const CircularLoader = ({ dark = false, size }) => {
  return (
    <div className="textCenter">
      <CircularProgress
        size={size}
        sx={{ color: dark ? "white" : configColor }}
      />
    </div>
  );
};
