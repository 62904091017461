import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useGenericGet from "../../../hooks/common/useGenericGet";
import Loader from "../../common/Loader";
import Table from "../../common/Table";
import {
  GENERAL_LEDGER_API,
  GENERAL_LEDGER_CSV_API,
  GENERAL_LEDGER_EXCEL_API,
} from "../../../constants/api";
import useGeneralLedger from "../../../hooks/payments/useGeneralLedger";
import { AntTable } from "../../common/tables/AntTable";

const BusinessBranchRegister = () => {
  // const location = useLocation();
  const [filters, setFilter] = useState({});
  const { loadData: getLedger, data: ledger, loading } = useGenericGet();

  const { branchTableColumns, branchTableData } = useGeneralLedger();

  useEffect(() => {
    getLedger({ api: GENERAL_LEDGER_API, params: filters });
  }, [filters]);

  if (loading) return <Loader />;

  return (
    <div>
      {/* <Table
        tableData={ledgerTableData(ledger?.results || [])}
        tableColumns={ledgerTableColumns}
        tableTitle="Sub Ledger"
        loading={loading}
        count={ledger?.count}
        onFilterChange={setFilter}
        filters={filters}
        excelDownloadApi={GENERAL_LEDGER_EXCEL_API}
        csvDownloadApi={GENERAL_LEDGER_CSV_API}
      /> */}

      <AntTable
        scroll={{ x: 1500 }}
        tableTitle={"Business Branch Register"}
        tableData={branchTableData(ledger?.results || [])}
        tableColumns={branchTableColumns}
      />
    </div>
  );
};

export default BusinessBranchRegister;
