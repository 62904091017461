import axios from "axios";

const formDataInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

formDataInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default formDataInstance;
