import React, { useState } from "react";
import { FaEdit, FaEye } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/axios";
import {
  BUSINESS_CATEGORIES_API,
  NATURE_OF_BUSINESS_ALL_API,
} from "../../constants/api";
import { DeleteButton } from "../../components/common/Button";

function useBusinessCategories() {
  const [categories, setCategories] = useState([]);
  const [nature_of_business, setNatureOfBusiness] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  //Column names in the business categories' UI table
  const businessCategoriesTableColumns = [
    { id: "name", header: "Name", filter: {} },
    { id: "administration_type", header: "Administration Type", filter: {} },
    { id: "actions", header: "Actions" },
  ];

  // Delete
  const deleteBusinessCategory = (id) => {
    setLoading(true);
    axiosInstance
      .delete(`configurables/trade-categories/${id}`)
      .then(() => {
        // handle success
        setLoading(false);
        navigate("/business-categories", { replace: true });
      })
      .catch((err) => {
        // handle error
        setLoading(false);
        setError(err);
      });
  };

  // Get list of Business Categories
  const getBusinessCateogories = (status) => {
    axiosInstance
      .get(BUSINESS_CATEGORIES_API, {
        params: { administration_type: status },
      })
      .then((res) => {
        // handle success
        setCategories(res.data.results);
      })
      .catch((err) => {
        // handle error
        setError(err);
      });
  };

  // Get list of nature of business
  const getNatureOfBusiness = (administration_type) => {
    axiosInstance
      .get(NATURE_OF_BUSINESS_ALL_API, {
        params: { administration_type: administration_type },
      })
      .then((res) => {
        // handle success
        setNatureOfBusiness(res.data.results);
      })
      .catch((err) => {
        // handle error
        setError(err);
      });
  };

  //Loading data into the business categories UI table
  const businessCategoriesTableData = (data) => [
    ...data.map((item) => {
      const row = {
        name: item.name,
        administration_type: item?.administration_type,
        actions: (
          <>
            <Link
              class="mr-2"
              style={{ padding: 5, paddingBottom: 0 }}
              to={`/business-categories/view/${item.id}`}
            >
              <FaEye />
            </Link>
            <Link
              style={{ padding: 5, paddingBottom: 0 }}
              class=" mr-2"
              to={`/business-categories/update/${item.id}`}
            >
              <FaEdit />
            </Link>
            <DeleteButton onClick={() => deleteBusinessCategory(item.id)} />
          </>
        ),
      };
      return row;
    }),
  ];

  const viewData = (item) => {
    return {
      Name: item?.name,
      administration_type: item?.administration_type,
    };
  };

  const getRedirect = (status) => {
    let redirect = "";
    switch (status) {
      default:
        redirect = "/business-categories";
        break;
    }

    return redirect;
  };

  return {
    businessCategoriesTableColumns,
    businessCategoriesTableData,
    categories,
    getBusinessCateogories,
    nature_of_business,
    getNatureOfBusiness,
    getRedirect,
    viewData,
  };
}

export default useBusinessCategories;
