import React, { useState } from "react";
import { AntTable } from "../../common/tables/AntTable";
import useInvoicesOwner from "../../../hooks/invoices-owner/useInvoicesOwner";
import Loader from "../../common/Loader";
import { useAppContext } from "../../../context/AppContext";
import GenericDialog from "../../dialog/GenericDialog";
import Paf from "./Paf";
import { PaymentDetails } from "./PaymentDetails";

const InvoicesOwner = () => {
  const loading = false;
  const { contextData } = useAppContext();
  const { invoicesOwner, filterData, invoice_id } = contextData;
  const [openModal, setOpenModal] = useState(false);

  const { invoiceOwnerTableColumns, invoiceOwnerTableData } = useInvoicesOwner(
    invoicesOwner?.results
  );

  return loading ? (
    <Loader />
  ) : (
    <>
      <PaymentDetails filterData={filterData} />
      <GenericDialog title="Details" open={openModal} setOpen={setOpenModal}>
        <Paf id={invoice_id} />
      </GenericDialog>
      <AntTable
        scroll={{ x: 700 }}
        tableTitle="Invoices"
        tableColumns={invoiceOwnerTableColumns()}
        tableData={invoiceOwnerTableData(setOpenModal)}
      />
    </>
  );
};

export default InvoicesOwner;
