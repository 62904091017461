export const percentageChange = (current, previous) => {
  var text = "higher";
  var suffix = "than last F/Y";
  var percentChange = ((current - previous) / previous) * 100;

  if (percentChange < 0) {
    text = "lower";
    percentChange = -percentChange;
  }

  var percentage = `${percentChange.toFixed(0) | 0}%`;

  return {
    text: text,
    suffix: suffix,
    percentage: percentage,
    full_text: `${percentage} ${text} ${suffix}`,
  };
};

export const percentageChangeFys = (current, previous) => {
  var percentChange = (current / previous) * 100;
  var percentage = percentChange.toFixed(1);
  var comparison = (100 - percentChange).toFixed(1);
  var archieved = `${percentage}% achieved of last F/Y`;

  var text = "higher";
  if (comparison < 0) {
    text = "lower";
    comparison = -comparison;
  }

  comparison = `${comparison}% ${text} than this F/Y`;
  return { archieved: archieved, comparison: comparison };
};
