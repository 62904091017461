import React from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import PropTypes from "prop-types";

const DocumentViewer = ({ docs }) => {
  return (
    <DocViewer
      documents={docs}
      initialActiveDocument={docs[1]}
      pluginRenderers={DocViewerRenderers}
      style={{ height: "90vh" }}
    />
  );
};

DocumentViewer.propTypes = {
  docs: PropTypes.arrayOf(PropTypes.shape({})),
};

export default DocumentViewer;
