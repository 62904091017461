import React, { useEffect, useState } from "react";
import {
  INDIVIDUAL,
  NON_INDIVIDUAL,
  SOURCE_YES,
} from "../../../constants/webapp";
import useForm from "../../../hooks/common/useForm";
import { useNavigate, useParams } from "react-router-dom";
import useGenericGet from "../../../hooks/common/useGenericGet";
import useGenericSet from "../../../hooks/common/useGenericSet";
import IndividualInstantTinForm from "./IndividualInstantTinForm";
import NonIndividualInstantTinForm from "./NonIndividualInstantTinForm";
import {
  INDIVIDUAL_INSTANT_TIN_API,
  NON_INDIVIDUAL_INSTANT_TIN_API,
  URA_INSTANT_TIN_API,
} from "../../../constants/api";
import { useToasts } from "react-toast-notifications";
import SelectInput from "../../common/SelectInput";
import moment from "moment";

const SetInstantTinForm = () => {
  const [ownershipType, setOwnershipType] = useState(INDIVIDUAL);
  const [source, setSource] = useState(SOURCE_YES);
  const [sourceincome, setSourceIncome] = useState(SOURCE_YES);
  const [sourceemployment, setSourceEmployment] = useState(SOURCE_YES);

  const { form, updateFormValue, onChange } = useForm({});
  const { error, uploadData, submitting, data, success } = useGenericSet();
  const { loadData: getTin } = useGenericGet();
  const { id } = useParams();

  const navigate = useNavigate();
  const { addToast } = useToasts();

  const ownershipTypes = [
    {
      id: INDIVIDUAL,
      name: "Individual",
    },
    {
      id: NON_INDIVIDUAL,
      name: "Non-individual",
    },
  ];

  useEffect(() => {
    if (id) {
      getTin({ api: URA_INSTANT_TIN_API, id });
    }
  }, [id]);

  useEffect(() => {
    if (error) {
      addToast("Failed to submit  Instant TIN Application", {
        appearance: "error",
        autoDismiss: true,
      });
    }

    if (success) {
      addToast("You have succesfully submitted TIN Application", {
        appearance: "success",
        autoDismiss: true,
      });
      navigate(`../instant-tin/register`, {
        replace: true,
      });
    }
  }, [success, error]);

  const submitForm = (payload) => {
    switch (ownershipType) {
      case 0:
        uploadData({
          api: INDIVIDUAL_INSTANT_TIN_API,
          params: {
            ...payload,
            personalInfomation: {
              name: `${payload?.surname} ${payload?.first_name}`,
              identificationNo: payload?.nin,
              dateOfBirth: moment(payload?.date_of_birth).format("DD/MM/YYYY"),
              title: payload?.title,
              maritalStatus: payload?.marital_status,
              surname: payload?.surname,
              firstName: payload?.first_name,
              gender: payload?.gender,
            },
            incomeSourceDetails: {
              isSrcBusiness:
                source === SOURCE_YES
                  ? SOURCE_YES
                  : payload.income_source_business,
              isSrcEmployment:
                sourceemployment === SOURCE_YES
                  ? SOURCE_YES
                  : payload.income_source_employment,
              isSrcInformalBusiness:
                sourceincome === SOURCE_YES
                  ? SOURCE_YES
                  : payload.incource_source_informal,
              isSrcRent: payload?.incource_source_rent,
              IsSrcOther: payload?.incource_source_property,
              isSrcProperty: payload?.incource_source_property,
            },
            addressDetails: {
              telephoneNumber: payload?.telephone_number,
              emailAddress: payload?.email,
              homeDistrict: payload?.district,
              homeCounty: payload?.county,
              homeSubCounty: payload?.sub_county,
              homeParish: payload?.parish,
              homeVillage: payload?.village,
            },
          },
          // type: "formdata",
        });
        break;
      default:
        uploadData({
          api: NON_INDIVIDUAL_INSTANT_TIN_API,
          params: {
            ...payload,
            masterData: {
              entityType: payload?.entity_type,
              entitySubType: payload?.entity_sub_type,
              brnFromURSB: payload?.brn,
              registeredName: payload?.registered_name,
              legalName: payload?.legal_name,
              businessActivityDescription: payload?.description,
              isResidentTaxPayer: payload?.is_resident_taxpayer,
              telephoneCountryCode: payload?.country_code,
              telephoneNo: payload?.telephone,
              emailAddress: payload?.email,
              entityEstablishmentDate: moment(
                payload?.entityEstablishmentDate
              ).format("DD/MM/YYYY"),
            },
            businessClassification: {
              mainActivity: payload?.main_activity,
              mainActivityDivision: payload?.main_activity_division,
              mainActivityClass: payload?.activity_class,
              isSourceRental: payload?.incource_source_rent,
              isSourceProperty: payload?.incource_source_property,
              isSourceBusiness: payload?.incource_source_business,
              hasIncomeTax: payload?.has_income_tax,
              hasValueAddedTax: payload?.has_value_added_tax,
              hasStampDuty: payload?.stamp_duty,
              hasImportAndExports: payload?.has_import,
              hasGamingTax: payload?.has_gaming_tax,
              hasLocalExciseDuty: payload?.has_excise_duty,
              hasMotorVehicle: payload?.motor_vehicle,
              hasExemption: payload?.has_exemption,
            },
            businessContactDetails: {
              district: payload?.district,
              county: payload?.county,
              subCounty: payload?.sub_county,
              parish: payload?.parish,
              village: payload?.village,
            },
            authorizedContactDetails: [
              {
                hasNIN: payload?.authorizeddata[0]?.has_nin,
                nin: payload?.authorizeddata[0]?.nin,
                dob: moment(payload?.authorizeddata[0]?.date_of_birth).format(
                  "DD/MM/YYYY"
                ),
                firstName: payload?.authorizeddata[0]?.first_name,
                surname: payload?.authorizeddata[0]?.surname,
                email: payload?.authorizeddata[0]?.email,
                title: payload?.authorizeddata[0]?.title,
                relationShip: payload?.authorizeddata[0]?.relationship,
                mobileCountryCode: payload?.authorizeddata[0]?.code,
                phoneNo: payload?.authorizeddata[0]?.phone_number,
                contactCategory: payload?.authorizeddata[0]?.category,
                hasTIN: payload?.authorizeddata[0]?.has_tin,
                tin: payload?.authorizeddata[0]?.tin,
              },
            ],
            attachments: [
              {
                category: "TEST",
                fileDescription: "test",
                fileAsBase64: "dfghjytfgfd",
                fileExtension: "char",
                documentCode: "7654556",
              },
            ],
          },
          // type: "formdata",
        });
    }
  };

  const renderForm = (key, props) => {
    let Child = null;
    switch (key) {
      case INDIVIDUAL:
        Child = IndividualInstantTinForm;
        break;

      case NON_INDIVIDUAL:
        Child = NonIndividualInstantTinForm;
        break;

      default:
        break;
    }
    return Child ? <Child {...props} /> : null;
  };

  return (
    <div className="user-form">
      <div className="App">
        <div className="panel panel-primary">
          <div className="panel-heading">
            <div className="panel-title">
              Register For Tax Identification Number (TIN)
            </div>
          </div>

          <div className="panel-body">
            <div className="row">
              <div className="col-sm-12">
                <SelectInput
                  label="Registration Type"
                  name="type"
                  onChange={(value) => setOwnershipType(value)}
                  options={ownershipTypes.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                  value={ownershipType}
                />
              </div>
            </div>

            {/* Form */}
            {renderForm(ownershipType, {
              form,
              submitForm,
              submitting,
              error,
              updateFormValue,
              onChange,
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetInstantTinForm;
