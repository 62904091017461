import { Logo, LogoWhite } from "../../../assets/svg/SvgIcons";

export default function LogoComponent({
  height,
  nolabel = false,
  dark = false,
}) {
  return (
    <div className="font12 semiBold pointer flexNullCenter">
      <img
        src={dark ? LogoWhite : Logo}
        style={{
          height: height ? height : 45,
          marginBlock: 10,
          marginRight: 10,
        }}
      />
      <div
        style={{ display: nolabel ? "none" : "block", color: dark && "white" }}
      >
        Integrated Revenue <br /> Administration System
      </div>
    </div>
  );
}
