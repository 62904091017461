import { createContext, useContext } from "react";
export const AppContext = createContext();

export const useAppContext = () => {
  const { contextData, setContextData } = useContext(AppContext);

  const updateContext = (key, value) => {
    setContextData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return {
    contextData,
    setContextData,
    updateContext,
  };
};
