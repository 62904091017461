import React, { useEffect, useState } from "react";
import useForm from "../../../hooks/common/useForm";
import { revenueSourceHooks, revenueSourceSelectHooks } from "../../../hooks";
import {
  prepareAttachmentsPost,
  renderReview,
  renderValuationObjectionForm,
} from "../../../utils/helperUtil";
import useGenericGet from "../../../hooks/common/useGenericGet";
import usePropertyValuation from "../../../hooks/property-rates/property-valuation/usePropertyValuation";

const BusinessTransferHandler = (props) => {
  const {
    data: registration,
    formId,
    onSubmit,
    registration_form,
    submitting,
    type,
    valuationobjection,
  } = props;
  const { form, updateFormValue, onChange } = useForm();
  const [selectedValue, setSelectedValue] = useState("current");
  const { viewData } = usePropertyValuation();

  const flatData = viewData(registration);

  const submitForm = (event) => {
    event.preventDefault();

    onSubmit({
      ...form,
      ...prepareAttachmentsPost([form?.attachment]),
      object_id: registration?.id,
    });
  };

  if (type === "details") {
    return renderReview(flatData, valuationobjection);
  }

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    updateFormValue(
      "transfer_existing_bills",
      selectedValue === "new" ? true : false
    );
  };

  const handleReasonChange = (event) => {
    updateFormValue("transfer_reason", event.target.value);
  };

  return (
    <form onSubmit={submitForm} id={formId}>
      {renderValuationObjectionForm(
        flatData,
        form,
        registration,
        onChange,
        updateFormValue,
        handleChange,
        selectedValue,
        handleReasonChange,
        submitting
      )}
    </form>
  );
};

export default BusinessTransferHandler;
