import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import axiosInstance from "../../utils/axios";
import useForm from "../../hooks/common/useForm";

const CancelPrnDialog = ({
  open,
  setOpen,
  ID,
  cancelApi,
  setSuccessCancel,
  setErrorCancel,
}) => {
  const { form, onChange } = useForm();

  function handleClose() {
    setOpen(false);
  }

  function handleCheck() {
    setOpen(false);
    setSuccessCancel(false);
    setErrorCancel(false);
    axiosInstance
      .post(`${cancelApi}`, {
        reason: form?.banned_reason,
        prn_id: ID,
      })
      .then(() => {
        setSuccessCancel(true);
        // navigate("../users", { replace: true });
      })
      .catch((err) => {
        // handle error
        setErrorCancel(true);
      });
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Reason</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="banned_reason"
          label="Enter Reason"
          type="text"
          name="banned_reason"
          fullWidth
          sx={{ width: 400 }}
          required
          onChange={onChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleCheck}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelPrnDialog;
