import React, { useEffect } from "react";
import PropTypes from "prop-types";
import FormInput from "../../common/FormInput";
import useGenericGet from "../../../hooks/common/useGenericGet";
import useForm from "../../../hooks/common/useForm";
import Loader from "../../common/Loader";
import { RENT_AND_RATES_API } from "../../../constants/api";

const RentAssessment = ({ tax_id, formId, proceed }) => {
  const { form, updateFormValue } = useForm();
  const { loadData, data: rent, loading } = useGenericGet();

  useEffect(() => {
    if (tax_id) {
      loadData({ api: RENT_AND_RATES_API, id: tax_id });
    }
  }, [tax_id]);



  const onSubmit = (event) => {
    event.preventDefault();
    proceed({ amount: rent?.monthly_rent});
  };

  if (loading) return <Loader />;

  return (
    <form id={formId} onSubmit={onSubmit}>
      <div className="panel panel-primary">
        <div className="panel-heading">
          <div className="panel-title">Generate Bill for Rents & Rates </div>
        </div>

        <div className="panel-body">
          <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="form-group">
                  <FormInput
                    label="Amount"
                    value={Number(rent?.monthly_rent || 0).toLocaleString()}
                    disabled
                    required
                  />
                </div>
              </div>
            </div>
        </div>
      </div>
    </form>
  );
};

RentAssessment.propTypes = {
  rent: PropTypes.shape({
    amount: PropTypes.number,
  }),
};

export default RentAssessment;
