import React, { useState } from "react";
import { FaEdit, FaEye } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { DeleteButton } from "../../../components/common/Button";
import axiosInstance from "../../../utils/axios";
import { PROFESSIONALS_API } from "../../../constants/api";

function useProfessionals() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  //Column names in the professionals UI table
  const professionalsTableColumns = [
    { id: "reg_no", header: "Registration Number", filter: {} },
    { id: "nin", header: "NIN", filter: {} },
    { id: "surname", header: "Surname", filter: {} },
    { id: "first_name", header: "First Name", filter: {} },
    { id: "other_name", header: "Other Name", filter: {} },
    { id: "mobile", header: "Mobile Number", filter: {} },
    { id: "email", header: "Email", filter: {} },
    { id: "physical_address", header: "Physical Adress", filter: {} },
    { id: "pc_no", header: "PC Number", filter: {} },
    { id: "professional_type", header: "Professional Type", filter: {} },
    { id: "actions", header: "Actions" },
  ];

  // Delete
  const deleteProfessional = (id) => {
    setLoading(true);
    axiosInstance
      .delete(`${PROFESSIONALS_API}${id}`)
      .then(() => {
        // handle success
        setLoading(false);
        navigate("/professionals/", { replace: true });
      })
      .catch((err) => {
        // handle error
        setLoading(false);
        setError(err);
      });
  };

  //Loading data into the professionals UI table
  const professionalsTableData = (data) => [
    ...data.map((item) => {
        const row = {
            reg_no: item?.reg_no,
            nin: item?.nin,
            surname: item?.surname,
            first_name: item?.first_name,
            other_name: item?.other_name,
            mobile: item?.mobile,
            email: item?.email,
            physical_address: item?.physical_address,
            pc_no: item?.pc_no,
            professional_type: item?.professional_type_details,            actions: (
              <>
                <Link
                  class="mr-2"
                  style={{ padding: 5, paddingBottom: 0 }}
                  to={`/professionals/view/${item.id}`}
                >
                  <FaEye />
                </Link>
                <Link
                  style={{ padding: 5, paddingBottom: 0 }}
                  class=" mr-2"
                  to={`/professionals/update/${item.id}`}
                >
                  <FaEdit />
                </Link>
                <DeleteButton onClick={() => deleteProfessional(item.id)} />
              </>
            ),
        };
        return row;
    }),
  ];

  const viewData = (item) => {
    return {
        reg_no: item?.reg_no,
        nin: item?.nin,
        surname: item?.surname,
        first_name: item?.first_name,
        other_name: item?.other_name,
        mobile: item?.mobile,
        email: item?.email,
        physical_address: item?.physical_address,
        pc_no: item?.pc_no,
        professional_type: item?.professional_type_details,
    };
  };

  const getRedirect = (status) => {
    let redirect = "";
    switch (status) {
      default:
        redirect = `/professionals/`;
        break;
    }

    return redirect;
  };

  return {
    professionalsTableColumns,
    professionalsTableData,
    viewData,
    getRedirect,
  };
}

export default useProfessionals;
