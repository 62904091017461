import React, { useEffect } from "react";
import {
  FaAngleDoubleRight,
  FaBan,
  FaEdit,
  FaEye,
  FaGem,
  FaMoneyBill,
} from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  NOT_PAID,
  OPERRATIONAL_PERMIT,
  PAID,
  STATUS_APPROVED,
  STATUS_ASSESSED,
  STATUS_DEACTIVATED,
  STATUS_DEFFERED,
  STATUS_PENDING,
  STATUS_REJECTED,
  STATUS_SUBMITTED,
} from "../../constants/webapp";
import {
  downloadThroughAnchorLink,
  returnObject,
} from "../../utils/helperUtil";
import { configColor } from "../../components/styled/Theme";
import { Visibility } from "../../components/common/Visibility";
import useLocationData from "../common/useLocationData";
import {
  INVOICE_API,
  OPERATIONAL_CATEGORIES_API,
  OPERATIONAL_SUB_CATEGORIES_API,
  TURNOVER_THRESHOLD_API,
} from "../../constants/api";
import useGenericGet from "../common/useGenericGet";
import { DownloadLink } from "../../components/common/Button";
import { Tooltip } from "antd";
import { LABEL_BUSINESS_TURNOVER } from "../../constants/operational-permit";
import {
  sDivision,
  sMunicipality,
  sParish,
  sStreet,
  sTaxpayerName,
  sVillage,
} from "../../components/layouts/LeftMenu";
import { PanTool } from "@mui/icons-material";
import { taxpayerAmdendables } from "../../utils/statusUtils";
import { MakePaymentRevenueButton } from "../trading-license/useTradingLicense";
import moment from "moment";
import { useAppContext } from "../../context/AppContext";

const useOperationalPermit = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { contextData } = useAppContext();
  const userRole = contextData?.role_name;

  const Assess = (item) => {
    navigate(`/assessments?tax_id=${item.id}&tax=operational_permit`, {
      state: { document_data: item },
    });
  };

  const operationalPermitTableData = (status, data) => [
    ...data.map((item) => {
      const row = {
        application_number: item?.application_number,
        branch_code: item?.branch_code,
        business_name: item?.business_name,
        business_category: item?.category_name,
        mobile_number: item?.mobile_number,
        owner_name: item?.owner_name,
        nature_of_business: item?.sub_category_name,
        r_app_fees: Number(item?.r_app_fees)?.toLocaleString(),
        r_waste_fees: Number(item?.r_waste_fees)?.toLocaleString(),
        r_sky_signs: Number(item?.r_sky_signs)?.toLocaleString(),
        r_shop_no_op: item?.r_shop_no_op,
        amount: Number(item?.amount).toLocaleString(),
        r_total_fees: Number(item?.amount)?.toLocaleString(),
        municipality_name: item?.municipality_name,
        division_name: item?.division_name,
        parish_name: item?.parish_name,
        village_name: item?.village_name,
        street_name: item?.street_name,
        plot_number: item?.r_plot_no,
        house_number: item?.r_house_no,
        r_lat: item?.r_lat,
        r_lng: item?.r_lng,
        payment_status:
          new Date(item?.expiry_date) >= new Date() ? "Paid" : "Not Paid",
        license_status:
          new Date(item?.expiry_date) >= new Date() ? "Active" : "No License",
        license_validity:
          new Date(item?.expiry_date) - moment(new Date()).format("YYYY-MM-DD"),
        expiry_date: item?.expiry_date ? item?.expiry_date : "No License",
        created_by: item?.created_by_name,
        assessments: (
          <a
            class="mr-2"
            href=""
            style={{ color: configColor }}
            onClick={() => {
              Assess(item);
            }}
          >
            Assessment
          </a>
        ),
        certificate: (
          <DownloadLink
            onClick={() =>
              downloadThroughAnchorLink(
                `${INVOICE_API}print-registration-certificate/?id=${item?.id} &table_name=operational_permit`,
                "Registration Certificate"
              )
            }
            title="[Registration Certificate]"
          />
        ),
        prn: (
          <MakePaymentRevenueButton
            api={OPERRATIONAL_PERMIT}
            id={item?.id}
            revenue_source={"operational_permit"}
          />
        ),
        actions: (
          <div style={{ whiteSpace: "nowrap" }}>
            <div style={{ whiteSpace: "nowrap" }}>
              <Link
                class="mr-2"
                style={{ padding: 5, paddingBottom: 0 }}
                to={`${OPERRATIONAL_PERMIT}/view/${item.id}`}
                // state={{ tax: "operational_permit" }}
              >
                <FaEye />
              </Link>
              <Visibility
                show={
                  item?.status_details === "DEFERRED" &&
                  userRole !== "MIS Authority"
                }
              >
                <Link
                  style={{ padding: 5, paddingBottom: 0 }}
                  class=" mr-2"
                  to={`${OPERRATIONAL_PERMIT}/update/${item.id}`}
                >
                  <FaEdit />
                </Link>
              </Visibility>
              <Visibility
                show={
                  ["APPROVED", "PAID"].includes(item?.status_details) &&
                  userRole !== "MIS Authority"
                }
              >
                <Tooltip title="Amendment">
                  <Link
                    style={{ padding: 5, paddingBottom: 0 }}
                    class=" mr-2"
                    to={`/amendment/operational_permit/${item.id}`}
                  >
                    <FaGem />
                  </Link>
                </Tooltip>
                <Tooltip title="Reassessment">
                  <Link
                    style={{ padding: 5, paddingBottom: 0 }}
                    class=" mr-2"
                    to={`/reassessment/operational_permit/${item.id}`}
                  >
                    <FaMoneyBill />
                  </Link>
                </Tooltip>
                <Tooltip title="Business Transfer">
                  <Link
                    style={{ padding: 5, paddingBottom: 0 }}
                    class=" mr-2"
                    to={`../business-transfers/request/operational-permits/${item.branch_code}`}
                  >
                    <FaAngleDoubleRight />
                  </Link>
                </Tooltip>
                <Tooltip title="Objection">
                  <Link
                    style={{ padding: 5, paddingBottom: 0 }}
                    class=" mr-2"
                    to={`/objection/operational_permit/${item.id}`}
                  >
                    <PanTool sx={{ fontSize: 16 }} />
                  </Link>
                </Tooltip>
              </Visibility>

              {item?.active_status === 1 &&
              status === "assessed" &&
              userRole !== "MIS Authority" ? (
                <Visibility show={item?.outstanding_balance === 0}>
                  <Link
                    style={{ padding: 5, paddingBottom: 0 }}
                    class=" mr-2"
                    to={`${OPERRATIONAL_PERMIT}/business_deactivation/${item.id}`}
                  >
                    <FaBan />
                  </Link>
                </Visibility>
              ) : null}
            </div>
          </div>
        ),
      };
      return row;
    }),
  ];

  const viewData = (item, isFormData) => {
    return {
      ...returnObject(!isFormData, {
        "Reference Number": item?.application_number,
      }),
      ...returnObject(
        item?.status_details === STATUS_APPROVED ||
          item?.status_details === STATUS_DEACTIVATED,
        {
          "Branch Code": item?.branch_code,
        }
      ),
      ...returnObject(isFormData, {
        [sTaxpayerName]: !item?.is_individual
          ? item?.legal_name
          : `${item?.first_name} ${item?.surname}`,
      }),
      ...returnObject(!isFormData, {
        [sTaxpayerName]: item?.owner_name,
      }),
      mobile_number: item?.mobile_number,
      tin: item?.tin,
      brn: item?.brn,
      nin: item?.nin,
      email: item?.email,
      [sMunicipality]: item?.municipality_name,
      [sDivision]: item?.division_name,
      [sParish]: item?.parish_name,
      [sVillage]: item?.village_name,
      [sStreet]: item?.street_name,
      grade: item?.grade,
      plot_number: item?.r_plot_no,
      house_number: item?.r_house_no,
      latitude: item?.r_lat,
      longitude: item?.r_lng,
      shop_number: item?.r_shop_no_op,
      business_name: item?.business_name,
      business_category: item?.category_name,
      nature_of_business: item?.sub_category_name,
      application_fees: Number(item?.r_app_fees)?.toLocaleString(),
      waste_fees: Number(item?.r_waste_fees)?.toLocaleString(),
      sign_fees: Number(item?.r_sky_signs)?.toLocaleString(),
      ...returnObject(location?.pathname === "/reassessments/request", {
        business_turnover: "",
      }),
      turnover_amount: Number(item?.lst_payable).toLocaleString(),
      permit_amount: Number(item?.permit_amount).toLocaleString(),
      total_fees: Number(item?.amount).toLocaleString(),
      ...returnObject(!isFormData, {
        outstanding_balance: Number(
          item?.outstanding_balance
        )?.toLocaleString(),
      }),
      created_by: `${item?.created_by?.first_name} ${item?.created_by?.last_name}`,
    };
  };

  const operationalPermitTableColumns = (status) => [
    { id: "application_number", header: "Reference Number ", filter: {} },
    ...returnObject(
      status === STATUS_ASSESSED || status === STATUS_DEACTIVATED,
      [{ id: "branch_code", header: "Branch Code", filter: {} }]
    ),
    { id: "mobile_number", header: "Mobile Number", filter: {} },
    { id: "owner_name", header: "Owner Name", filter: {} },
    { id: "municipality_name", header: "Municipality ", filter: {} },
    { id: "division_name", header: "Division", filter: {} },
    { id: "parish_name", header: "Parish ", filter: {} },
    { id: "village_name", header: "Village", filter: {} },
    { id: "street_name", header: "Street ", filter: {} },
    { id: "business_name", header: "Business Name", filter: {} },
    { id: "business_category", header: "Business Category ", filter: {} },
    { id: "nature_of_business", header: "Nature of Business", filter: {} },
    { id: "r_total_fees", header: "Total Fees (UGX)", filter: {} },
    { id: "created_by", header: "Created By", filter: {} },
    ...returnObject(status === STATUS_ASSESSED, [
      {
        id: "payment_status",
        header: "Payment Status",
        filter: {
          type: "select",
          placeholder: "Select status...",
          options: [
            { id: NOT_PAID, name: "Not Paid" },
            { id: PAID, name: "Paid" },
          ],
        },
      },
      { id: "license_status", header: "License Status" },
      // { id: "license_validity", header: "License Validity (days)" },
      { id: "expiry_date", header: "Expiry Date" },
      { id: "amount", header: "Amount" },
      { id: "assessments", header: "Invoices" },
      { id: "prn", header: "PRNs" },
      { id: "certificate", header: "Registration Certificate" },
    ]),
    { id: "actions", header: "Actions" },
  ];

  const getRedirect = (status) => {
    let redirect = "";
    switch (status?.toLowerCase()) {
      case STATUS_SUBMITTED:
        redirect = `${OPERRATIONAL_PERMIT}/${STATUS_ASSESSED}`;
        break;

      case STATUS_SUBMITTED:
        redirect = `${OPERRATIONAL_PERMIT}/${STATUS_REJECTED}`;
        break;
      case STATUS_APPROVED:
        redirect = `${OPERRATIONAL_PERMIT}/${STATUS_ASSESSED}`;
        break;

      case STATUS_REJECTED:
        redirect = `${OPERRATIONAL_PERMIT}/${STATUS_REJECTED}`;
        break;

      case STATUS_DEFFERED:
        redirect = `${OPERRATIONAL_PERMIT}/${STATUS_DEFFERED}`;
        break;

      default:
        redirect = `${OPERRATIONAL_PERMIT}/${STATUS_SUBMITTED}`;
        break;
    }

    return redirect;
  };

  const getActions = (status, approvedID, deferredID, rejectedID) => {
    status = status?.toLowerCase();

    let options = [];
    switch (status) {
      case STATUS_PENDING?.toLowerCase():
        options = [
          { value: approvedID, label: "Approve" },
          { value: rejectedID, label: "Reject" },
          { value: deferredID, label: "Deferred" },
        ];
        break;

      default:
        options = [];
    }

    return options;
  };

  const amendableFields = [
    ...taxpayerAmdendables,

    {
      id: sMunicipality,
      field: "municipality",
      type: "select",
      options: "districts",
    },
    { id: sDivision, field: "division", type: "select", options: "divisions" },
    { id: sParish, field: "parish", type: "select", options: "parishes" },
    { id: sVillage, field: "village", type: "select", options: "villages" },
    { id: sStreet, field: "street", type: "select", options: "streets" },
    // { id: "latitude", field: "r_lat", type: "text" },
    // { id: "longitude", field: "r_lng", type: "text" },
    { id: "plot_number", field: "r_plot_no", type: "text" },
    { id: "house_number", field: "r_house_no", type: "text" },
    { id: "shop_number", field: "r_shop_no_op", type: "text" },

    { id: "business_name", field: "business_name", type: "text" },
  ];

  const objectionFields = [
    { id: "mobile_number", field: "mobile_number", type: "tel" },
    { id: "tin", field: "tin", type: "number" },
    { id: "email", field: "email", type: "text" },
    {
      id: sMunicipality,
      field: "municipality",
      type: "select",
      options: "districts",
    },
    { id: sDivision, field: "division", type: "select", options: "divisions" },
    { id: sParish, field: "parish", type: "select", options: "parishes" },
    { id: sVillage, field: "village", type: "select", options: "villages" },
    { id: sStreet, field: "street", type: "select", options: "streets" },
    { id: "latitude", field: "r_lat", type: "text" },
    { id: "longitude", field: "r_lng", type: "text" },
    { id: "plot_number", field: "r_plot_no", type: "text" },
    { id: "house_number", field: "r_house_no", type: "text" },
    { id: "shop_number", field: "r_shop_no_op", type: "text" },
  ];

  const reassessFields = [
    {
      id: "business_category",
      field: "category",
      type: "select",
      options: "categories",
    },
    {
      id: "nature_of_business",
      field: "sub_category",
      type: "select",
      options: "natureofbusiness",
    },
    { id: "latitude", field: "r_lat", type: "text" },
    { id: "longitude", field: "r_lng", type: "text" },
    {
      id: "waste_fees",
      field: "r_waste_fees",
      type: "number",
    },
    {
      id: "sign_fees",
      field: "r_sky_signs",
      type: "number",
    },
    {
      id: "application_fees",
      field: "r_app_fees",
      type: "number",
    },
    {
      id: "permit_amount",
      field: "permit_amount",
      type: "number",
    },
    {
      id: LABEL_BUSINESS_TURNOVER,
      field: "r_lst_threshold",
      type: "select",
      options: "turnover_threshold",
    },
    {
      id: "turnover_amount",
      field: "lst_payable",
      type: "number",
      props: { readOnly: true },
    },
    {
      id: "total_fees",
      field: "amount",
      type: "number",
      props: { readOnly: true },
    },
  ];

  return {
    operationalPermitTableColumns,
    operationalPermitTableData,
    viewData,
    getRedirect,
    getActions,
    amendableFields,
    reassessFields,
    objectionFields,
  };
};

export const useOperationalPermitFormSelect = (form, updateFormValue) => {
  const {
    districts,
    getDistricts,
    divisions,
    getDivisions,
    parishes,
    getParishes,
    villages,
    getVillages,
    streets,
    getStreets,
  } = useLocationData();
  const { data: categories, loadData: getBusinessCateogories } =
    useGenericGet();
  const { data: natureofbusiness, loadData: getNatureOfBusiness } =
    useGenericGet();
  const { data: turnover_threshold, loadData: getTurnoverThreshold } =
    useGenericGet();
  const { loadData: getTurnOverAmount } = useGenericGet();

  useEffect(() => {
    getDistricts();
  }, []);

  useEffect(() => {
    if (form?.municipality) getDivisions(form?.municipality);
  }, [form?.municipality]);

  useEffect(() => {
    if (form?.division) getParishes(form?.division);
  }, [form?.division]);

  useEffect(() => {
    if (form?.parish) getVillages(form?.parish);
  }, [form?.parish]);

  useEffect(() => {
    if (form?.village) getStreets(form?.village);
  }, [form?.village]);

  useEffect(() => {
    getBusinessCateogories({
      api: OPERATIONAL_CATEGORIES_API,
      params: { parent: null },
    });
    getTurnoverThreshold({ api: TURNOVER_THRESHOLD_API });
  }, []);

  useEffect(() => {
    if (form.category) {
      getNatureOfBusiness({
        api: OPERATIONAL_SUB_CATEGORIES_API,
        params: { parent: form.category },
      });
    }
  }, [form.category]);

  useEffect(() => {
    if (form.r_lst_threshold) {
      updateFormValue(
        "amount",
        (turnover_threshold?.results || [])
          .filter((item) => item.id === Number(form.r_lst_threshold))
          .reduce((acc, curr) => curr.amount, 0)
      );
    }
  }, [form.r_lst_threshold]);

  useEffect(() => {
    if (form.r_lst_threshold) {
      updateFormValue(
        "amount",
        (turnover_threshold?.results || [])
          .filter((item) => item.id === Number(form.r_lst_threshold))
          .reduce((acc, curr) => curr.amount, 0)
      );
    }
  }, [form.r_lst_threshold]);

  useEffect(() => {
    if (form.r_lst_threshold_name) {
      updateFormValue("lst_payable", form.r_lst_threshold_amount);
    }
  }, [form.r_lst_threshold_name]);

  useEffect(() => {
    updateFormValue(
      "amount",
      Number(form?.lst_payable || 0) +
        Number(form?.permit_amount || 0) +
        Number(form?.r_sky_signs || 0) +
        Number(form?.r_app_fees || 0) +
        Number(form?.r_waste_fees || 0)
    );
  }, [
    form?.lst_payable,
    form?.permit_amount,
    form?.r_sky_signs,
    form?.r_app_fees,
    form?.r_waste_fees,
  ]);

  return {
    districts,
    divisions,
    parishes,
    villages,
    streets,
    turnover_threshold: turnover_threshold?.results || [],
    categories: categories?.results || [],
    natureofbusiness: natureofbusiness?.results || [],
  };
};

export default useOperationalPermit;
