import moment from "moment";

export const getCurrentDate = () => {
  let d = new Date();
  var options = {
    dateStyle: "long",
  };
  return d.toLocaleDateString("en-UK", options);
};

export const getFinancialYear = () => {
  let currentDate = new Date();
  let FY;
  if (currentDate.getMonth() >= 6)
    FY = currentDate.getFullYear() + "/" + (currentDate.getFullYear() + 1);
  else FY = currentDate.getFullYear() - 1 + "/" + currentDate.getFullYear();

  return FY;
};

export const getLastFinYear = () => {
  let currentDate = new Date();
  let FY = currentDate.getFullYear() - 1 + "/" + currentDate.getFullYear();

  return FY;
};

export const getFinancialPeriod = (year=new Date().getFullYear()) => {
  var month = 6; // July
  // let currentDate = new Date();
  // if (currentDate.getMonth() >= month){

  // }

  const incoming_date = new Date(++year, 0, 0);

  var first_date = new Date(incoming_date.getFullYear()-1, month , 1);
  var last_date = new Date(incoming_date.getFullYear(), month , 0);
  var this_fin_period=`${moment(first_date).format('DD MMM YYYY')} - ${moment(last_date).format('DD MMM YYYY')}`;
  return this_fin_period;
}
