function useRevenueLedger() {
  const ledgerTableData = (data) => [
    ...data.map((item) => {
      const row = {
        account: item?.account,
        name: item?.name,
        financial_year: item?.financial_year,
        registrations: item?.registrations,
        assessments: Number(item?.assessments || 0).toLocaleString(),
        collections: Number(item?.collections || 0).toLocaleString(),
        balance: Number(item?.assessments - item?.collections).toLocaleString(),
      };
      return row;
    }),
  ];

  const ledgerTableColumns = [
    { id: "account", header: "COA Code", filter: {} },
    { id: "name", header: "Revenue Source Item", filter: {} },
    { id: "financial_year", header: "Financial Year", filter: {} },
    { id: "registrations", header: "Registrations", filter: {} },
    { id: "assessments", header: "Assessments(UGX)", filter: {} },
    { id: "collections", header: "Collections(UGX)", filter: {} },
    { id: "balance", header: "Balance(UGX)", filter: {} },
  ];

  return {
    ledgerTableData,
    ledgerTableColumns,
  };
}

export default useRevenueLedger;
