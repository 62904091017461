import { Add, Delete } from "@mui/icons-material";
import { Button } from "antd";
import { useEffect } from "react";
import SelectInput from "../../common/SelectInput";
import FormInput from "../../common/FormInput";
import DateInput from "../../common/DateInput";
import useGenericGet from "../../../hooks/common/useGenericGet";
import { TITLE_API } from "../../../constants/api";

const options = [
  {
    id: "Y",
    name: "Yes",
  },
  {
    id: "Y",
    name: "No",
  },
];

const categories = [
  {
    id: "AUTH",
    name: "Authorized Contact",
  },
  {
    id: "DIR",
    name: "Director",
  },
];

const relationships = [
  {
    code: "DIRC",
    name: "DIRECTOR",
  },
  {
    code: "TRST",
    name: "TRUSTEE",
  },
  {
    code: "PART",
    name: "PARTNER",
  },
  {
    code: "OBCO",
    name: "OFFICE BEARER OF CLUB OR OTHER ENTITY",
  },
];

const InstantTinTable = (props) => {
  const { form, updateFormValue } = props;
  const rowsData = form?.authorizeddata || [];

  const addTableRows = () => {
    const rowsInput = {
      has_tin: "",
      has_nin: "",
      category: "",
      relationship: "",
      title: "",
      nin: "",
      tin: "",
      date_of_birth: "",
      first_name: "",
      surname: "",
      email: "",
      code: "",
      phone_number: "",
    };
    updateFormValue("authorizeddata", [...rowsData, rowsInput]);
  };

  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    updateFormValue("authorizeddata", rows);
  };

  const handleChange = (index, name, value) => {
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    updateFormValue("authorizeddata", rowsInput);
  };

  useEffect(() => {
    if (rowsData.length == 0) {
      addTableRows();
    }
  }, [rowsData]);

  return rowsData.map((data, index) => {
    const {
      has_tin,
      has_nin,
      category,
      relationship,
      nin,
      tin,
      title,
      date_of_birth,
      first_name,
      surname,
      email,
      code,
      phone_number,
    } = data;
    return (
      <TableRowItem
        handleChange={handleChange}
        addTableRows={addTableRows}
        has_tin={has_tin}
        has_nin={has_nin}
        category={category}
        relationship={relationship}
        title={title}
        nin={nin}
        tin={tin}
        date_of_birth={date_of_birth}
        first_name={first_name}
        surname={surname}
        email={email}
        code={code}
        phone_number={phone_number}
        deleteTableRows={deleteTableRows}
        index={index}
      />
    );
  });
};

const TableRowItem = ({
  index,
  has_tin,
  category,
  relationship,
  title,
  has_nin,
  nin,
  tin,
  date_of_birth,
  first_name,
  surname,
  email,
  code,
  phone_number,
  handleChange,
  addTableRows,
  deleteTableRows,
}) => {
  const { data: titles, loadData: getTitle } = useGenericGet();
  useEffect(() => {
    getTitle({
      api: TITLE_API,
    });
  }, []);
  return (
    <div
      className="row flexNullCenter gap20"
      style={{ marginTop: 5, marginBottom: 5 }}
    >
      <div className="col-25">
        <SelectInput
          label="Category"
          name="category"
          value={category}
          onChange={(value) => {
            handleChange(index, "category", value);
          }}
          options={(categories || []).map((sup) => ({
            label: sup.name,
            value: sup.id,
          }))}
          required
        />
      </div>

      <div className="col-25">
        <SelectInput
          label="Has TIN"
          name=""
          value={has_tin}
          onChange={(value) => {
            handleChange(index, "has_tin", value);
          }}
          options={(options || []).map((sup) => ({
            label: sup.name,
            value: sup.id,
          }))}
          required
        />
      </div>

      <div className="col-25">
        <SelectInput
          label="Has NIN"
          name=""
          value={has_nin}
          onChange={(value) => {
            handleChange(index, "has_nin", value);
          }}
          options={(options || []).map((sup) => ({
            label: sup.name,
            value: sup.id,
          }))}
          required
        />
      </div>

      <div className="col-25">
        <SelectInput
          label="Title"
          name="title"
          value={title}
          onChange={(value) => {
            handleChange(index, "title", value);
          }}
          options={(titles || []).map((sup) => ({
            label: sup.id,
            value: sup.id,
          }))}
          required
        />
      </div>

      <div className="col-25">
        <SelectInput
          label="Relationship"
          name="relationship"
          value={relationship}
          onChange={(value) => {
            handleChange(index, "relationship", value);
          }}
          options={(relationships || []).map((sup) => ({
            label: sup.name,
            value: sup.code,
          }))}
          required
        />
      </div>

      <div className="col-25">
        <FormInput
          label="National Identification No."
          name="nin"
          onChange={(evnt) => handleChange(index, "nin", evnt.target.value)}
          value={nin}
          type="text"
          maxLenth={14}
          required
        />
      </div>

      <div className="col-25">
        <FormInput
          label="Tax Identification No."
          name="tin"
          onChange={(evnt) => handleChange(index, "tin", evnt.target.value)}
          value={tin}
          type="text"
          maxLenth={10}
          required
        />
      </div>
      {/* Date Of Birth */}
      <div className="col-25">
        <DateInput
          label="Date Of Birth"
          id="date_of_birth"
          name="date_of_birth"
          onChange={(evnt) =>
            handleChange(index, "date_of_birth", evnt?.target?.value)
          }
          value={date_of_birth}
          format="DD/MM/YYYY"
          required
        />
      </div>

      <div className="col-25">
        <FormInput
          label="First Name"
          name="first_name"
          onChange={(evnt) =>
            handleChange(index, "first_name", evnt.target.value)
          }
          value={first_name}
          type="text"
          required
        />
      </div>

      <div className="col-25">
        <FormInput
          label="Surname"
          name="surname"
          onChange={(evnt) => handleChange(index, "surname", evnt.target.value)}
          value={surname}
          type="text"
          required
        />
      </div>

      <div className="col-25">
        <FormInput
          label="Email"
          name="email"
          onChange={(evnt) => handleChange(index, "email", evnt.target.value)}
          value={email}
          type="email"
          required
        />
      </div>

      <div className="col-25">
        <FormInput
          label="Mobile country code"
          name="code"
          onChange={(evnt) => handleChange(index, "code", evnt.target.value)}
          value={code}
          type="text"
          required
        />
      </div>

      <div className="col-25">
        <FormInput
          label="Phone Number"
          name="phone_number"
          onChange={(evnt) =>
            handleChange(index, "phone_number", evnt.target.value)
          }
          value={phone_number}
          type="text"
          required
        />
      </div>

      {/* Buttons */}
      <div className="flex gap10" style={{ paddingRight: 20 }}>
        {index > 0 && (
          <Button icon={<Delete />} onClick={() => deleteTableRows(index)} />
        )}
        <Button icon={<Add />} onClick={addTableRows} />
      </div>
    </div>
  );
};

export default InstantTinTable;
