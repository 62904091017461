import React, { useEffect } from "react";
import PropTypes from "prop-types";
import FormInput from "../../../common/FormInput";
import useGenericGet from "../../../../hooks/common/useGenericGet";
import useForm from "../../../../hooks/common/useForm";
import Loader from "../../../common/Loader";
import {PROPERTY_TAX_API} from "../../../../constants/api";

const PropertyTaxAssessment = ({ tax_id, formId, proceed }) => {
  const { form, updateFormValue } = useForm();
  const { loadData, data: propertytax, loading } = useGenericGet();

  useEffect(() => {
    if (tax_id) {
      loadData({ api: PROPERTY_TAX_API, id: tax_id });
    }
  }, [tax_id]);



  const onSubmit = (event) => {
    event.preventDefault();
    proceed({ amount: propertytax?.annual_rate});
  };

  if (loading) return <Loader />;

  return (
    <form id={formId} onSubmit={onSubmit}>
      <div className="panel panel-primary">
        <div className="panel-heading">
          <div className="panel-title">Generate Bill for Property Tax</div>
        </div>

        <div className="panel-body">
        <div className="row">
              <div className="col-lg-6 col-sm-12">
                <div className="form-group">
                  <FormInput
                    label="Total built up area"
                    value={propertytax?.r_total_built_up_area_sqm || ""}
                    disabled
                    required
                  />
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="form-group">
                  <FormInput
                    label="Rate per square meter"
                    value={propertytax?.r_rate_per_sq_meter || ""}
                    disabled
                    required
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <div className="form-group">
                  <FormInput
                    label="Rent per unit"
                    value={propertytax?.r_prt_rent || ""}
                    disabled
                    required
                  />
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="form-group">
                  <FormInput
                    label="Number of rental units"
                    value={propertytax?.r_prt_units || ""}
                    disabled
                    required
                  />
                </div>
              </div>
            </div>


          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <div className="form-group">
                <FormInput
                  label="Gross Value"
                  value={Number(propertytax?.gross_value || 0).toLocaleString()}
                  disabled
                  required
                />
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="form-group">
                <FormInput
                  label="Ratable Value"
                  value={Number(propertytax?.ratable_value || 0).toLocaleString()}
                  disabled
                  required
                />
              </div>
            </div>
          </div>
          <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="form-group">
                  <FormInput
                    label="Annual Rate"
                    value={Number(propertytax?.annual_rate || 0).toLocaleString()}
                    disabled
                    required
                  />
                </div>
              </div>
            </div>
        </div>
      </div>
    </form>
  );
};

PropertyTaxAssessment.propTypes = {
  propertytax: PropTypes.shape({
    amount: PropTypes.number,
    amount: PropTypes.number,
  }),
};

export default PropertyTaxAssessment;
