import React, { useEffect } from "react";
import {
  FaAngleDoubleRight,
  FaBan,
  FaEdit,
  FaEye,
  FaGem,
  FaMoneyBill,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  LOCAL_HOTEL_TAX,
  STATUS_APPROVED,
  STATUS_PENDING,
  STATUS_REJECTED,
  STATUS_SUBMITTED,
  STATUS_ASSESSED,
  STATUS_DEFFERED,
  MUN_NAME,
  DIV_NAME,
  PAR_NAME,
  VILL_NAME,
  STR_NAME,
  STATUS_DEACTIVATED,
} from "../../constants/webapp";
import {
  downloadThroughAnchorLink,
  returnObject,
} from "../../utils/helperUtil";
import { configColor } from "../../components/styled/Theme";
import { Visibility } from "../../components/common/Visibility";
import useLocationData from "../common/useLocationData";
import useGenericGet from "../common/useGenericGet";
import {
  HOTEL_CLASSIFICATION_API,
  HOTEL_RATE_API,
  INVOICE_API,
} from "../../constants/api";
import { DownloadLink } from "../../components/common/Button";
import {
  sDivision,
  sMunicipality,
  sParish,
  sStreet,
  sTaxpayerName,
  sVillage,
} from "../../components/layouts/LeftMenu";
import { Tooltip } from "antd";
import { PanTool } from "@mui/icons-material";
import { taxpayerAmdendables } from "../../utils/statusUtils";
import { MakePaymentRevenueButton } from "../trading-license/useTradingLicense";
import { useAppContext } from "../../context/AppContext";

const useLocalHotelTax = () => {
  const { contextData } = useAppContext();
  const userRole = contextData?.role_name;

  const localHotelTaxTableData = (_, data) => [
    ...data.map((item) => {
      const row = {
        application_number: item?.application_number,
        branch_code: item?.branch_code,
        tin: item?.tin,
        email: item?.email,
        mobile_number: item?.mobile_number,
        owner_name: item?.owner_name,
        nin: item?.nin,
        brn: item?.brn,
        hotel_name: item?.hotel_name,
        [MUN_NAME]: item?.municipality_name,
        [DIV_NAME]: item?.division_name,
        [PAR_NAME]: item?.parish_name,
        [VILL_NAME]: item?.village_name,
        [STR_NAME]: item?.street_name,
        latitude: item?.r_lat,
        longitude: item?.r_lng,
        classification: item?.classification_name,
        rooms: item?.rooms,
        hotel_rating: item?.r_facility_rating_name,
        status: item?.status,
        created_by: item?.created_by_name,
        assessments: (
          <Link
            to={`/assessments?tax_id=${item.id}&tax=lht`}
            style={{ color: configColor }}
          >
            Assessment
          </Link>
        ),
        certificate: (
          <DownloadLink
            onClick={() =>
              downloadThroughAnchorLink(
                `${INVOICE_API}print-registration-certificate/?id=${item?.id} &table_name=lht`,
                "Registration Certificate"
              )
            }
            title="[Registration Certificate]"
          />
        ),
        prn: (
          <MakePaymentRevenueButton
            api={LOCAL_HOTEL_TAX}
            id={item?.id}
            revenue_source={"lht"}
          />
        ),

        actions: (
          <div style={{ whiteSpace: "nowrap" }}>
            <div style={{ whiteSpace: "nowrap" }}>
              <Link
                class="mr-2"
                style={{ padding: 5, paddingBottom: 0 }}
                to={`${LOCAL_HOTEL_TAX}/view/${item.id}`}
                // state={{ tax: "lht" }}
              >
                <FaEye />
              </Link>
              <Visibility
                show={
                  item?.status_details === "DEFERRED" &&
                  userRole !== "MIS Authority"
                }
              >
                <Link
                  style={{ padding: 5, paddingBottom: 0 }}
                  class=" mr-2"
                  to={`${LOCAL_HOTEL_TAX}/update/${item.id}`}
                >
                  <FaEdit />
                </Link>
              </Visibility>
              <Visibility
                show={
                  ["APPROVED", "PAID"].includes(item?.status_details) &&
                  userRole !== "MIS Authority"
                }
              >
                <Tooltip title="Amendemt">
                  <Link
                    style={{ padding: 5, paddingBottom: 0 }}
                    class=" mr-2"
                    to={`/amendment/lht/${item.id}`}
                  >
                    <FaGem />
                  </Link>
                </Tooltip>
                <Tooltip title="Reassessment">
                  <Link
                    style={{ padding: 5, paddingBottom: 0 }}
                    class=" mr-2"
                    to={`/assessments?tax_id=${item.id}&tax=lht`}
                  >
                    <FaMoneyBill />
                  </Link>
                </Tooltip>
                <Tooltip title="Business Transfer">
                  <Link
                    style={{ padding: 5, paddingBottom: 0 }}
                    class=" mr-2"
                    to={`../business-transfers/request/lhts/${item.branch_code}`}
                  >
                    <FaAngleDoubleRight />
                  </Link>
                </Tooltip>
                <Tooltip title="Objection">
                  <Link
                    style={{ padding: 5, paddingBottom: 0 }}
                    class=" mr-2"
                    to={`/objection/lht/${item.id}`}
                  >
                    <PanTool sx={{ fontSize: 16 }} />
                  </Link>
                </Tooltip>
                <Visibility show={item?.outstanding_balance == 0}>
                  <Tooltip title="Deactivate">
                    <Tooltip title="Deactivate">
                      <Link
                        style={{ padding: 5, paddingBottom: 0 }}
                        class=" mr-2"
                        to={`${LOCAL_HOTEL_TAX}/business_deactivation/${item.id}`}
                      >
                        <FaBan />
                      </Link>
                    </Tooltip>
                  </Tooltip>
                </Visibility>
              </Visibility>
            </div>
          </div>
        ),
      };
      return row;
    }),
  ];

  const viewData = (item, isFormData) => {
    return {
      ...returnObject(!isFormData, {
        "Reference Number": item?.application_number,
      }),
      ...returnObject(
        item?.status_details === STATUS_APPROVED ||
          item?.status_details === STATUS_DEACTIVATED,
        {
          "Branch Code": item?.branch_code,
        }
      ),
      tin: item?.tin,
      [item?.idTypeName]: item?.cin,
      ...returnObject(!isFormData, {
        [sTaxpayerName]: item?.owner_name,
      }),
      email: item?.email,
      hotel_name: item?.hotel_name,
      mobile_number: item?.mobile_number,
      [sMunicipality]: isFormData
        ? item?.municipality_name
        : item?.municipality_name,
      [sDivision]: isFormData ? item?.division_name : item?.division_name,
      [sParish]: isFormData ? item?.parish_name : item?.parish_name,
      [sVillage]: isFormData ? item?.village_name : item?.village_name,
      [sStreet]: isFormData ? item?.street_name : item?.street_name,
      latitude: item?.r_lat,
      longitude: item?.r_lng,
      classification: isFormData
        ? item?.classification_name
        : item?.classification_name,
      hotel_facility_rating: isFormData
        ? item?.rating_name
        : item?.r_facility_rating,
      "Total Number of Rooms": item?.rooms,
      ...returnObject(!isFormData, {
        outstanding_balance: Number(
          item?.outstanding_balance
        )?.toLocaleString(),
        // Status: item?.status_details,
      }),
      created_by: `${item?.first_name} ${item?.surname}`,
    };
  };

  const localHotelTaxTableColumns = (status) => [
    { id: "application_number", header: "Reference Number ", filter: {} },
    ...returnObject(
      status === STATUS_ASSESSED || status === STATUS_DEACTIVATED,
      [{ id: "branch_code", header: "Branch Code", filter: {} }]
    ),
    { id: "hotel_name", header: "Hotel Business Name", filter: {} },
    { id: "mobile_number", header: "Mobile Number ", filter: {} },
    { id: "owner_name", header: "Taxpayer Name", filter: {} },
    { id: "nin", header: "NIN", filter: {} },
    { id: "brn", header: "BRN", filter: {} },
    { id: MUN_NAME, header: "Municipality ", filter: {} },
    { id: DIV_NAME, header: "Division", filter: {} },
    { id: PAR_NAME, header: "Parish ", filter: {} },
    { id: VILL_NAME, header: "Village", filter: {} },
    { id: STR_NAME, header: "Street ", filter: {} },
    { id: "classification", header: "Classification", filter: {} },
    { id: "rooms", header: "Total Rooms", filter: {} },
    { id: "hotel_rating", header: "Hotel Facility Rating", filter: {} },
    { id: "created_by", header: "Created By", filter: {} },
    ...returnObject(status === STATUS_ASSESSED, [
      { id: "assessments", header: "Invoices" },
      { id: "prn", header: "PRNs" },

      { id: "certificate", header: "Registration Certificate" },
    ]),

    { id: "actions", header: "Actions" },
  ];

  const getRedirect = (status) => {
    let redirect = "";
    switch (status.toLowerCase()) {
      case STATUS_SUBMITTED:
        redirect = `${LOCAL_HOTEL_TAX}/${STATUS_PENDING}`;
        break;

      case STATUS_ASSESSED:
        redirect = `${LOCAL_HOTEL_TAX}/${STATUS_ASSESSED}`;
        break;

      case STATUS_REJECTED:
        redirect = `${LOCAL_HOTEL_TAX}/${STATUS_REJECTED}`;
        break;

      case STATUS_DEFFERED:
        redirect = `${LOCAL_HOTEL_TAX}/${STATUS_DEFFERED}`;
        break;

      default:
        break;
    }

    return redirect;
  };

  const getActions = (status, approvedID, deferredID, rejectedID) => {
    status = status?.toLowerCase();

    let options = [];
    switch (status) {
      case STATUS_PENDING?.toLowerCase():
        options = [
          { value: approvedID, label: "Approve" },
          { value: rejectedID, label: "Reject" },
          { value: deferredID, label: "Deferred" },
        ];
        break;

      default:
        options = [];
    }

    return options;
  };

  const amendableFields = [
    ...taxpayerAmdendables,

    {
      id: sMunicipality,
      field: "municipality",
      type: "select",
      options: "districts",
    },
    { id: sDivision, field: "division", type: "select", options: "divisions" },
    { id: sParish, field: "parish", type: "select", options: "parishes" },
    { id: sVillage, field: "village", type: "select", options: "villages" },
    { id: sStreet, field: "street", type: "select", options: "streets" },
    { id: "latitude", field: "r_lat", type: "text" },
    { id: "longitude", field: "r_lng", type: "text" },
    { id: "plot_number", field: "r_plot_no", type: "text" },
    { id: "hotel_name", field: "hotel_name", type: "text" },
    { id: "Total Number of Rooms", field: "rooms", type: "text" },
    {
      id: "classification",
      field: "classification",
      options: "classifications",
      type: "select",
    },
    {
      id: "hotel_facility_rating",
      field: "r_facility_rating",
      options: "hotelrates",
      type: "select",
    },
  ];

  const objectionFields = [
    { id: "mobile_number", field: "mobile_number", type: "tel" },
    { id: "tin", field: "tin", type: "number" },
    { id: "nin", field: "nin", type: "text" },
    { id: "email", field: "email", type: "text" },
    {
      id: sMunicipality,
      field: "municipality",
      type: "select",
      options: "districts",
    },
    { id: sDivision, field: "division", type: "select", options: "divisions" },
    { id: sParish, field: "parish", type: "select", options: "parishes" },
    { id: sVillage, field: "village", type: "select", options: "villages" },
    { id: sStreet, field: "street", type: "select", options: "streets" },
    { id: "latitude", field: "r_lat", type: "text" },
    { id: "longitude", field: "r_lng", type: "text" },
    { id: "plot_number", field: "r_plot_no", type: "text" },

    { id: "hotel_name", field: "hotel_name", type: "text" },
    { id: "Total Number of Rooms", field: "rooms", type: "text" },
    {
      id: "classification",
      field: "classification",
      options: "classifications",
      type: "select",
    },
    {
      id: "hotel_facility_rating",
      field: "r_facility_rating",
      options: "hotelrates",
      type: "select",
    },
  ];

  const reassessFields = [];

  return {
    localHotelTaxTableColumns,
    localHotelTaxTableData,
    viewData,
    getRedirect,
    getActions,
    amendableFields,
    objectionFields,
    reassessFields,
  };
};

export const useLocalHotelTaxFormSelect = (form) => {
  const {
    districts,
    getDistricts,
    divisions,
    getDivisions,
    parishes,
    getParishes,
    villages,
    getVillages,
    streets,
    getStreets,
  } = useLocationData();
  const { data: classifications, loadData: getClassification } =
    useGenericGet();
  const { data: hotelrates, loadData: getHotelRate } = useGenericGet();

  useEffect(() => {
    getDistricts();
  }, []);

  useEffect(() => {
    if (form?.municipality) getDivisions(form?.municipality);
  }, [form?.municipality]);

  useEffect(() => {
    if (form?.division) getParishes(form?.division);
  }, [form?.division]);

  useEffect(() => {
    if (form?.parish) getVillages(form?.parish);
  }, [form?.parish]);

  useEffect(() => {
    if (form?.village) getStreets(form?.village);
  }, [form?.village]);

  useEffect(() => {
    getClassification({ api: HOTEL_CLASSIFICATION_API });
    getHotelRate({ api: HOTEL_RATE_API });
  }, []);

  return {
    districts,
    divisions,
    parishes,
    villages,
    streets,
    classifications: classifications?.results || [],
    hotelrates: hotelrates?.results || [],
  };
};

export default useLocalHotelTax;
