import axiosInstance from "../../utils/axios";
import formDataInstance from "../../utils/formdataUtil";
import { addManyToFormdata, createFormData } from "../../utils/helperUtil";

function useAxios() {
  function get(options) {
    const { api, loader, successHandler, errorHandler, params } = options;
    if (loader) loader(true);

    axiosInstance
      .get(api, { params })
      .then(({ data }) => {
        if (successHandler) successHandler(data);
        if (loader) loader(false);
      })
      .catch(({ message }) => {
        if (errorHandler) errorHandler(message);
        if (loader) loader(false);
      });
  }

  function post(options) {
    const {
      api,
      methodName,
      loader,
      successHandler,
      errorHandler,
      params,
      type,
      manyToMany,
    } = options;
    const instance = type === "formdata" ? formDataInstance : axiosInstance;
    const payload =
      type === "formdata"
        ? manyToMany
          ? addManyToFormdata(createFormData(params), manyToMany)
          : createFormData(params)
        : params;

    if (loader) loader(true);

    instance[methodName || "post"](api, payload)
      .then(({ data }) => {
        if (successHandler) successHandler(data);
        if (loader) loader(false);
      })
      .catch((err) => {
        if (errorHandler) {
          errorHandler(err?.response?.data || "An unknown error occured");
        }

        if (loader) loader(false);
      });
  }

  return { get, post };
}

export default useAxios;
