import React, { useEffect } from "react";
import { FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";
import moment from "moment";
import { returnObject } from "../../utils/helperUtil";
import {
  COLOR_BLUE,
  DIV_NAME,
  MUN_NAME,
  PAR_NAME,
  STR_NAME,
  VILL_NAME,
} from "../../constants/webapp";
import useLocationData from "../common/useLocationData";
import { taxpayerAmdendables } from "../../utils/statusUtils";
import { sTaxpayerName } from "../../components/layouts/LeftMenu";

function useOntr() {
  //Column names in the nature of business UI table
  const ontrTableColumns = [
    { id: "created_at", header: "Created At" },
    { id: "branch_code", header: "Branch Code", filter: {} },
    { id: "owner_name", header: "Owner Name", filter: {} },
    { id: "email", header: "Email", filter: {} },
    { id: "mobile_number", header: "Mobile Number", filter: {} },
    { id: MUN_NAME, header: "Municipality", filter: {} },
    { id: DIV_NAME, header: "Division", filter: {} },
    { id: PAR_NAME, header: "Parish", filter: {} },
    { id: VILL_NAME, header: "Village", filter: {} },
    { id: STR_NAME, header: "Street", filter: {} },
    { id: "revenue_category", header: "Revenue Category", filter: {} },
    { id: "revenue_source_item", header: "Revenue Source Item", filter: {} },
    { id: "payment_date", header: "Payment Date", filter: {} },
    { id: "registered_by", header: "Registered By", filter: {} },
    { id: "amount", header: "Amount (UGX)", filter: {} },
    {
      id: "payment_status",
      header: "Payment status",
      filter: { type: "select" },
    },
    { id: "paf", header: "PAFs" },
    { id: "actions", header: "Actions" },
  ];

  //Loading data into the ontr UI table
  const ontrTableData = (data) => [
    ...data.map((item) => {
      const row = {
        branch_code: item.branch_code,
        owner_name: item.owner_name,
        email: item.email,
        mobile_number: item.mobile_number,
        [MUN_NAME]: item.municipality_name,
        [DIV_NAME]: item.division_name,
        [PAR_NAME]: item.parish_name,
        [VILL_NAME]: item.village_name,
        [STR_NAME]: item.street_name,
        revenue_category: item.revenue_category_name,
        revenue_source_item: item.revenue_source_item_name,
        amount: item?.amount?.toLocaleString(),
        status: item.status,
        created_at: moment(item?.created_at)?.format("ll"),
        payment_date: item?.payment_date2
          ? moment(item?.payment_date2).format("DD-MM-YYYY")
          : null,
        registered_by: item?.created_by_name,
        payment_status:
          item?.invoice?.payment_status?.replaceAll("_", " ").toUpperCase() ||
          "NO INVOICE",
        paf: item?.invoice ? (
          <Link
            to={`/paf/create/invoice/ontr/${item?.invoice?.id}`}
            style={{ color: COLOR_BLUE }}
          >
            [PAFs]
          </Link>
        ) : null,
        actions: (
          <>
            <Link
              class="mr-2"
              style={{ padding: 5, paddingBottom: 0 }}
              to={`/ontrs/view/${item.id}`}
            >
              <FaEye />
            </Link>
          </>
        ),
      };
      return row;
    }),
  ];

  const viewData = (item, isFormData) => {
    return {
      ...returnObject(!isFormData, {
        "Branch Code": item?.branch_code,
        [sTaxpayerName]: item?.owner_name,
      }),
      tin: item?.tin,
      nin: item?.cin,
      brn: item?.brn,
      email: item?.email,
      mobile_number: item?.mobile_number,
      municipality: item?.municipality_name,
      division: item?.division_name,
      parish: item?.parish_name,
      village: item?.village_name,
      street: item?.street_name,
      revenue_category: isFormData
        ? item?.category_name
        : item?.revenue_category_name,
      revenue_source_item: item?.revenue_source_item_name,
      copies: item?.copies,
      amount: Number(item?.amount || 0).toLocaleString(),
      ...returnObject(!isFormData && item?.invoice?.payment_status, {
        "Payment status": item?.invoice?.payment_status,
      }),
      "Created At": moment(item?.created_at).format("DD-MM-YYYY"),
      "Payment Date": item?.payment_date
        ? moment(item?.payment_date2).format("DD-MM-YYYY")
        : null,
      ...returnObject(!isFormData, {
        "Registered By": `${item?.created_by?.first_name} ${item?.created_by?.last_name}`,
        // PRN: item?.prn,
      }),
    };
  };

  const getRedirect = (status) => {
    let redirect = "";
    switch (status) {
      default:
        redirect = "/ontrs";
        break;
    }

    return redirect;
  };

  const amendableFields = [
    ...taxpayerAmdendables,
    {
      id: "municipality",
      field: "municipality",
      type: "select",
      options: "districts",
    },
    { id: "division", field: "division", type: "select", options: "divisions" },
    { id: "parish", field: "parish", type: "select", options: "parishes" },
    { id: "village", field: "village", type: "select", options: "villages" },
    { id: "street", field: "street", type: "select", options: "streets" },
    { id: "latitude", field: "r_lat", type: "text" },
    { id: "longitude", field: "r_lng", type: "text" },
    { id: "plot_number", field: "r_plot_no", type: "text" },
  ];

  const objectionFields = [
    { id: "mobile_number", type: "mobile_number" },
    { id: "tin", type: "text" },
    { id: "owner_name", type: "text" },
    { id: "email", type: "text" },
    { id: "nin", type: "text" },

    { id: "municipality", type: "select", options: "districts" },
    { id: "division", type: "select", options: "divisions" },
    { id: "parish", type: "select", options: "parishes" },
    { id: "village", type: "select", options: "villages" },
    { id: "street", type: "select", options: "streets" },
    { id: "latitude", field: "r_lat", type: "text" },
    { id: "longitude", field: "r_lng", type: "text" },
    { id: "plot_number", field: "r_plot_no", type: "text" },
  ];

  return {
    ontrTableColumns,
    ontrTableData,
    getRedirect,
    viewData,
    amendableFields,
    objectionFields,
  };
}

export const useOntrFormSelect = (form) => {
  const {
    districts,
    getDistricts,
    divisions,
    getDivisions,
    parishes,
    getParishes,
    villages,
    getVillages,
    streets,
    getStreets,
  } = useLocationData();

  useEffect(() => {
    getDistricts();
  }, []);

  useEffect(() => {
    if (form?.municipality) getDivisions(form?.municipality);
  }, [form?.municipality]);

  useEffect(() => {
    if (form?.division) getParishes(form?.division);
  }, [form?.division]);

  useEffect(() => {
    if (form?.parish) getVillages(form?.parish);
  }, [form?.parish]);

  useEffect(() => {
    if (form?.village) getStreets(form?.village);
  }, [form?.village]);

  return {
    districts,
    divisions,
    parishes,
    villages,
    streets,
  };
};

export default useOntr;
